var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StringList = StringList;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _reactNativeDraggableFlatlist = _interopRequireWildcard(require("react-native-draggable-flatlist"));
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _KeyboardEvents = require("../utils/KeyboardEvents");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function StringList(props) {
  var mapValues = function mapValues(v) {
    var m = [];
    v.forEach(function (value) {
      return m.push({
        key: uuid.v1(),
        value: value
      });
    });
    return m;
  };
  var _useState = (0, _react.useState)(props.initValue ? mapValues(props.initValue) : []),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    value = _useState2[0],
    setValue = _useState2[1];
  var _useState3 = (0, _react.useState)(''),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    hasfocus = _useState4[0],
    setHasFocus = _useState4[1];
  var selection = (0, _react.useRef)({
    start: 0,
    end: 0
  });
  (0, _react.useEffect)(function () {
    _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        if (navigator && navigator.clipboard) {
          e.preventDefault();
          navigator.clipboard.readText().then(function (t) {
            var text = [];
            var st2 = t.split('\t');
            st2.forEach(function (a) {
              var st = a.split('\n');
              st.forEach(function (b) {
                text.push(b);
              });
            });
            if (text.length > 0) {
              var newVal = (0, _toConsumableArray2.default)(value);
              var _loop = function _loop(_t) {
                if (_t.length > 0 && !newVal.find(function (c) {
                  return c.value === _t;
                })) {
                  newVal.push({
                    value: _t,
                    key: uuid.v1()
                  });
                }
              };
              for (var _t of text) {
                _loop(_t);
              }
              setValue(newVal);
            }
          }).catch(function (err) {
            return void 0;
          });
        }
      }
    }, 'onChange123');
    return function () {
      _KeyboardEvents.KeyboardEvents.remove('onChange123');
    };
  }, [value]);
  (0, _react.useEffect)(function () {
    if (props.onChange != null) {
      var s = [];
      value.forEach(function (v) {
        var val = v.value.trim();
        if (val && val.length > 0 && s.findIndex(function (a) {
          return a === val;
        }) === -1) {
          s.push(val);
        }
      });
      props.onChange(s);
    }
  }, [value]);
  var onChange = function onChange(key) {
    return function (val) {
      var newVal = (0, _toConsumableArray2.default)(value);
      var find = newVal.findIndex(function (a) {
        return a.key === key;
      });
      if (find >= 0) {
        newVal[find] = {
          key: key,
          value: val
        };
      } else if (find === -1) {
        newVal.push({
          key: key,
          value: val
        });
      }
      setValue(newVal);
    };
  };
  var onFocus = function onFocus(key) {
    return function () {
      setHasFocus(key);
    };
  };
  var onBlur = function onBlur(_key) {
    return function () {
      setHasFocus('');
    };
  };
  var onSubmit = function onSubmit(key) {
    return function () {
      var index = value.findIndex(function (a) {
        return a.key === key;
      });
      if (index > -1) {
        var newVal = [];
        var newKey = uuid.v1();
        value.forEach(function (v, i) {
          newVal.push(v);
          if (i === index) {
            newVal.push({
              key: newKey,
              value: ''
            });
          }
        });
        setHasFocus(newKey);
        setValue(newVal);
      }
    };
  };
  var onDeleteNow = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* (key) {
      var canDelete = true;
      if (props.beforeDeleteFunction) {
        canDelete = yield props.beforeDeleteFunction(key);
      }
      if (canDelete) {
        var newVal = [];
        value.forEach(function (v) {
          if (v.key !== key) newVal.push(v);
        });
        setValue(newVal);
        if (hasfocus !== '') setHasFocus('');
      }
    });
    return function onDeleteNow(_x) {
      return _ref.apply(this, arguments);
    };
  }();
  var onDelete = function onDelete(key) {
    return function () {
      onDeleteNow(key).catch(function (err) {
        return void 0;
      });
    };
  };
  var setOrder = function setOrder(data) {
    setValue((0, _toConsumableArray2.default)(data));
  };
  var renderFormField = function renderFormField(item, drag) {
    var webStyle = _reactNativeWeb.Platform.OS === 'web' ? {
      cursor: 'move'
    } : {};
    return (0, _jsxRuntime.jsx)(_reactNativeDraggableFlatlist.ScaleDecorator, {
      activeScale: 1.05,
      children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          height: 40,
          marginBottom: 4,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: _ThemeManager.ThemeManager.style.formInputFilledBackgroundColor
        },
        children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
          onPressIn: drag,
          style: Object.assign({
            width: 40
          }, webStyle),
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "drag-horizontal",
            toolTip: ""
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            flex: 1
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.TextInput, {
            onKeyPress: function onKeyPress(ev) {
              try {
                var e = ev.nativeEvent;
                if (e.key === 'ArrowDown' || e.key === 'Tab') {
                  var current = value.findIndex(function (a) {
                    return a.key === hasfocus;
                  });
                  if (current + 1 < value.length) {
                    e.preventDefault();
                    setHasFocus(value[current + 1].key);
                  }
                } else if (e.key === 'ArrowUp') {
                  var _current = value.findIndex(function (a) {
                    return a.key === hasfocus;
                  });
                  var prev = _current - 1;
                  if (prev < value.length && prev >= 0) {
                    e.preventDefault();
                    setHasFocus(value[prev].key);
                  }
                } else if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
                  e.preventDefault();
                  navigator.clipboard.readText().then(function (t) {
                    var text = [];
                    var st2 = t.split('\t');
                    st2.forEach(function (a) {
                      var st = a.split('\n');
                      st.forEach(function (b) {
                        text.push(b);
                      });
                    });
                    if (text.length > 1) {
                      e.preventDefault();
                      var newVal = (0, _toConsumableArray2.default)(value);
                      var _loop2 = function _loop2(_t2) {
                        if (_t2.length > 0 && !newVal.find(function (c) {
                          return c.value === _t2;
                        })) {
                          newVal.push({
                            value: _t2,
                            key: uuid.v1()
                          });
                        }
                      };
                      for (var _t2 of text) {
                        _loop2(_t2);
                      }
                      setValue(newVal);
                    } else if (text.length === 1) {
                      try {
                        var st = item.value.substring(0, selection.current.start);
                        var _st = item.value.substring(selection.current.end);
                        onChange(item.key)(`${st}${text[0]}${_st}`);
                      } catch (e) {}
                    }
                  }).catch(function (err) {
                    return void 0;
                  });
                }
              } catch (e) {}
            },
            onSelectionChange: function onSelectionChange(a) {
              try {
                if (a.nativeEvent.selection) {
                  selection.current = a.nativeEvent.selection;
                }
              } catch (e) {}
            },
            style: [(0, _MaterialText.getStyleForType)(_MaterialText.MaterialTextTypes.Body1), {
              width: '100%',
              fontSize: _ThemeManager.ThemeManager.style.getTextInputFontSize()
            }],
            numberOfLines: 1,
            onChangeText: onChange(item.key),
            onBlur: onBlur(item.key),
            onFocus: onFocus(item.key),
            onSubmitEditing: onSubmit(item.key),
            autoFocus: hasfocus === item.key,
            value: item.value
          })
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 64,
            alignItems: 'flex-end'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            icon: "delete-outline",
            toolTip: _I18n.I18n.m.getMessage('delete'),
            onPress: onDelete(item.key)
          })
        })]
      }, `outer_${item.key}_${hasfocus === item.key}`)
    });
  };
  var renderField = function renderField(_ref2) {
    var item = _ref2.item,
      drag = _ref2.drag;
    return renderFormField(item, drag);
  };
  var renderFields = function renderFields() {
    return (0, _jsxRuntime.jsx)(_reactNativeDraggableFlatlist.default, {
      style: {
        width: '100%'
      },
      data: value,
      renderItem: renderField,
      keyExtractor: function keyExtractor(item) {
        return `${item.key}`;
      },
      onDragEnd: function onDragEnd(_ref3) {
        var data = _ref3.data;
        return setOrder(data);
      },
      extraData: hasfocus,
      debug: false
    });
  };
  var onAdd = function onAdd() {
    var newVal = (0, _toConsumableArray2.default)(value);
    var newKey = uuid.v1();
    newVal.push({
      key: newKey,
      value: ''
    });
    setHasFocus(newKey);
    setValue(newVal);
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      children: [(props.title != null && props.title.length > 0 || props.subTitle != null && props.subTitle.length > 0) && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          paddingVertical: 8
        },
        children: [props.title != null && props.title.length > 0 && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Body2,
          strong: true,
          children: props.title
        }), props.subTitle != null && props.subTitle.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Body2,
          children: props.subTitle
        }) : (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Body2,
          children: _I18n.I18n.m.getMessage('listOptionsSubTitle')
        })]
      }), props.options != null && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        icon: props.optionIcon ? props.optionIcon : 'dots-vertical',
        toolTip: props.optionTooltip ? props.optionTooltip : '',
        onPress: props.options
      })]
    }), renderFields(), !props.disabled && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        paddingVertical: 8
      },
      children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        onPress: function onPress() {
          onAdd();
        },
        title: _I18n.I18n.m.getMessage('addOption'),
        icon: {
          icon: 'plus',
          toolTip: ''
        },
        borderStyle: "solid",
        outlineColor: _ThemeManager.ThemeManager.style.brandPrimary,
        full: true,
        backgroundColor: "#FFFFFF",
        textColor: _ThemeManager.ThemeManager.style.brandPrimary
      })
    })]
  });
}