import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, Text, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class ProjectImage extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.onImageLoadError = (_e) => {
            this.setState({ loadError: true });
        };
        this.onImageNotFound = () => {
            this.setState({ loadError: true });
        };
        const { project } = this.props;
        const projectImageLink = project.projectImageFileId != null && project.projectImageFileId.length > 0
            ? `${UpmeshClient.instance.url}/project/${project.id}/image/${project.projectImageFileId}`
            : undefined;
        this.state = {
            projectImageLink,
            loadError: false,
        };
    }
    componentDidMount() {
        this.initStateColor().catch((err) => console.debug(err));
    }
    getProjectColor() {
        const { projectStateColor } = this.state;
        const { project } = this.props;
        if (projectStateColor != null)
            return projectStateColor;
        return project.color;
    }
    async initStateColor() {
        const { project } = this.props;
        try {
            const projectInfo = await UpmeshClient.instance.modals.companyProjectInfo.get({
                filter: `projectId eq '${project.id}' and companyId eq '${CompanyUserInfo.companySettings?.id}' and deleted ne true`,
            });
            if (projectInfo != null && projectInfo.length > 0) {
                const pState = CompanyUserInfo.companySettings?.projectStateNames.find((elem) => elem.id === projectInfo[0].projectStateId);
                if (pState != null) {
                    this.setState({ projectStateColor: pState.color });
                }
            }
        }
        catch (err) {
            console.error('could not load company project info');
        }
    }
    static getDerivedStateFromProps(nextProps, _prevState) {
        const projectImageLink = nextProps.project.projectImageFileId != null && nextProps.project.projectImageFileId.length > 0
            ? `${UpmeshClient.instance.url}/project/${nextProps.project.id}/image/${nextProps.project.projectImageFileId}`
            : undefined;
        return {
            projectImageLink,
        };
    }
    render() {
        const { size, project, lightBox } = this.props;
        const { projectImageLink, tempFileId, loadError } = this.state;
        if (!loadError && projectImageLink != null && projectImageLink.length > 0) {
            return (<View style={{
                    width: size,
                    height: size,
                    backgroundColor: 'transparent',
                    borderRadius: size / 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderColor: this.getProjectColor() != null ? this.getProjectColor() : 'transparent',
                    borderWidth: 2,
                }}>
          <CacheableImage key={`${project.projectImageFileId}_${size}`} onlyOnConnectedServer={false} width={size - 4} height={size - 4} tempFileId={tempFileId} style={{
                    borderRadius: size - 4 / 2,
                }} lightBox={lightBox} source={{ uri: projectImageLink }} onError={this.onImageLoadError} resizeMode="cover" onNotFound={this.onImageNotFound}/>
        </View>);
        }
        let initialien = '?';
        if (project.title != null && project.title.length >= 1) {
            if (project.title.length >= 2) {
                initialien = project.title.substr(0, 2);
            }
            else {
                initialien = project.title.substr(0, 1);
            }
        }
        const extraTopPadding = Platform.OS !== 'web' ? { paddingTop: '10%' } : {};
        return (<View style={{
                width: size,
                height: size,
                backgroundColor: this.getProjectColor() != null ? this.getProjectColor() : ThemeManager.style.brandPrimary,
                borderRadius: size / 2,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
        <Text style={{
                width: '100%',
                color: this.getProjectColor() == null && this.getProjectColor() === 'transparent'
                    ? ThemeManager.style.black87
                    : '#FFFFFF',
                textAlign: 'center',
                fontSize: size / 2,
                lineHeight: size / 2,
                fontFamily: 'roboto',
                ...extraTopPadding,
            }} allowFontScaling adjustsFontSizeToFit numberOfLines={1}>
          {initialien}
        </Text>
      </View>);
    }
}
