import React from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { View } from 'react-native';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { I18n } from '../../i18n/I18n';
import { compareDates } from '../tickets/TicketCalendar/calendarUtil';
export function JournalCard(props) {
    const weekDays = I18n.m.dateCurrent.weekdayNamesShort();
    const renderDot = () => {
        const { journal } = props;
        let backgroundColor = ThemeManager.style.black38;
        if (journal != null)
            if (journal.versions != null && journal.versions > 0)
                backgroundColor = ThemeManager.style.brandSecondary;
            else
                backgroundColor = ThemeManager.style.brandWarning;
        return (<View style={{
                minWidth: 24,
                height: 24,
                borderRadius: 12,
                backgroundColor,
                alignItems: 'flex-end',
                paddingTop: 3,
            }}>
        <MaterialText centeredBox centeredText color={ThemeManager.style.white87} numberOfLines={1}>
          {journal != null && journal.versions != null ? journal.versions.toString() : ''}
        </MaterialText>
      </View>);
    };
    const renderLView = () => {
        const { cardDate, journal, onPressJournal, disabled } = props;
        const today = compareDates(cardDate, new Date());
        const width = 93;
        const height = 150;
        const elevation = journal != null ? ThemeManager.style.elevation2 : {};
        return (<View style={{ width, height, marginLeft: 16, marginVertical: 4, overflow: 'visible' }} key={`journalCard_${cardDate}`}>
        <Ripple disabled={disabled} onPress={(e) => onPressJournal(journal, e)} style={{
                width,
                height,
                alignItems: 'center',
                paddingVertical: ThemeManager.style.contentPaddingValue,
                borderColor: ThemeManager.style.black38,
                borderWidth: journal != null ? 0 : ThemeManager.style.borderWidth,
                borderRadius: ThemeManager.style.borderRadius,
                backgroundColor: journal != null ? ThemeManager.style.white87 : 'transparent',
                ...elevation,
            }}>
          <MaterialText numberOfLines={1} centeredBox centeredText type={MaterialTextTypes.H6} color={journal != null ? ThemeManager.style.black87 : ThemeManager.style.black38}>
            {weekDays[cardDate.getDay()]}
          </MaterialText>
          <MaterialText centeredBox centeredText numberOfLines={1} type={MaterialTextTypes.H4} color={journal != null ? ThemeManager.style.brandPrimary : ThemeManager.style.black38}>
            {cardDate.getDate()}
          </MaterialText>
          {today ? (<MaterialText centeredBox numberOfLines={1} centeredText type={MaterialTextTypes.H5} color={journal != null ? ThemeManager.style.brandPrimary : ThemeManager.style.black38}>
              {I18n.m.getMessage('today')}
            </MaterialText>) : (<View />)}
          <View style={{ flex: 1 }}/>
          {renderDot()}
        </Ripple>
      </View>);
    };
    const renderSView = () => {
        const { cardDate, journal, onPressJournal, disabled } = props;
        const elevation = journal != null ? ThemeManager.style.elevation2 : {};
        return (<View style={{ marginHorizontal: 16, marginVertical: 4, overflow: 'visible' }}>
        <Ripple style={{
                flexDirection: 'row',
                padding: 8,
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                borderColor: ThemeManager.style.black38,
                borderWidth: journal != null ? 0 : ThemeManager.style.borderWidth,
                borderRadius: ThemeManager.style.borderRadius,
                backgroundColor: journal != null ? ThemeManager.style.white87 : 'transparent',
                ...elevation,
            }} disabled={disabled} onPress={(e) => onPressJournal(journal, e)}>
          <View style={{}}>
            <MaterialText type={MaterialTextTypes.H6} numberOfLines={1} color={journal != null ? ThemeManager.style.black87 : ThemeManager.style.black38}>
              {weekDays[cardDate.getDay()]}
            </MaterialText>
            <MaterialText type={MaterialTextTypes.Subtitle1} numberOfLines={1} color={journal != null ? ThemeManager.style.black54 : ThemeManager.style.black38}>
              {cardDate.toLocaleDateString()}
            </MaterialText>
          </View>
          {renderDot()}
        </Ripple>
      </View>);
    };
    return ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM ? renderLView() : renderSView();
}
