var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SliderV2 = SliderV2;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _color = _interopRequireDefault(require("color"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _Chip = require("./chips/Chip");
var _Icon = require("./Icon");
var _ScrollHandler = require("./scroll/ScrollHandler");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var defaultProps = {
  value: 0,
  minimumValue: 0,
  maximumValue: 100,
  mainColor: _ThemeManager.ThemeManager.style.brandPrimary,
  disabledColor: (0, _color.default)(_ThemeManager.ThemeManager.style.appBgColor).darken(0.5).toString(),
  stepWidth: 1
};
function SliderV2(inputProps) {
  var props = Object.assign({}, defaultProps, inputProps);
  var _useState = (0, _react.useState)(undefined),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    moveStart = _useState2[0],
    setMoveStart = _useState2[1];
  var _useState3 = (0, _react.useState)(undefined),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    lastMove = _useState4[0],
    setLastMove = _useState4[1];
  var _useState5 = (0, _react.useState)(props.value ? props.value : 0),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    value = _useState6[0],
    setValue = _useState6[1];
  var _useState7 = (0, _react.useState)(props.value ? props.value : 0),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    steps = _useState8[0],
    setSteps = _useState8[1];
  var _useState9 = (0, _react.useState)(props.value ? props.value : 0),
    _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
    valueBeforeMove = _useState10[0],
    setValueBeforeMove = _useState10[1];
  var _useState11 = (0, _react.useState)(0),
    _useState12 = (0, _slicedToArray2.default)(_useState11, 2),
    width = _useState12[0],
    setWidth = _useState12[1];
  var _useState13 = (0, _react.useState)(0),
    _useState14 = (0, _slicedToArray2.default)(_useState13, 2),
    widthPerStep = _useState14[0],
    setWidthPerStep = _useState14[1];
  var moveS = _reactNativeWeb.Platform.OS === 'web' && props.disabled !== true ? {
    cursor: moveStart != null ? 'grabbing' : 'pointer'
  } : {};
  (0, _react.useEffect)(function () {
    setWidthPerStep(width / (props.maximumValue - props.minimumValue) * (props.stepWidth != null ? props.stepWidth : 1));
  }, [props.maximumValue, props.minimumValue, props.stepWidth]);
  (0, _react.useEffect)(function () {
    var maximumValue = props.maximumValue,
      minimumValue = props.minimumValue,
      stepWidth = props.stepWidth;
    var newValue = props.value;
    if (newValue == null || newValue < minimumValue) newValue = minimumValue;else if (newValue > maximumValue) newValue = maximumValue;
    setValue(newValue);
    var stepW = stepWidth || 1;
    setSteps((newValue - minimumValue) / stepW);
  }, [props.value]);
  var sliderPanResponder = _reactNativeWeb.PanResponder.create({
    onStartShouldSetPanResponder: function onStartShouldSetPanResponder() {
      return true;
    },
    onStartShouldSetPanResponderCapture: function onStartShouldSetPanResponderCapture() {
      return true;
    },
    onMoveShouldSetPanResponder: function onMoveShouldSetPanResponder() {
      return true;
    },
    onMoveShouldSetPanResponderCapture: function onMoveShouldSetPanResponderCapture() {
      return true;
    },
    onPanResponderGrant: function onPanResponderGrant() {
      return true;
    },
    onPanResponderMove: function onPanResponderMove(_e, gestureState) {
      if (props.disabled !== true) {
        if (moveStart != null) {
          var lm = {
            x: gestureState.moveX - moveStart.x,
            y: gestureState.moveY - moveStart.y
          };
          setLastMove(lm);
          var stepWidth = props.stepWidth ? props.stepWidth : 1;
          var newValue = Math.round(valueBeforeMove / stepWidth + lm.x / widthPerStep) * stepWidth;
          if (newValue < props.minimumValue) newValue = props.minimumValue;else if (newValue > props.maximumValue) newValue = props.maximumValue;
          setValue(newValue);
          setSteps((newValue - props.minimumValue) / stepWidth);
          if (props.onValueChange) props.onValueChange(newValue);
        } else {
          _ScrollHandler.ScrollHandler.disableAll();
          setMoveStart({
            x: gestureState.moveX,
            y: gestureState.moveY
          });
          setValueBeforeMove(value);
        }
      }
    },
    onPanResponderEnd: function onPanResponderEnd(_evt, _gestureState) {
      setValueBeforeMove(value);
      setLastMove(undefined);
      setMoveStart(undefined);
    },
    onPanResponderRelease: function onPanResponderRelease(_e, _gestureState) {
      _ScrollHandler.ScrollHandler.enableAll();
      if (moveStart != null && lastMove != null) {
        setLastMove(undefined);
        setMoveStart(undefined);
        if (props.onRelease) props.onRelease(value);
      }
    }
  });
  var renderToolTip = function renderToolTip() {
    if (props.showCurrentValue === false || moveStart == null) return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
    var ttWidth = 8 * 2 + 50;
    var position = steps * widthPerStep - ttWidth / 2;
    if (position < 0) position = 0;else if (position + ttWidth / 2 > width - ttWidth / 2) position = width - ttWidth;
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        height: 28,
        borderRadius: 14,
        paddingVertical: 6,
        paddingHorizontal: 8,
        backgroundColor: _ThemeManager.ThemeManager.style.black87,
        position: 'absolute',
        top: -32,
        left: position,
        width: ttWidth
      },
      children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        color: "#ffffff",
        centeredText: true,
        centeredBox: true,
        type: _MaterialText.MaterialTextTypes.Body2,
        numberOfLines: 1,
        children: value.toString()
      })
    });
  };
  var renderDot = function renderDot(style, color) {
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: Object.assign({
        position: 'absolute'
      }, style),
      children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
        icon: "circle-small",
        toolTip: "",
        outerSize: 16,
        color: color
      })
    }, `dot${color}_${style.left}_${style.right}`);
  };
  var handlePress = function handlePress(event, side) {
    var _event$nativeEvent = event.nativeEvent,
      locationX = _event$nativeEvent.locationX,
      layerX = _event$nativeEvent.layerX;
    var pressedX = layerX != null ? layerX : locationX;
    if (pressedX != null) {
      var stepWidth = props.stepWidth ? props.stepWidth : 1;
      var sliderPixels = side === 'right' ? 18 : 0;
      var newValue = Math.round((pressedX + sliderPixels) / widthPerStep) * stepWidth;
      if (side === 'right') newValue = value + newValue;else newValue += props.minimumValue;
      if (newValue < props.minimumValue) newValue = props.minimumValue;else if (newValue > props.maximumValue) newValue = props.maximumValue;
      setValue(newValue);
      setSteps((newValue - props.minimumValue) / stepWidth);
      if (props.onRelease) props.onRelease(newValue);
      if (props.onValueChange) props.onValueChange(newValue);
    }
  };
  var renderAllDots = function renderAllDots() {
    var mainColor = props.mainColor,
      disabledColor = props.disabledColor,
      disabled = props.disabled;
    var allDots = [];
    var color = disabled === true ? disabledColor : mainColor;
    if (widthPerStep > 16) {
      for (var i = 0; i < width / widthPerStep; i += 1) {
        if (i !== steps) allDots.push(renderDot({
          left: i * widthPerStep
        }, i > steps ? color : (0, _color.default)(color).lighten(0.8).toString()));
      }
    } else if (steps * widthPerStep < width - 16) {
      allDots.push(renderDot({
        right: 0
      }, color));
    }
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        width: width,
        height: 16,
        alignItems: 'center',
        position: 'absolute',
        top: 14,
        left: 0,
        right: 0,
        bottom: 0
      },
      pointerEvents: "none",
      children: allDots
    });
  };
  var renderSlider = function renderSlider() {
    var mainColor = props.mainColor,
      disabledColor = props.disabledColor,
      disabled = props.disabled;
    var color = disabled === true ? disabledColor : mainColor;
    if (width === 0) return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        height: 16,
        borderRadius: 8,
        backgroundColor: (0, _color.default)(color).lighten(0.8).toString(),
        flex: 1,
        marginHorizontal: 16,
        marginTop: 14
      }
    });
    var sliderWidth = moveStart != null ? 3 : 6;
    var sliderMargin = 6;
    var sliderPosition = widthPerStep * steps - sliderWidth / 2;
    if (sliderPosition < 0) sliderPosition = 0;
    if (sliderPosition > width - sliderWidth) sliderPosition = width - sliderWidth;
    var bar1Position = sliderPosition > sliderWidth ? sliderPosition - sliderMargin : 0;
    var bar2Position = sliderPosition < width - sliderWidth ? width - sliderPosition - sliderWidth - sliderMargin : 0;
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: width,
        alignItems: 'center',
        height: 200,
        paddingHorizontal: 16
      },
      children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.Pressable, {
        onPress: function onPress(e) {
          return handlePress(e, 'left');
        },
        disabled: props.disabled,
        style: {
          height: 16,
          borderRadius: 2,
          borderBottomLeftRadius: 8,
          borderTopLeftRadius: 8,
          backgroundColor: color,
          position: 'absolute',
          top: 14,
          left: 0,
          width: bar1Position,
          flexDirection: 'row'
        }
      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({
        style: Object.assign({
          height: 44,
          width: sliderWidth + 12,
          position: 'absolute',
          top: 0,
          left: sliderPosition - sliderMargin,
          paddingHorizontal: 6
        }, moveS)
      }, sliderPanResponder.panHandlers, {
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 44,
            backgroundColor: color,
            width: sliderWidth,
            borderRadius: sliderWidth / 2
          }
        })
      })), (0, _jsxRuntime.jsx)(_reactNativeWeb.Pressable, {
        onPress: function onPress(e) {
          return handlePress(e, 'right');
        },
        disabled: props.disabled,
        style: {
          height: 16,
          borderRadius: 2,
          borderBottomRightRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: (0, _color.default)(color).lighten(0.8).toString(),
          position: 'absolute',
          top: 14,
          right: 0,
          width: bar2Position,
          flexDirection: 'row'
        }
      }), renderAllDots()]
    });
  };
  var onLayoutView = function onLayoutView(e) {
    if (e && e.nativeEvent && e.nativeEvent.layout && e.nativeEvent.layout.width > 0) {
      var newWidth = e.nativeEvent.layout.width;
      setWidth(newWidth);
      setWidthPerStep(newWidth / (props.maximumValue - props.minimumValue) * (props.stepWidth != null ? props.stepWidth : 1));
    }
  };
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: Object.assign({
      overflow: 'visible',
      flexDirection: 'row'
    }, _ThemeManager.ThemeManager.noSelectionWebStyle()),
    children: [renderToolTip(), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row',
        height: 44,
        flex: 1
      },
      onLayout: onLayoutView,
      children: renderSlider()
    }), props.showValue ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        paddingLeft: 8
      },
      children: (0, _jsxRuntime.jsx)(_Chip.Chip, {
        backgroundColor: (0, _color.default)(props.disabled === true ? props.disabledColor : props.mainColor).lighten(0.8).toString(),
        title: value.toString()
      })
    }) : false]
  });
}