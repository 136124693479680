import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { CustomFieldProgressOptions } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { SliderV2 } from 'materialTheme/src/theme/components/SliderV2';
export function TicketProgress({ ticket, progressField, labelStyle, ticketValue, errorResult, editable }) {
    const o = new CustomFieldProgressOptions(progressField?.customField?.options);
    const [value, setValue] = useState(ticketValue || (o.default ? o.default : o.start));
    useEffect(() => {
        if (ticket && ticket.fields) {
            ticket?.fields.forEach((field) => {
                if (field.id === progressField.id && field.value !== value) {
                    setValue(field.value);
                }
            });
        }
    }, [ticket.fields]);
    const onChangeSlider = (v) => {
        setValue(v);
        if (ticket != null) {
            const c = new ChangeTicketCustomField({ fieldId: progressField.id, value: v }, ticket.id);
            c.execute().catch((err) => errorResult(err));
        }
    };
    return (<>
      <View style={labelStyle} key="ProgressLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {progressField.label}
        </MaterialText>
      </View>
      <SliderV2 showValue onRelease={onChangeSlider} value={value} minimumValue={o.start ? o.start : 0} maximumValue={o.end ? o.end : 10} disabled={!editable} stepWidth={o.stepWidth ? o.stepWidth : 1}/>
    </>);
}
