import React, { useEffect, useState } from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { FlatList, View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { I18n } from '../../i18n/I18n';
import { compareDates, getKW, numberToDate } from '../tickets/TicketCalendar/calendarUtil';
import { JournalCard } from './JournalCard';
import { CurrentProject } from '../project/CurrentProject';
export function JournalCardsView(props) {
    const today = new Date();
    const [journalsPerWeek, setJournalsPerWeek] = useState([]);
    const [showEmptyDays, setShowEmptyDays] = useState(props.searched);
    const getFirstShown = () => {
        let startDate = new Date();
        const { visibleJournals } = props;
        if (visibleJournals.length > 0 && numberToDate(visibleJournals[0].forDay).getTime() < startDate.getTime())
            startDate = numberToDate(visibleJournals[0].forDay);
        const project = CurrentProject.instance.getCurrentProject();
        if (project && project.projectStart && project.projectStart.getTime() < startDate.getTime()) {
            startDate = project.projectStart;
        }
        startDate = new Date(startDate.getTime() - 1000 * 60 * 60 * 24 * 7 * 4);
        return startDate;
    };
    const firstShown = getFirstShown();
    useEffect(() => {
        const journals = props.visibleJournals;
        let currentJournalIndex = 0;
        const journalWeeks = [];
        const startsWithMonday = CurrentUser.settings.startWithMonday;
        let currentWeek = { kw: getKW(new Date(), startsWithMonday), journals: [], startDate: new Date() };
        let date = new Date();
        const compareJournalDate = (j) => compareDates(date, numberToDate(j.forDay));
        while (date.getTime() >= firstShown.getTime()) {
            const dailyJournal = journals.find(compareJournalDate);
            if (getKW(date, startsWithMonday) !== currentWeek.kw) {
                currentWeek.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
                journalWeeks.push(currentWeek);
                currentWeek = { kw: getKW(date, startsWithMonday), journals: [], startDate: date };
            }
            if (dailyJournal != null) {
                currentWeek.journals.push(dailyJournal);
                currentJournalIndex += 1;
            }
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
        }
        journalWeeks.push(currentWeek);
        setJournalsPerWeek(journalWeeks);
        setShowEmptyDays(props.searched);
    }, [props.visibleJournals]);
    const renderWeek = ({ item }) => {
        const { openEditDialog, onPressJournal } = props;
        const { journals, kw, startDate } = item;
        if (showEmptyDays && journals.length === 0)
            return <View />;
        let headline = `${I18n.m.getMessage('dateWeekNumberShort')} ${kw}`;
        let lastDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
        if (lastDay.getTime() >= today.getTime())
            headline += ` — ${I18n.m.getMessage('journalCardsThisWeek')}`;
        if (lastDay.getTime() > today.getTime())
            lastDay = today;
        const renderedDays = [];
        let currentDay = startDate;
        const compareJournalDate = (j) => compareDates(currentDay, numberToDate(j.forDay));
        while (currentDay.getTime() <= lastDay.getTime()) {
            const journal = journals.find(compareJournalDate);
            const forDay = currentDay;
            if (!showEmptyDays || journal != null)
                renderedDays.push(<JournalCard cardDate={forDay} journal={journal} onPressJournal={journal != null ? onPressJournal : () => openEditDialog(forDay)} disabled={journal == null && false}/>);
            currentDay = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate() + 1);
        }
        const secondLine = `${startDate.toLocaleDateString()} — ${lastDay.toLocaleDateString()}`;
        return (<View key={`week${startDate}`} style={{ overflow: 'visible', paddingTop: 12 }}>
        <View style={{ paddingLeft: 16 }}>
          <MaterialText type={MaterialTextTypes.H6}>{headline}</MaterialText>
          <MaterialText color={ThemeManager.style.black42} type={MaterialTextTypes.Subtitle1}>
            {secondLine}
          </MaterialText>
        </View>
        <View style={{
                flexDirection: ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM ? 'row' : 'column',
                overflow: 'visible',
            }}>
          {renderedDays}
        </View>
      </View>);
    };
    return (<View style={{ width: '100%' }}>
      <FlatList style={{ height: '100%' }} data={journalsPerWeek} renderItem={renderWeek} keyExtractor={(item) => `JournalCardItem${item.startDate}`}/>
    </View>);
}
