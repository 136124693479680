import * as EmailValidator from 'email-validator';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddNewOrExistingUserToCompany, } from 'upmesh-core/src/client/commands/companies/AddNewOrExistingUserToCompany';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class AddUserToCompanyDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onChangeLicenseIndex = (value) => {
            const s = { ...this.state };
            s.selectedLicenseIndex = value.index;
            s.payroll = false;
            s['errors'] = this.checkForErrors(s);
            this.setState(s);
        };
        this.cancel = () => {
            Dialog.instance?.close();
        };
        this.checkForErrors = (s) => {
            const { firstName, lastName, email, errors, selectedLicenseIndex } = s;
            if (selectedLicenseIndex < 2 && email.length === 0) {
                errors['email'] = I18n.m.getMessage('required');
            }
            else if (email.length > 0 && !EmailValidator.validate(email)) {
                errors['email'] = I18n.m.getMessage('emailValidationError');
            }
            else {
                errors['email'] = null;
            }
            if (firstName.length === 0) {
                errors['firstName'] = I18n.m.getMessage('required');
            }
            else {
                errors['firstName'] = null;
            }
            if (lastName.length === 0) {
                errors['lastName'] = I18n.m.getMessage('required');
            }
            else {
                errors['lastName'] = null;
            }
            return errors;
        };
        this.onChange = (value) => (text) => {
            const s = { ...this.state };
            s[value] = text;
            s['errors'] = this.checkForErrors(s);
            this.setState(s);
        };
        this.onChangePickerIndex = (type) => (value) => {
            const s = { ...this.state };
            s[type] = value.index;
            s['errors'] = this.checkForErrors(s);
            this.setState(s);
        };
        this.submit = () => {
            this.setState({ isLoading: true }, this.submitNow);
        };
        this.submitNow = () => {
            const { company } = this.props;
            const { selectedLicenseIndex, staffNumber, firstName, lastName, selectedGroupIndex, selectedTimeModelIndex, email, disableJournalEdit, groupList, timeModelList, isAdmin, payroll, extraPayTracking, canInviteEmployees, selectedTimeTrackingForIndex, selectedTimeTrackingFromIndex, selectedAbsenceForIndex, selectedCanPermitAbsenceForIndex, selectedCanLockTimeTrackingFromIndex, } = this.state;
            let role = 'user';
            const licence = this.getLicenseList()[selectedLicenseIndex].data;
            if (licence === 'full' && isAdmin) {
                role = 'admin';
            }
            else if (licence === 'full') {
                role = 'user';
            }
            else if (licence === 'collaborator') {
                role = 'collaborator';
            }
            else if (licence === 'timeTracker') {
                role = 'timeTracker';
            }
            else {
                role = 'inactive';
            }
            const timeTrackingFor = this.getTimeTrackingForList()[selectedTimeTrackingForIndex].data;
            const timeTrackingFrom = this.getTimeTrackingFromList()[selectedTimeTrackingFromIndex].data;
            const list = this.getAbsenceForList(selectedLicenseIndex);
            const absenceFor = list[Math.min(list.length - 1, selectedAbsenceForIndex)].data;
            const list2 = this.getCanPermitAbsenceForList(selectedLicenseIndex);
            const canPermitAbsenceFor = list2[Math.min(list2.length - 1, selectedCanPermitAbsenceForIndex)].data;
            const list3 = this.getCanLockTimeTrackingFromList(selectedLicenseIndex);
            const canLockTimeTrackingFrom = list3[Math.min(list2.length - 1, selectedCanLockTimeTrackingFromIndex)].data;
            try {
                const data = {
                    email,
                    staffNumber,
                    companyId: company.id,
                    role,
                    firstname: firstName,
                    lastname: lastName,
                    disableJournalEdit: selectedLicenseIndex >= 2 ? false : !!disableJournalEdit,
                    groupId: groupList[selectedGroupIndex].data,
                    workingTimeModelId: timeModelList[selectedTimeModelIndex].data,
                    canInviteEmployees: selectedLicenseIndex === 0 ? canInviteEmployees : false,
                    payroll: selectedLicenseIndex === 0 ? payroll : false,
                    extraPayTracking: selectedLicenseIndex === 3 ? false : payroll ? true : extraPayTracking,
                    timeTrackingFor: payroll ? 'all' : timeTrackingFor,
                    timeTrackingFrom: payroll ? 'all' : timeTrackingFrom,
                    absenceFor: payroll ? 'all' : absenceFor,
                    canPermitAbsenceFor: payroll ? 'all' : canPermitAbsenceFor,
                    canLockTimeTrackingFrom: payroll ? 'all' : canLockTimeTrackingFrom,
                };
                const addNewOr = new AddNewOrExistingUserToCompany(data);
                addNewOr
                    .execute(ClientStore.commandStore)
                    .then(() => {
                    this.setState({ isLoading: false });
                    Dialog.instance?.close();
                })
                    .catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                    this.setState({ isLoading: false });
                });
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                this.setState({ isLoading: false });
            }
        };
        const s = {
            addNewUser: false,
            email: '',
            disabledForm: false,
            disabledFormNames: false,
            firstName: '',
            lastName: '',
            staffNumber: '',
            groupList: [{ title: I18n.m.getMessage('loading'), data: props.company.id }],
            timeModelList: [{ title: I18n.m.getMessage('loading'), data: props.company.id }],
            payroll: false,
            isLoading: false,
            disabledSave: true,
            canInviteEmployees: false,
            selectedGroupIndex: 0,
            isAdmin: false,
            disableJournalEdit: false,
            selectedLicenseIndex: 0,
            selectedTimeModelIndex: 0,
            extraPayTracking: true,
            selectedTimeTrackingForIndex: 0,
            selectedTimeTrackingFromIndex: 0,
            selectedAbsenceForIndex: 0,
            selectedCanPermitAbsenceForIndex: 0,
            selectedCanLockTimeTrackingFromIndex: 0,
            errors: {},
        };
        this.state = { ...s, errors: this.checkForErrors(s) };
    }
    componentDidMount() {
        const { company } = this.props;
        UpmeshClient.instance.modals.group
            .get({ filter: `companyId eq '${company.id}' and deleted ne true` })
            .then((groups) => {
            const groupList = [];
            groups.forEach((g) => groupList.push({
                title: g.groupName === GroupEntity.getDefaultName() ? I18n.m.getMessage('companyGroupDefaultName') : g.groupName,
                data: g.id,
            }));
            this.setState({ groupList });
        })
            .catch((err) => console.debug(err));
        UpmeshClient.instance.modals.workingTimeModel
            .get({ filter: `companyId eq '${company.id}' and deleted ne true` })
            .then((groups) => {
            const timeModelList = [];
            groups.forEach((g) => timeModelList.push({ title: g.mark, data: g.id }));
            this.setState({ timeModelList });
        })
            .catch((err) => console.debug(err));
    }
    openInfoAlert(text) {
        Alert.instance?.open({
            content: I18n.m.getMessage(text),
            closeOnTouchOutside: false,
            buttons: [
                <ContainedButton title={I18n.m.getMessage('ok')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ],
        });
    }
    render() {
        const { email, staffNumber, disabledFormNames, disabledForm, firstName, lastName, groupList, timeModelList, isLoading, selectedTimeModelIndex, selectedGroupIndex, selectedLicenseIndex, errors, isAdmin, canInviteEmployees, disableJournalEdit, payroll, extraPayTracking, selectedTimeTrackingForIndex, selectedTimeTrackingFromIndex, selectedAbsenceForIndex, selectedCanPermitAbsenceForIndex, selectedCanLockTimeTrackingFromIndex, } = this.state;
        const hasOldTimeTrackingModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTrackingJournalEdit');
        return [
            <DialogTitle key="title">{I18n.m.getMessage('companyAddUserDilaogTitle')}</DialogTitle>,
            <DialogContent key="content">
        <MaterialText>{I18n.m.getMessage('companyAddUserDilaogText')}</MaterialText>
        <FormInputPicker selectedIndex={selectedLicenseIndex} disabled={disabledForm} list={this.getLicenseList()} labelText={I18n.m.getMessage('companyLicence')} onChange={this.onChangeLicenseIndex}/>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ flex: 1, paddingRight: 8 }}>
            <FormInputFilled value={firstName} disabled={disabledForm || disabledFormNames} labelText={I18n.m.getMessage('firstName')} onChange={this.onChange('firstName')} error={errors['firstName'] != null} helperText={errors['firstName']}/>
          </View>
          <View style={{ flex: 1, paddingLeft: 8 }}>
            <FormInputFilled value={lastName} disabled={disabledForm || disabledFormNames} labelText={I18n.m.getMessage('lastName')} onChange={this.onChange('lastName')} error={errors['lastName'] != null} helperText={errors['lastName']}/>
          </View>
        </View>
        <FormInputFilled value={email} labelText={I18n.m.getMessage('email')} keyboardType="email-address" onChange={this.onChange('email')} error={errors['email'] != null} helperText={errors['email']}/>
        <CheckboxListItem onChange={(isAdmin) => this.setState({ isAdmin })} disabled={selectedLicenseIndex !== 0} value={selectedLicenseIndex === 0 ? isAdmin : false} title={I18n.m.getMessage('companyRoleAdmin')} key={`admin_${(selectedLicenseIndex === 0 ? isAdmin : false).toString()}`} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogAdminInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <CheckboxListItem onChange={(payrollSel) => {
                    if (payrollSel) {
                        this.setState({ payroll: true, extraPayTracking: true });
                    }
                    else {
                        this.setState({ payroll: false });
                    }
                }} disabled={selectedLicenseIndex !== 0} key={`payroll_${(selectedLicenseIndex === 0 ? payroll : false).toString()}`} value={selectedLicenseIndex === 0 ? payroll : false} title={I18n.m.getMessage('payroll')} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogPayrollInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <CheckboxListItem onChange={(extraPayTracking) => this.setState({ extraPayTracking })} disabled={selectedLicenseIndex >= 3 || (payroll && selectedLicenseIndex === 0)} key={`extraPayTracking_${(selectedLicenseIndex >= 3 ? false : !extraPayTracking).toString()}`} value={selectedLicenseIndex >= 3 ? false : payroll ? true : extraPayTracking} title={I18n.m.getMessage('canTrackExtraPay')} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogExtraPayInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <CheckboxListItem onChange={(canInviteEmployees) => this.setState({ canInviteEmployees })} disabled={selectedLicenseIndex !== 0} key={`canInviteEmployees_${(selectedLicenseIndex === 0 ? canInviteEmployees : false).toString()}`} value={selectedLicenseIndex === 0 ? canInviteEmployees : false} title={I18n.m.getMessage('companyMemberCanInviteCompanyUserToProjects')} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogExtraPayInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <CheckboxListItem onChange={(disableJournalEdit) => this.setState({ disableJournalEdit: !disableJournalEdit })} disabled={(selectedLicenseIndex >= 2 && !hasOldTimeTrackingModule) ||
                    (selectedLicenseIndex >= 3 && hasOldTimeTrackingModule)} key={`disableJournalEdit_${((selectedLicenseIndex >= 2 && !hasOldTimeTrackingModule) ||
                    (selectedLicenseIndex >= 3 && hasOldTimeTrackingModule)
                    ? false
                    : !disableJournalEdit).toString()}`} value={(selectedLicenseIndex >= 2 && !hasOldTimeTrackingModule) ||
                    (selectedLicenseIndex >= 3 && hasOldTimeTrackingModule)
                    ? false
                    : !disableJournalEdit} title={I18n.m.getMessage('canEditJournals')} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogAddMemberInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <FormInputFilled onChange={this.onChange('staffNumber')} value={staffNumber} disabled={disabledForm} labelText={I18n.m.getMessage('staffNumber')}/>
        <FormInputPicker selectedIndex={selectedGroupIndex} disabled={disabledForm} list={groupList} labelText={I18n.m.getMessage('group')} onChange={this.onChangePickerIndex('selectedGroupIndex')}/>
        <FormInputPicker selectedIndex={selectedTimeModelIndex} disabled={disabledForm} list={timeModelList} labelText={I18n.m.getMessage('workingTimeModel')} onChange={this.onChangePickerIndex('selectedTimeModelIndex')}/>
        <FormInputPicker selectedIndex={selectedLicenseIndex >= 2 ? 0 : selectedTimeTrackingForIndex} disabled={disabledForm || selectedLicenseIndex >= 2} list={this.getTimeTrackingForList()} labelText={I18n.m.getMessage('companyUserCanTimeTrackFor')} onChange={this.onChangePickerIndex('selectedTimeTrackingForIndex')}/>
        <FormInputPicker selectedIndex={selectedLicenseIndex >= 2 ? 0 : selectedTimeTrackingFromIndex} disabled={disabledForm || selectedLicenseIndex >= 2} list={this.getTimeTrackingFromList()} labelText={I18n.m.getMessage('companyUserCanTimeTrackFrom')} onChange={this.onChangePickerIndex('selectedTimeTrackingFromIndex')}/>
        <FormInputPicker key={`companyUserLockTimeTrackingFrom_${selectedLicenseIndex}`} selectedIndex={payroll
                    ? 2
                    : Math.min(this.getCanLockTimeTrackingFromList(selectedLicenseIndex).length - 1, selectedCanLockTimeTrackingFromIndex)} disabled={disabledForm || payroll || selectedLicenseIndex >= 2} list={this.getCanLockTimeTrackingFromList(selectedLicenseIndex)} labelText={I18n.m.getMessage('companyUserCanLockTimeTrackingFrom')} onChange={this.onChangePickerIndex('selectedCanLockTimeTrackingFromIndex')}/>

        <FormInputPicker selectedIndex={Math.min(this.getAbsenceForList(selectedLicenseIndex).length - 1, selectedAbsenceForIndex)} disabled={disabledForm || selectedLicenseIndex >= 3} list={this.getAbsenceForList(selectedLicenseIndex)} labelText={I18n.m.getMessage('companyUserCanAbsenceFor')} onChange={this.onChangePickerIndex('selectedAbsenceForIndex')}/>
        <FormInputPicker selectedIndex={Math.min(this.getCanPermitAbsenceForList(selectedLicenseIndex).length - 1, selectedCanPermitAbsenceForIndex)} disabled={disabledForm || selectedLicenseIndex >= 3} list={this.getCanPermitAbsenceForList(selectedLicenseIndex)} labelText={I18n.m.getMessage('companyUserCanPermitAbsenceFor')} onChange={this.onChangePickerIndex('selectedCanPermitAbsenceForIndex')}/>
      </DialogContent>,
            <DialogActions key="actions" onBottom>
        <ContainedButton disabled={isLoading} title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} onPress={this.cancel} backgroundColor="transparent"/>
        <ContainedButton disabled={isLoading || errors['email'] != null || errors['firstName'] != null || errors['lastName'] != null} loading={isLoading} onPress={this.submit} title={I18n.m.getMessage('add')} backgroundColor={ThemeManager.style.brandPrimary}/>
      </DialogActions>,
        ];
    }
    getLicenseList() {
        return [
            {
                title: `${I18n.m.getMessage('companyUserLicenseFull')}`,
                data: 'full',
            },
            {
                title: `${I18n.m.getMessage('companyUserLicenseCollaborator')}`,
                data: 'collaborator',
            },
            {
                title: `${I18n.m.getMessage('companyUserLicenseTimeTracking')}`,
                data: 'timeTracker',
            },
            {
                title: `${I18n.m.getMessage('companyRoleInactive')}`,
                data: 'inactive',
            },
        ];
    }
    getTimeTrackingForList() {
        return [
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackForSelf')}`,
                data: 'self',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackForGroup')}`,
                data: 'group',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackForAll')}`,
                data: 'all',
            },
        ];
    }
    getTimeTrackingFromList() {
        return [
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackFromSelf')}`,
                data: 'self',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackFromGroup')}`,
                data: 'group',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackFromAll')}`,
                data: 'all',
            },
        ];
    }
    getCanLockTimeTrackingFromList(licenseIndex) {
        const d = [
            {
                title: `${I18n.m.getMessage('companyUserCanLockTimeTrackingFromNobody')}`,
                data: 'nobody',
            },
        ];
        if (licenseIndex < 2)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanLockTimeTrackingFromGroup')}`,
                data: 'group',
            });
        if (licenseIndex < 1)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanLockTimeTrackingFromAll')}`,
                data: 'all',
            });
        return d;
    }
    getCanPermitAbsenceForList(licenseIndex) {
        const d = [
            {
                title: `${I18n.m.getMessage('companyUserCanAbsenceForNobody')}`,
                data: 'nobody',
            },
        ];
        if (licenseIndex < 2)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForGroup')}`,
                data: 'group',
            });
        if (licenseIndex < 1)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForAll')}`,
                data: 'all',
            });
        return d;
    }
    getAbsenceForList(licenseIndex) {
        const d = [
            {
                title: `${I18n.m.getMessage('companyUserCanAbsenceForNobody')}`,
                data: 'nobody',
            },
        ];
        if (licenseIndex < 3)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForSelf')}`,
                data: 'self',
            });
        if (licenseIndex < 2)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForGroup')}`,
                data: 'group',
            });
        if (licenseIndex < 1)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForAll')}`,
                data: 'all',
            });
        return d;
    }
}
