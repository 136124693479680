import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { CustomFieldProgressOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { I18n } from '../../../../i18n/I18n';
import { TicketLayoutDefaultOptions } from './TicketLayoutDefaultOptions';
export function TicketLayoutProgressOptions(props) {
    const [currentField, setCurrentField] = useState(props.field);
    const [formError, setFormError] = useState(new Map());
    const checkForErrors = (currentField) => {
        if (currentField && currentField.customField && currentField.customField.options) {
            const { stepWidth } = currentField.customField.options;
            const defaultValue = currentField.customField.options.default;
            const start = currentField.customField.options.start ? currentField.customField.options.start : 0;
            const end = currentField.customField.options.end ? currentField.customField.options.end : 10;
            if (Number.isNaN(start)) {
                const s = new Map();
                s.set('start', I18n.m.getMessage('required'));
                return s;
            }
            if (end == null || Number.isNaN(end)) {
                const s = new Map();
                s.set('end', I18n.m.getMessage('required'));
                return s;
            }
            if (end < start) {
                const s = new Map();
                s.set('end', I18n.m.getMessage('ticketLayoutOptionsProgressEndSmallerThanStart'));
                return s;
            }
            if (defaultValue != null && !Number.isNaN(defaultValue) && (defaultValue < start || defaultValue > end)) {
                const s = new Map();
                s.set('defaultValue', I18n.m.getMessage('ticketLayoutOptionsProgressDefaultNotInRange'));
                return s;
            }
            if (stepWidth && stepWidth <= 0) {
                const s = new Map();
                s.set('stepWidth', I18n.m.getMessage('ticketLayoutOptionsProgressStepWidthBelowOne'));
                return s;
            }
        }
        return new Map();
    };
    const submitForm = (_e) => {
        if (currentField.label == null || currentField.label.length === 0) {
            const s = new Map();
            s.set('label', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        if (currentField && currentField.customField && currentField.customField.options) {
            setFormError(checkForErrors(currentField));
        }
        setFormError(new Map());
        props.onSave(currentField);
        DialogBetween.instance?.close();
    };
    const icon = TicketLayoutsEntity.getIconName(props.field);
    return (<>
      <DialogTitle iconRight={{ icon, color: ThemeManager.style.brandSuccess, toolTip: '' }}>
        {props.newField
            ? I18n.m.getMessage('ticketLayoutOptionsTileNew')
            : I18n.m.getMessage('ticketLayoutOptionsTileEdit', { title: currentField.label })}
      </DialogTitle>
      <DialogContent>
        <TicketLayoutDefaultOptions approverCanVisible={props.approverCanVisible} assigneeCanEditVisible={props.assigneeCanEditVisible} field={currentField} onChange={(f) => {
            setCurrentField({ ...currentField, ...f });
        }} onError={(err) => {
            setFormError(err);
        }} onSuccess={() => setFormError(new Map())}>
          <FormInputFilled helperText={formError.has('start') ? formError.get('start') : ''} error={formError.has('start')} labelText={I18n.m.getMessage('ticketLayoutOptionsProgressStart')} initValue={currentField.customField && currentField.customField.options && currentField.customField.options.start
            ? currentField.customField.options.start.toString()
            : '0'} filterText={(t) => {
            return t.replace(/[^0-9,.-]/gi, '');
        }} onChange={(v) => {
            const vNumber = Number.parseFloat(v.replace(/,/gi, '.'));
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'progress', options: new CustomFieldProgressOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldProgressOptions();
            customField.options.start = vNumber;
            setCurrentField({ ...currentField, customField });
            setFormError(checkForErrors({ ...currentField, customField }));
        }}/>
          <FormInputFilled helperText={formError.has('end') ? formError.get('end') : ''} error={formError.has('end')} labelText={I18n.m.getMessage('ticketLayoutOptionsProgressEnd')} initValue={currentField.customField && currentField.customField.options && currentField.customField.options.end
            ? currentField.customField.options.end.toString()
            : '10'} filterText={(t) => {
            return t.replace(/[^0-9,.-]/gi, '');
        }} onChange={(v) => {
            const vNumber = Number.parseFloat(v.replace(/,/gi, '.'));
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'progress', options: new CustomFieldProgressOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldProgressOptions();
            customField.options.end = vNumber;
            setCurrentField({ ...currentField, customField });
            setFormError(checkForErrors({ ...currentField, customField }));
        }}/>
          <FormInputFilled helperText={formError.has('defaultValue') ? formError.get('defaultValue') : ''} error={formError.has('defaultValue')} labelText={I18n.m.getMessage('ticketLayoutOptionsDefaultValue')} initValue={currentField.customField && currentField.customField.options && currentField.customField.options.default
            ? currentField.customField.options.default.toString()
            : '0'} filterText={(t) => {
            return t.replace(/[^0-9,.-]/gi, '');
        }} onChange={(v) => {
            const vNumber = Number.parseFloat(v.replace(/,/gi, '.'));
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'progress', options: new CustomFieldProgressOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldProgressOptions();
            customField.options.default = vNumber;
            setCurrentField({ ...currentField, customField });
            setFormError(checkForErrors({ ...currentField, customField }));
        }}/>
          <FormInputFilled helperText={formError.has('stepWidth') ? formError.get('stepWidth') : ''} error={formError.has('stepWidth')} labelText={I18n.m.getMessage('ticketLayoutOptionsProgressStepWidth')} initValue={currentField.customField && currentField.customField.options && currentField.customField.options.stepWidth
            ? currentField.customField.options.stepWidth.toString()
            : '1'} filterText={(t) => {
            return t.replace(/[^0-9,.]/gi, '');
        }} onChange={(v) => {
            const vNumber = Number.parseFloat(v.replace(/,/gi, '.'));
            const customField = currentField.customField
                ? currentField.customField
                : { type: 'progress', options: new CustomFieldProgressOptions() };
            if (customField.options == null)
                customField.options = new CustomFieldProgressOptions();
            customField.options.stepWidth = vNumber;
            setCurrentField({ ...currentField, customField });
            setFormError(checkForErrors({ ...currentField, customField }));
        }}/>
        </TicketLayoutDefaultOptions>
      </DialogContent>
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => DialogBetween.instance?.close()} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton disabled={formError.size > 0 || currentField.label == null || currentField.label.length === 0} onPress={submitForm} title={props.newField ? I18n.m.getMessage('create') : I18n.m.getMessage('save')}/>
      </DialogActions>
    </>);
}
