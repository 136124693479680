import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { FinalizeJournal } from 'upmesh-core/src/client/commands/project/journal/FinalizeJournal';
import { SaveJournal } from 'upmesh-core/src/client/commands/project/journal/SaveJournal';
import { JournalEntity } from 'upmesh-core/src/client/query/entities/JournalEntity';
import { JournalSettingsEntity } from 'upmesh-core/src/client/query/entities/JournalSettingsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { CurrentProject } from '../project/CurrentProject';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { JournalCalendarView } from './JournalCalenderView';
import { JournalDialog } from './JournalDialog';
import { openJournalSendDialog } from './JournalSendDialog';
import { JournalSettingsDialog } from './JournalSettingsDialog';
import { JournalTableView } from './JournalTableView';
import { JournalCardsView } from './JournalCardsView';
import { JournalHeader } from './JournalHeader';
import { numberToDate } from '../tickets/TicketCalendar/calendarUtil';
export class JournalView extends PureComponent {
    constructor(props) {
        super(props);
        this.receivers = [];
        this.company = null;
        this.allJournals = [];
        this.openEditDialog = (date) => {
            Menu.instance?.close();
            this.getJournalDataForDay(date)
                .then((data) => {
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    fullscreenResponsive: true,
                    contentPadding: false,
                    scrollable: false,
                    showCloseButton: false,
                    width: 1024,
                    content: (<JournalDialog journalAvailable={data.journalAvailable} selectedDate={new Date(date)} initialJournalData={data.journalData} company={this.company ? this.company : undefined} createVersionIsLoading={this.state.createVersionIsLoading} previewIsLoading={this.state.previewIsLoading} changePreviewIsLoading={this.changePreviewIsLoading} openSendDialog={this.openSendDialog} coloredDates={this.datesForCalander} journalSettings={this.state.journalSettings}/>),
                });
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.sendCurrentDraft = (date) => {
            Menu.instance?.close();
            const asyncNow = async () => {
                const data = await this.getJournalDataForDay(date);
                const { journalData, journalProtocol } = data;
                if (journalData != null) {
                    if (journalProtocol != null && journalProtocol.length > 0) {
                        const journal = journalProtocol[0];
                        if (journal != null && journal.createdAt.getTime() >= journalData.lastModifiedAt.getTime()) {
                            await this.openSendDialog(journal.id, journalData.forDay);
                            return;
                        }
                    }
                    this.onCreateVersion(journalData);
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.changePreviewIsLoading = (value, callback) => {
            this.setState({ previewIsLoading: value }, () => {
                if (callback != null) {
                    callback();
                }
            });
        };
        this.createVersionNow = (journalData) => {
            const create = new FinalizeJournal({ journalId: journalData.id });
            create
                .execute()
                .then(async () => {
                Dialog.instance?.close(() => {
                    this.openSendDialog(create.entityId);
                });
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.datesForCalander = async (startDate, endDate) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const draftJournals = await UpmeshClient.instance.modals.journal.get({
                filter: `projectId eq '${projectId}' and forDay ge ${Number.parseInt(JournalEntity.getNormalizedDateShort(startDate), 10)} and forDay le ${Number.parseInt(JournalEntity.getNormalizedDateShort(endDate), 10)}`,
            }, CurrentUser.userId);
            const coloredDates = [];
            const protocols = await UpmeshClient.instance.modals.journalProtocol.get({ filter: `projectId eq '${projectId}'` });
            for (const draft of draftJournals) {
                const isSendOnce = protocols.find((element) => {
                    return element.journalId === draft.id;
                });
                coloredDates.push({
                    date: JournalEntity.getNormalizedDateShortToDate(draft.forDay.toString()),
                    color: isSendOnce ? ThemeManager.style.brandSecondary : ThemeManager.style.brandWarning,
                });
            }
            return coloredDates;
        };
        this.getAllReceivers = async () => {
            const allReceivers = [];
            const team = CurrentProject.instance.getCurrentProjectTeam();
            for (let i = 0; i < team.length; i += 1) {
                const user = await AuthClient.instance.modals.user.getById(team[i].user.userId);
                allReceivers.push({
                    title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                    id: team[i].user.id,
                    thumbnail: <UserImage size={24} user={team[i].user}/>,
                    secondTextLine: user.emails != null ? user.emails.join(', ') : '',
                });
            }
            return allReceivers;
        };
        this.getJournalDataForDay = async (date) => {
            const { projectId } = this.props;
            let selectedJournal;
            let journalProtocol = [];
            let journalAvailable = false;
            const userOrCompanyId = this.company != null ? this.company.id : CurrentUser.userId;
            selectedJournal = new JournalEntity({
                owner: {
                    id: this.company != null ? this.company.id : CurrentUser.userId,
                    type: this.company != null ? 'company' : 'user',
                },
                projectId,
                forDay: Number.parseInt(JournalEntity.getNormalizedDateShort(date), 10),
            });
            const currentJournals = await UpmeshClient.instance.modals.journal.get({
                filter: `owner/id eq '${userOrCompanyId}' and projectId eq '${projectId}' and forDay eq ${Number.parseInt(JournalEntity.getNormalizedDateShort(date), 10)}`,
            }, CurrentUser.userId);
            if (currentJournals != null && currentJournals.length > 0) {
                selectedJournal = new JournalEntity(currentJournals[0]);
                journalProtocol = await UpmeshClient.instance.modals.journalProtocol.get({
                    filter: `journalId eq '${selectedJournal.id}'`,
                    orderby: 'createdAt DESC',
                });
                journalAvailable = true;
            }
            let canI = false;
            const s = new SaveJournal({ ...selectedJournal, forDay: selectedJournal.forDay.toString() });
            try {
                await s.canI();
                canI = true;
            }
            catch (e) {
                console.debug('no rights');
            }
            return {
                journalData: selectedJournal,
                journalAvailable,
                journalProtocol,
                canI,
            };
        };
        this.onCreateVersion = (journalData) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('journalCreateVersionQuestion'),
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="cancel" title={I18n.m.getMessage('cancel')} onPress={() => Alert.instance?.close()}/>,
                    <ContainedButton key="importNow" title={I18n.m.getMessage('journalCreateVersion')} onPress={() => {
                            Alert.instance?.close(() => {
                                this.createVersionNow(journalData);
                            });
                        }}/>,
                ],
            });
        };
        this.onGetAsPDF = (date) => {
            Menu.instance?.close();
            if (!UpmeshClient.instance.serverConnected()) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                });
                return;
            }
            const asyncNow = async () => {
                const { journalData } = await this.getJournalDataForDay(date);
                this.setState({ previewIsLoading: true }, () => {
                    const asyncNow = async () => {
                        try {
                            const link = await CurrentProject.instance.createJournalPreview(journalData);
                            if (link != null) {
                                const orgFilename = `${I18n.m.getMessage('preview')}_${journalData.forDay}.pdf`;
                                StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                                    link,
                                    orgFilename,
                                }))
                                    .catch((e) => {
                                    console.error('cant download', link, e);
                                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                                    throw e;
                                })
                                    .finally(() => {
                                    this.setState({ previewIsLoading: false });
                                });
                            }
                            else {
                                this.setState({ previewIsLoading: false });
                            }
                        }
                        catch (e) {
                            console.debug('JournalDialog Error', e);
                            let message = '';
                            if (e != null && (e.message != null || e.errorMessage != null)) {
                                message = e.message != null ? e.message : e.errorMessage;
                            }
                            if (message != null && message.length > 0) {
                                console.debug('error', message);
                                Routing.instance.alert.post({
                                    text: I18n.m.getMessage(message),
                                });
                            }
                            else {
                                Routing.instance.alert.post({
                                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                                });
                            }
                            this.setState({
                                previewIsLoading: false,
                            });
                        }
                    };
                    asyncNow().catch((err) => console.error(err));
                });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.onPressJournal = (journal, _e) => {
            if (journal != null) {
                const date = numberToDate(journal.forDay);
                this.setState({ selectedDate: date });
                Routing.instance.routeBlock.post(false);
                requestAnimationFrame(() => {
                    Routing.instance.goTo(`/projects/${this.props.projectId}/dailyreports/${JournalEntity.getNormalizedDateShort(date)}`);
                });
            }
        };
        this.openSendDialog = (pId, dateNumber) => {
            const asyncNow = async () => {
                const allReceivers = await this.getAllReceivers();
                try {
                    let jData;
                    if (dateNumber != null) {
                        const date = numberToDate(dateNumber);
                        const data = await this.getJournalDataForDay(date);
                        jData = data.journalData;
                    }
                    else {
                        let d;
                        await WaitFor.instance.waitFor(() => {
                            UpmeshClient.instance.modals.journalProtocol
                                .getById(pId)
                                .then((e) => {
                                d = e;
                            })
                                .catch((err) => console.debug(err));
                            return d != null;
                        }, 1000, 3000);
                        const protocol = await UpmeshClient.instance.modals.journalProtocol.getById(pId);
                        jData = await UpmeshClient.instance.modals.journal.getById(protocol.journalId);
                    }
                    const receiverChips = [];
                    this.receivers = [];
                    const lastReceiver = SimpleStorage.get(`lastJournalReceiver_${jData.projectId}`);
                    if (lastReceiver != null) {
                        const receivers = JSON.parse(lastReceiver);
                        const team = CurrentProject.instance.getCurrentProjectTeam();
                        for (let i = 0; i < receivers.length; i += 1) {
                            this.receivers.push(receivers[i]);
                            let added = false;
                            let j = 0;
                            while (j < team.length && !added) {
                                const { user } = team[j];
                                if (user != null && user.emails != null && user.emails.findIndex((mail) => mail === receivers[i]) > -1) {
                                    receiverChips.push({
                                        title: `${user.firstname} ${user.lastname}`,
                                        id: user.id,
                                        thumbnail: <UserImage size={24} user={user}/>,
                                        secondTextLine: user.emails.join(`, `),
                                    });
                                    added = true;
                                }
                                j += 1;
                            }
                            if (!added) {
                                receiverChips.push({ title: `${receivers[i]}`, id: receivers[i] });
                            }
                        }
                    }
                    openJournalSendDialog({
                        allReceivers,
                        receiverChips,
                        protocolId: pId,
                        projectId: jData.projectId,
                        journalDataId: jData.id,
                    })();
                }
                catch (err) {
                    console.debug('cant get last receiver');
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.openSettings = (e) => {
            if (!UpmeshClient.instance.serverConnected()) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                });
                return;
            }
            const { projectId } = this.props;
            let openPosition;
            if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
                openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
            }
            Dialog.instance?.open({
                content: <JournalSettingsDialog projectId={projectId} onSaved={this.updateJournalSettings}/>,
                contentPadding: false,
                fullscreenResponsive: true,
                closeOnTouchOutside: false,
                openPosition,
                scrollable: false,
            });
        };
        this.updateJournalSettings = () => {
            const { projectId } = this.props;
            const ownerId = CompanyUserInfo.company != null ? CompanyUserInfo.company.id : CurrentUser.userId;
            UpmeshClient.instance.modals.journalSettings
                .get({
                filter: `projectId eq '${projectId}' and owner/id eq '${ownerId}'`,
            })
                .then((js) => {
                const filename = `${I18n.m.getMessage('journal')}_{project}_{forDay}`;
                let journalSettings;
                if (js.length > 0)
                    journalSettings = new JournalSettingsEntity(js[0]);
                else
                    journalSettings = new JournalSettingsEntity({ filename });
                this.setState({ journalSettings });
            })
                .catch((err) => console.debug('cant load settings', err));
        };
        this.init = async () => {
            const { projectId } = this.props;
            const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                filter: `userId eq '${CurrentUser.userId}' and deleted ne true and role ne 'inactive'`,
            });
            if (companyMember != null && companyMember.length > 0) {
                this.company = await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
            }
            await this.updateJournalDataToStateDate();
            let canI;
            const journalData = new JournalEntity({
                projectId,
                forDay: Number.parseInt(JournalEntity.getNormalizedDateShort(new Date()), 10),
            });
            try {
                const saveJournal = new SaveJournal({
                    ...journalData,
                    forDay: journalData.forDay.toString(),
                }, journalData.id);
                canI = (await saveJournal.canI()) === true;
            }
            catch (e) {
                canI = false;
            }
            const journalSelectedView = SimpleStorage.get('journalSelectedView');
            const js = await UpmeshClient.instance.modals.journal.get({
                filter: `projectId eq '${projectId}' and forDay ge ${Number.parseInt(JournalEntity.getNormalizedDateShort(new Date(0)), 10)} and forDay le ${Number.parseInt(JournalEntity.getNormalizedDateShort(new Date()), 10)}`,
            }, CurrentUser.userId);
            const journals = [];
            for (let i = 0; i < js.length; i += 1) {
                const jv = js[i];
                const versions = await UpmeshClient.instance.modals.journalProtocol.get({
                    filter: `projectId eq '${projectId}' and journalId eq '${jv.id}'`,
                });
                jv.versions = versions.length;
                journals.push(jv);
            }
            this.allJournals = journals;
            const ownerId = CompanyUserInfo.company != null ? CompanyUserInfo.company.id : CurrentUser.userId;
            UpmeshClient.instance.modals.journalSettings
                .get({
                filter: `projectId eq '${projectId}' and owner/id eq '${ownerId}'`,
            })
                .then((js) => {
                const filename = `${I18n.m.getMessage('journal')}_{project}_{forDay}`;
                let journalSettings;
                if (js.length > 0)
                    journalSettings = new JournalSettingsEntity(js[0]);
                else
                    journalSettings = new JournalSettingsEntity({ filename });
                this.setState({
                    init: true,
                    canI,
                    journalSettings,
                    selectedView: journalSelectedView != null ? journalSelectedView : 'list',
                    visibleJournals: journals,
                });
            })
                .catch((err) => console.debug('cant load settings', err));
        };
        this.updateAllRemotes = async () => {
            await this.updateJournalDataToStateDate();
        };
        this.updateJournalDataToStateDate = async () => {
            const { selectedDate } = this.state;
            if (selectedDate != null) {
                const data = await this.getJournalDataForDay(selectedDate);
                if (data != null) {
                    await new Promise((resolve) => {
                        this.setState({
                            journalData: data.journalData,
                            journalProtocol: data.journalProtocol,
                            journalAvailable: data.journalAvailable,
                            canI: data.canI,
                        }, resolve);
                    });
                }
            }
        };
        this.attachKey = uuid.v4();
        this.attachKeyProtocol = uuid.v4();
        this.attachKeySettings = uuid.v4();
        this.state = {
            selectedDate: props.pathvars.planId == null ? undefined : JournalEntity.getNormalizedDateShortToDate(props.pathvars.planId),
            journalData: new JournalEntity({ projectId: CurrentProject.instance.getCurrentProjectId() }),
            journalProtocol: [],
            init: false,
            journalAvailable: false,
            canI: false,
            previewIsLoading: false,
            sendIsLoading: false,
            createVersionIsLoading: false,
            selectedView: 'list',
            visibleJournals: [],
            searching: false,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.pathvars.planId == null) {
            return { selectedDate: undefined };
        }
        const selectedDate = JournalEntity.getNormalizedDateShortToDate(nextProps.pathvars.planId);
        if (selectedDate !== prevState.selectedDate) {
            return { selectedDate };
        }
        return null;
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init', err));
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'Journal',
                attachKey: this.attachKey,
                callback: (en) => {
                    const { projectId } = this.props;
                    let shouldUpdate = false;
                    en.entities.forEach((e) => {
                        if (e.entity.projectId === projectId) {
                            shouldUpdate = true;
                        }
                    });
                    if (shouldUpdate)
                        this.init().catch((err) => console.debug('cant init', err));
                    this.updateAllRemotes().catch((err) => console.error(err));
                },
            });
            if (this.attachKeyProtocol != null) {
                UpmeshClient.eventDispatcher.attach({
                    readModelName: 'JournalProtocol',
                    attachKey: this.attachKeyProtocol,
                    callback: (en) => {
                        const { projectId } = this.props;
                        let shouldUpdate = false;
                        en.entities.forEach((e) => {
                            if (e.entity.projectId === projectId) {
                                shouldUpdate = true;
                            }
                        });
                        if (shouldUpdate)
                            this.init().catch((err) => console.debug('cant init', err));
                        this.updateAllRemotes().catch((err) => console.error(err));
                    },
                });
            }
        }
        if (this.attachKeySettings != null) {
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'JournalSettings',
                attachKey: this.attachKeySettings,
                callback: this.updateJournalSettings,
            });
        }
    }
    componentDidUpdate(_prevProps, prevState) {
        if (prevState.selectedDate?.toISOString() !== this.state.selectedDate?.toISOString()) {
            this.updateJournalDataToStateDate().catch((err) => console.debug('updateJournalDataToStateDate Error', err));
        }
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Journal', this.attachKey);
        UpmeshClient.eventDispatcher.detach('JournalProtocol', this.attachKeyProtocol);
        UpmeshClient.eventDispatcher.detach('JournalSettings', this.attachKeySettings);
    }
    render() {
        const { projectId } = this.props;
        const { selectedDate, selectedView, canI, init } = this.state;
        if (!init)
            return <Spinner />;
        return (<JournalHeader canI={canI} allJournals={this.allJournals} selectedDate={selectedDate} selectedView={selectedView != null ? selectedView : 'list'} projectId={projectId} onChangeJournalSettings={(s) => this.setState({ journalSettings: s })} onChangeView={(v) => this.setState({ selectedView: v })} onChangeVisibleJournals={(j, s) => this.setState({ visibleJournals: j, searching: s })}>
        {this.renderView()}
      </JournalHeader>);
    }
    renderView() {
        const { projectId, size, q } = this.props;
        const { selectedDate, previewIsLoading, sendIsLoading, canI, init, journalAvailable, journalData, journalProtocol, selectedView, visibleJournals, journalSettings, searching, } = this.state;
        if (!init) {
            return <Spinner />;
        }
        if (selectedDate != null) {
            return (<JournalCalendarView canI={canI} openEditDialog={this.openEditDialog} openSendDialog={this.openSendDialog} previewIsLoading={previewIsLoading} sendIsLoading={sendIsLoading} size={size} projectId={projectId} onGetAsPDF={this.onGetAsPDF} selectedDate={selectedDate} init={init} journalAvailable={journalAvailable} journalData={journalData} journalProtocol={journalProtocol} sendCurrentDraft={this.sendCurrentDraft} journalSettings={this.state.journalSettings}/>);
        }
        if (selectedView === 'table') {
            return (<JournalTableView q={q} projectId={projectId} coloredDates={this.datesForCalander} size={size} onPressJournal={this.onPressJournal} openEditDialog={this.openEditDialog} openSendDialog={this.openSendDialog} previewOrSendIsLoading={previewIsLoading || sendIsLoading} getAsPdf={this.onGetAsPDF} sendCurrentDraft={this.sendCurrentDraft} canI={canI} openSettings={this.openSettings} journalSettings={journalSettings} visibleJournals={visibleJournals}/>);
        }
        return (<JournalCardsView onPressJournal={this.onPressJournal} openEditDialog={this.openEditDialog} visibleJournals={visibleJournals} searched={searching} canI={canI}/>);
    }
}
