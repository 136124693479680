import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { JournalSettingsEntity } from 'upmesh-core/src/client/query/entities/JournalSettingsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { JournalFilter } from 'upmesh-core/src/client/query/filter/JournalFilter';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { PageView } from '../root/PageView';
import { JournalSettingsDialog } from './JournalSettingsDialog';
import JournalDownloadDialog from './JournalDownloadDialog';
export function JournalHeader(props) {
    const [searchWords, setSearchWords] = useState('');
    let searchTimer;
    let searching = false;
    const changeView = (_index, button) => {
        SimpleStorage.set('journalSelectedView', button.key);
        props.onChangeView(button.key);
    };
    const openDownloadDialog = () => {
        Dialog.instance?.open({ content: <JournalDownloadDialog />, contentPadding: false });
    };
    const updateVisibleJournals = async (searchWords) => {
        const { onChangeVisibleJournals, allJournals } = props;
        let visibleJournals = [...allJournals];
        if (searchWords != null && searchWords.length > 0) {
            visibleJournals = await JournalFilter.filterJournalsByText(searchWords, visibleJournals);
        }
        onChangeVisibleJournals([...visibleJournals], searchWords.length > 0);
    };
    const searchNow = (text) => () => {
        if (searching) {
            searchTimer = window.setTimeout(searchNow(text), 100);
            return;
        }
        searching = true;
        searching = false;
        Routing.instance.changeQueryParameter({ q: text });
        updateVisibleJournals(text).catch((err) => console.debug('cant load settings', err));
    };
    const onSearch = (text) => {
        setSearchWords(text);
        if (searchTimer != null)
            clearTimeout(searchTimer);
        searchTimer = window.setTimeout(searchNow(text), 250);
    };
    const updateJournalSettings = () => {
        const { projectId, onChangeJournalSettings } = props;
        const ownerId = CompanyUserInfo.company != null ? CompanyUserInfo.company.id : CurrentUser.userId;
        UpmeshClient.instance.modals.journalSettings
            .get({
            filter: `projectId eq '${projectId}' and owner/id eq '${ownerId}'`,
        })
            .then((js) => {
            const filename = `${I18n.m.getMessage('journal')}_{project}_{forDay}`;
            let journalSettings;
            if (js.length > 0)
                journalSettings = new JournalSettingsEntity(js[0]);
            else
                journalSettings = new JournalSettingsEntity({ filename });
            if (journalSettings != null)
                onChangeJournalSettings(journalSettings);
        })
            .catch((err) => console.debug('cant load settings', err));
    };
    const openSettings = (e) => {
        if (!UpmeshClient.instance.serverConnected()) {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('commandOfflineNotPossible'),
            });
            return;
        }
        const { projectId } = props;
        let openPosition;
        if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
            openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        }
        Dialog.instance?.open({
            content: <JournalSettingsDialog projectId={projectId} onSaved={updateJournalSettings}/>,
            contentPadding: false,
            fullscreenResponsive: true,
            closeOnTouchOutside: false,
            openPosition,
            scrollable: false,
        });
    };
    const renderViewSelection = () => {
        const { selectedDate, selectedView } = props;
        if (selectedDate == null)
            return (<View key="segmentedButton" style={{ width: 144, marginLeft: 12 }}>
          <SegmentedButton buttons={[
                    { icon: { icon: 'calendar' }, key: 'list' },
                    { icon: { icon: 'table-large' }, key: 'table' },
                ]} onPress={changeView} singleSelectSelected={selectedView === 'list' ? 0 : 1} density={2}/>
        </View>);
        return <View />;
    };
    return (<PageView scrollable={props.selectedView === 'list' && props.selectedDate == null} style={{ height: '100%' }} headerProps={{
            title: props.selectedDate == null
                ? ''
                : `${I18n.m.getMessage('journal')} ${I18n.m.dateCurrent.localeDateString(props.selectedDate)}`,
            leftButtons: props.selectedDate == null
                ? []
                : [
                    <Icon key="backButton" toolTip={I18n.m.getMessage('back')} icon="arrow-left" onPress={Routing.instance.goToButton(`/projects/${props.projectId}/dailyreports`)}/>,
                ],
            rightButtons: [
                <Icon key="journalSettingsIcon" toolTip={I18n.m.getMessage('journalSettings')} icon="cog-outline" onPress={openSettings}/>,
                <Icon key="journalDownloadIcon" toolTip={I18n.m.getMessage('journalMultiDownload')} icon="download-outline" onPress={openDownloadDialog}/>,
                renderViewSelection(),
            ],
            searchBarProps: props.selectedDate == null
                ? {
                    accessibilityLabel: I18n.m.getMessage('journalSearchJournals'),
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('journalSearchJournals'),
                    searchOnChange: onSearch,
                    tooltip: I18n.m.getMessage('searchPlans'),
                }
                : {},
        }}>
      {props.children}
    </PageView>);
}
