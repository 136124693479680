"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomFieldProgressOptions = exports.CustomFieldDividerOptions = exports.CustomFieldCheckboxListOptions = exports.CustomFieldMultiselectListOptions = exports.CustomFieldListOptions = exports.CustomFieldDateRangeOptions = exports.CustomFieldDateOptions = exports.CustomFieldStringOptions = exports.CustomFieldMultiPesronOptions = exports.CustomFieldPersonOptions = exports.CustomFieldNumberOptions = exports.CustomFieldOptions = exports.LayoutFields = exports.keyBySystemField = exports.CustomFieldValues = void 0;
var AuthClient_1 = require("upmesh-auth-core/build/src/client/AuthClient");
var CustomFieldValues = (0, _createClass2.default)(function CustomFieldValues() {
  (0, _classCallCheck2.default)(this, CustomFieldValues);
});
exports.CustomFieldValues = CustomFieldValues;
exports.keyBySystemField = {
  assignee: 'assignedToUserId',
  approver: 'approverUserId',
  craft: 'craft',
  tags: 'tags',
  completionOn: 'completionOn',
  plan: 'planId',
  description: 'description',
  status: 'ticketStatus'
};
var LayoutFields = function () {
  function LayoutFields() {
    (0, _classCallCheck2.default)(this, LayoutFields);
  }
  return (0, _createClass2.default)(LayoutFields, null, [{
    key: "getIdentifier",
    value: function getIdentifier(c) {
      if (c.systemField) return c.systemField;
      if (c.identifier != null && c.identifier.length > 0) return c.identifier.replace(/[^A-Za-z0-9_]/g, '');
      return c.label.replace(/[^A-Za-z0-9_]/g, '');
    }
  }]);
}();
exports.LayoutFields = LayoutFields;
LayoutFields.filterIdentifier = function (t) {
  return t.replace(/[^A-Za-z0-9_]/g, '');
};
var CustomFieldOptions = (0, _createClass2.default)(function CustomFieldOptions() {
  (0, _classCallCheck2.default)(this, CustomFieldOptions);
});
exports.CustomFieldOptions = CustomFieldOptions;
var CustomFieldNumberOptions = function (_CustomFieldOptions) {
  function CustomFieldNumberOptions(values) {
    var _this;
    (0, _classCallCheck2.default)(this, CustomFieldNumberOptions);
    _this = _callSuper(this, CustomFieldNumberOptions);
    var v = values != null ? values : {
      dezimals: 0,
      delimiter: 0
    };
    if (!v.dezimals) v.dezimals = 0;
    if (!v.delimiter) v.delimiter = 0;
    if (v != null) {
      for (var k in v) {
        _this[k] = v[k];
      }
    }
    return _this;
  }
  (0, _inherits2.default)(CustomFieldNumberOptions, _CustomFieldOptions);
  return (0, _createClass2.default)(CustomFieldNumberOptions, [{
    key: "formatValue",
    value: function formatValue(value) {
      var v = value == null ? 0 : value;
      return `${v.toLocaleString(this.delimiter === 0 ? 'de-DE' : 'en-US', {
        maximumFractionDigits: this.dezimals,
        minimumFractionDigits: this.dezimals
      })}${this.unit ? ` ${this.unit}` : ''}`;
    }
  }, {
    key: "validate",
    value: function validate(value) {
      if (this.max && value > this.max) {
        throw {
          message: `Value ist zu groß, maximal "${this.max}" zugelassen`,
          messageCode: 'badRequest'
        };
      } else if (this.min && value < this.min) {
        throw {
          message: `Value ist zu klein, minimum "${this.min}" zugelassen`,
          messageCode: 'badRequest'
        };
      }
    }
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'number',
        value: value,
        formattedValue: this.formatValue(value)
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldNumberOptions = CustomFieldNumberOptions;
var CustomFieldPersonOptions = function (_CustomFieldOptions2) {
  function CustomFieldPersonOptions(v) {
    var _this2;
    (0, _classCallCheck2.default)(this, CustomFieldPersonOptions);
    _this2 = _callSuper(this, CustomFieldPersonOptions);
    if (v != null) {
      for (var k in v) {
        _this2[k] = v[k];
      }
    }
    return _this2;
  }
  (0, _inherits2.default)(CustomFieldPersonOptions, _CustomFieldOptions2);
  return (0, _createClass2.default)(CustomFieldPersonOptions, [{
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getFormattedValue",
    value: function () {
      var _getFormattedValue = (0, _asyncToGenerator2.default)(function* (value) {
        if (!value || value === '0') return '';
        try {
          var user = yield AuthClient_1.AuthClient.instance.modals.user.getById(value);
          return user.getFullName();
        } catch (err) {
          return '';
        }
      });
      function getFormattedValue(_x) {
        return _getFormattedValue.apply(this, arguments);
      }
      return getFormattedValue;
    }()
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'person',
        value: value,
        formattedValue: value
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldPersonOptions = CustomFieldPersonOptions;
var CustomFieldMultiPesronOptions = function (_CustomFieldOptions3) {
  function CustomFieldMultiPesronOptions(v) {
    var _this3;
    (0, _classCallCheck2.default)(this, CustomFieldMultiPesronOptions);
    _this3 = _callSuper(this, CustomFieldMultiPesronOptions);
    if (v != null) {
      for (var k in v) {
        _this3[k] = v[k];
      }
    }
    return _this3;
  }
  (0, _inherits2.default)(CustomFieldMultiPesronOptions, _CustomFieldOptions3);
  return (0, _createClass2.default)(CustomFieldMultiPesronOptions, [{
    key: "getFormattedValue",
    value: function () {
      var _getFormattedValue2 = (0, _asyncToGenerator2.default)(function* (v) {
        if (!v || v.length === 0) return '';
        try {
          var persons = [];
          for (var id of v) {
            try {
              var user = yield AuthClient_1.AuthClient.instance.modals.user.getById(id);
              persons.push(user.getFullName());
            } catch (err) {}
          }
          return persons.join(', ');
        } catch (err) {
          return '';
        }
      });
      function getFormattedValue(_x2) {
        return _getFormattedValue2.apply(this, arguments);
      }
      return getFormattedValue;
    }()
  }, {
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'multiperson',
        value: value,
        formattedValue: value.join(', ')
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldMultiPesronOptions = CustomFieldMultiPesronOptions;
var CustomFieldStringOptions = function (_CustomFieldOptions4) {
  function CustomFieldStringOptions(v) {
    var _this4;
    (0, _classCallCheck2.default)(this, CustomFieldStringOptions);
    _this4 = _callSuper(this, CustomFieldStringOptions);
    if (v != null) {
      for (var k in v) {
        _this4[k] = v[k];
      }
    }
    return _this4;
  }
  (0, _inherits2.default)(CustomFieldStringOptions, _CustomFieldOptions4);
  return (0, _createClass2.default)(CustomFieldStringOptions, [{
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'string',
        value: value,
        formattedValue: value
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldStringOptions = CustomFieldStringOptions;
var CustomFieldDateOptions = function (_CustomFieldOptions5) {
  function CustomFieldDateOptions(v) {
    var _this5;
    (0, _classCallCheck2.default)(this, CustomFieldDateOptions);
    _this5 = _callSuper(this, CustomFieldDateOptions);
    if (v != null) {
      for (var k in v) {
        _this5[k] = v[k];
      }
    }
    return _this5;
  }
  (0, _inherits2.default)(CustomFieldDateOptions, _CustomFieldOptions5);
  return (0, _createClass2.default)(CustomFieldDateOptions, [{
    key: "validate",
    value: function validate(value) {
      if (value != null) {
        if (value['date']) {
          var date = new Date(value['date']);
          if (date.getTime() === 0) {
            throw {
              message: `Ungültiges Datum Format`,
              messageCode: 'badRequest'
            };
          }
        }
      }
    }
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      var v;
      if (value != null) {
        if (value['date']) {
          var date = new Date(value['date']);
          v = date.toISOString();
        }
      }
      return {
        id: field.id,
        name: field.label,
        type: 'Date',
        value: value,
        formattedValue: v
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldDateOptions = CustomFieldDateOptions;
var CustomFieldDateRangeOptions = function (_CustomFieldOptions6) {
  function CustomFieldDateRangeOptions(v) {
    var _this6;
    (0, _classCallCheck2.default)(this, CustomFieldDateRangeOptions);
    _this6 = _callSuper(this, CustomFieldDateRangeOptions);
    if (v != null) {
      for (var k in v) {
        _this6[k] = v[k];
      }
    }
    return _this6;
  }
  (0, _inherits2.default)(CustomFieldDateRangeOptions, _CustomFieldOptions6);
  return (0, _createClass2.default)(CustomFieldDateRangeOptions, [{
    key: "validate",
    value: function validate(value) {
      if (value != null) {
        if (value['from']) {
          var from = new Date(value['from']);
          var to = new Date(value['to']);
          if (from.getTime() === 0) {
            throw {
              message: `Ungültiges Datum Format`,
              messageCode: 'badRequest'
            };
          } else if (to.getTime() === 0) {
            throw {
              message: `Ungültiges Datum Format`,
              messageCode: 'badRequest'
            };
          } else if (from.getTime() > to.getTime()) {
            throw {
              message: `From Date sollte kleiner sein als to Date`,
              messageCode: 'badRequest'
            };
          }
        }
      }
    }
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      var v;
      if (value != null) {
        if (value['from']) {
          var from = new Date(value['from']);
          var to = new Date(value['to']);
          v = `${from.toISOString()} - ${to.toISOString()}`;
        }
      }
      return {
        id: field.id,
        name: field.label,
        type: 'DateRange',
        value: value,
        formattedValue: v
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldDateRangeOptions = CustomFieldDateRangeOptions;
var CustomFieldListOptions = function (_CustomFieldOptions7) {
  function CustomFieldListOptions(v) {
    var _this7;
    (0, _classCallCheck2.default)(this, CustomFieldListOptions);
    _this7 = _callSuper(this, CustomFieldListOptions);
    if (v != null) {
      for (var k in v) {
        _this7[k] = v[k];
      }
    }
    _this7.children = [];
    return _this7;
  }
  (0, _inherits2.default)(CustomFieldListOptions, _CustomFieldOptions7);
  return (0, _createClass2.default)(CustomFieldListOptions, [{
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'List',
        value: value,
        formattedValue: value
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldListOptions = CustomFieldListOptions;
var CustomFieldMultiselectListOptions = function (_CustomFieldOptions8) {
  function CustomFieldMultiselectListOptions(v) {
    var _this8;
    (0, _classCallCheck2.default)(this, CustomFieldMultiselectListOptions);
    _this8 = _callSuper(this, CustomFieldMultiselectListOptions);
    if (v != null) {
      for (var k in v) {
        _this8[k] = v[k];
      }
    }
    _this8.children = [];
    return _this8;
  }
  (0, _inherits2.default)(CustomFieldMultiselectListOptions, _CustomFieldOptions8);
  return (0, _createClass2.default)(CustomFieldMultiselectListOptions, [{
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'MultiselectList',
        value: value,
        formattedValue: value.join(', ')
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldMultiselectListOptions = CustomFieldMultiselectListOptions;
var CustomFieldCheckboxListOptions = function (_CustomFieldOptions9) {
  function CustomFieldCheckboxListOptions(v) {
    var _this9;
    (0, _classCallCheck2.default)(this, CustomFieldCheckboxListOptions);
    _this9 = _callSuper(this, CustomFieldCheckboxListOptions);
    if (v != null) {
      for (var k in v) {
        _this9[k] = v[k];
      }
    }
    return _this9;
  }
  (0, _inherits2.default)(CustomFieldCheckboxListOptions, _CustomFieldOptions9);
  return (0, _createClass2.default)(CustomFieldCheckboxListOptions, [{
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'CheckboxList',
        value: value,
        formattedValue: value.join(', ')
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldCheckboxListOptions = CustomFieldCheckboxListOptions;
var CustomFieldDividerOptions = function (_CustomFieldOptions10) {
  function CustomFieldDividerOptions(v) {
    var _this10;
    (0, _classCallCheck2.default)(this, CustomFieldDividerOptions);
    _this10 = _callSuper(this, CustomFieldDividerOptions);
    if (v != null) {
      for (var k in v) {
        _this10[k] = v[k];
      }
    }
    return _this10;
  }
  (0, _inherits2.default)(CustomFieldDividerOptions, _CustomFieldOptions10);
  return (0, _createClass2.default)(CustomFieldDividerOptions, [{
    key: "validate",
    value: function validate(_value) {}
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'divider',
        value: value,
        formattedValue: value
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldDividerOptions = CustomFieldDividerOptions;
var CustomFieldProgressOptions = function (_CustomFieldOptions11) {
  function CustomFieldProgressOptions(v) {
    var _this11;
    (0, _classCallCheck2.default)(this, CustomFieldProgressOptions);
    _this11 = _callSuper(this, CustomFieldProgressOptions);
    if (v != null) {
      for (var k in v) {
        _this11[k] = v[k];
      }
    }
    return _this11;
  }
  (0, _inherits2.default)(CustomFieldProgressOptions, _CustomFieldOptions11);
  return (0, _createClass2.default)(CustomFieldProgressOptions, [{
    key: "validate",
    value: function validate(value) {
      if (this.end && value > this.end) {
        throw {
          message: `Value ist zu groß, maximal "${this.end}" zugelassen`,
          messageCode: 'badRequest'
        };
      } else if (this.start && value < this.start) {
        throw {
          message: `Value ist zu klein, minimum "${this.start}" zugelassen`,
          messageCode: 'badRequest'
        };
      }
    }
  }, {
    key: "getDefaulValues",
    value: function getDefaulValues(field, value) {
      return {
        id: field.id,
        name: field.label,
        type: 'progress',
        value: value,
        formattedValue: value
      };
    }
  }]);
}(CustomFieldOptions);
exports.CustomFieldProgressOptions = CustomFieldProgressOptions;