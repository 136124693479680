import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { Image, StatusBar, View } from 'react-native';
import { LogoHandler } from './GlobalLogo';
import { HomeViewContent } from './HomeViewContent';
const HGUSER = require('../../assets/img/hg_start1.46.jpg');
export class HomeView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        try {
            const { size } = this.props;
            if (size.contentTop > 0 && StatusBar != null && typeof StatusBar.setBarStyle === 'function')
                StatusBar.setBarStyle('light-content', true);
        }
        catch (e) {
            console.debug(e);
        }
    }
    componentWillUnmount() {
        try {
            const { size } = this.props;
            if (size.contentTop > 0 && StatusBar != null && typeof StatusBar.setBarStyle === 'function')
                StatusBar.setBarStyle('dark-content', true);
        }
        catch (e) {
            console.debug(e);
        }
    }
    render() {
        return (<View key="homeViewWrapper" style={{
                overflow: 'visible',
                position: 'relative',
                backgroundColor: 'transparent',
                height: ResizeEvent.current.windowHeight,
                width: '100%',
            }}>
        <Image key="bgImage" resizeMethod="resize" source={LogoHandler.getLogo(true) || HGUSER} style={{
                height: ResizeEvent.current.windowHeight,
                width: ResizeEvent.current.windowWidth,
            }}/>
        <HomeViewContent {...this.props}/>
      </View>);
    }
}
HomeView.defaultProps = {
    title: 'upmesh',
};
