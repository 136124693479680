import { Calendar } from 'materialTheme/src/theme/components/Calendar';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { GanttChartDataEntry } from './GanttChartDataEntry';
export const GanttChartItem = React.memo((props) => {
    const monthNames = I18n.m.dateCurrent.monthsNamesShort();
    const scrollContainer = useRef();
    const [chartWidth, setChartWidth] = useState(ResizeEvent.current.contentWidth);
    const [chartHeight, setChartHeight] = useState(ResizeEvent.current.contentHeight);
    const [todayPosition, setTodayPosition] = useState(0);
    const [firstDay, setFirstDay] = useState(new Date());
    const [lastDay, setLastDay] = useState(new Date());
    const [initialScrollNeeded, setInitialScrollNeeded] = useState(true);
    const scrollPosition = useRef({ x: 0, y: 0 });
    const moveS = Platform.OS === 'web' ? { cursor: 'move' } : {};
    const getWidthPerDay = () => {
        const { timeScale } = props;
        if (timeScale === 'month/day')
            return 25;
        if (timeScale === 'year/month')
            return 2;
        return 0.3;
    };
    const getFirstAndLastShownDay = (d, v) => {
        const data = d != null ? d : props.data;
        const visibleDaysBeforeAndAfter = v != null ? v : props.visibleDaysBeforeAndAfter;
        let firstDay = new Date();
        let lastDay = new Date();
        if (data.length > 0) {
            firstDay = new Date(data[0].start);
            lastDay = new Date(data[0].end);
            for (const d of data) {
                if (d.start < firstDay)
                    firstDay = new Date(d.start);
                if (d.end > lastDay)
                    lastDay = new Date(d.end);
            }
        }
        const visibleDays = visibleDaysBeforeAndAfter || 365;
        if (visibleDays > 0) {
            firstDay.setDate(firstDay.getDate() - visibleDays);
            firstDay.setDate(1);
            lastDay.setDate(lastDay.getDate() + visibleDays);
            lastDay = new Date(lastDay.getFullYear(), lastDay.getMonth() + 1, 0);
        }
        let lastvisible = new Date(firstDay.getTime());
        lastvisible.setDate(firstDay.getDate() + chartWidth / getWidthPerDay());
        if (lastvisible.getTime() < lastDay.getTime())
            lastvisible = lastDay;
        firstDay.setHours(0, 0, 0, 0);
        lastDay.setHours(0, 0, 0, 0);
        return { firstDay, lastDay: lastvisible };
    };
    const daysInMonth = (y, m) => {
        return new Date(y, m + 1, 0).getDate();
    };
    const daysSinceStart = (s, u) => {
        const start = s || firstDay;
        const until = u || lastDay;
        const dd = Math.ceil((until.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)) + 1;
        return dd;
    };
    const scrollToToday = () => {
        if (scrollContainer.current != null) {
            scrollPosition.current = { x: todayPosition - chartWidth / 3, y: 0 };
            scrollContainer.current.scrollTo({ animated: true, x: todayPosition - chartWidth / 3, y: 0 });
        }
    };
    useEffect(() => {
        if (initialScrollNeeded && props.data.length > 0 && scrollContainer.current != null) {
            scrollToToday();
            setInitialScrollNeeded(false);
        }
    }, [scrollContainer.current]);
    useEffect(() => {
        const days = getFirstAndLastShownDay();
        setFirstDay(days.firstDay);
        setLastDay(days.lastDay);
        const today = new Date();
        today.setHours(6, 0, 0, 0);
        days.firstDay.setHours(6, 0, 0, 0);
        const daysSince = Math.round((today.getTime() - days.firstDay.getTime()) / (1000 * 60 * 60 * 24)) + 0.5;
        const width = getWidthPerDay();
        const pos = daysSince * width - 1;
        setTodayPosition(pos);
    }, [props, props.data, chartWidth]);
    const lastMove = useRef(0);
    const onMoveStart = useRef();
    const webListener = {
        onMouseMove: (e) => {
            try {
                if (onMoveStart.current != null) {
                    const moved = e.pageX - lastMove.current;
                    if (Math.abs(moved) > 5) {
                        const newScroll = {
                            x: onMoveStart.current - moved,
                            y: scrollPosition.current.y,
                            animated: false,
                        };
                        scrollContainer.current.scrollTo(newScroll);
                    }
                }
            }
            catch (err) {
                console.error(err);
            }
        },
        onMouseDown: (e) => {
            lastMove.current = e.pageX;
            onMoveStart.current = scrollPosition.current.x;
        },
        onMouseUp: (_e) => {
            onMoveStart.current = undefined;
        },
        onMouseLeave: (_e) => {
            onMoveStart.current = undefined;
        },
    };
    const renderSection = (width, text, key, left, bgColor, textColor) => {
        return (<View style={{
                backgroundColor: bgColor || 'transparent',
                height: '100%',
                width,
                borderLeftWidth: ThemeManager.style.borderWidth,
                borderColor: ThemeManager.style.borderColor,
                overflow: 'hidden',
                flex: 1,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left,
            }} key={key}>
          <View style={{
                width,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderColor: ThemeManager.style.borderColor,
                height: 20,
            }}>
            <MaterialText color={textColor} centeredText centeredBox fixedWidth={width - ThemeManager.style.borderWidth}>
              {text}
            </MaterialText>
          </View>
        </View>);
    };
    const renderSingleMonthDay = (firstShown, l, end) => {
        const renderedSections = [];
        const monthDays = end || daysInMonth(firstShown.getFullYear(), firstShown.getMonth());
        const numDays = monthDays - 1;
        let days = 0;
        const widthPerDay = getWidthPerDay();
        const left = l || 0;
        do {
            const title = `${firstShown.getDate() + days}`;
            const date = new Date(firstShown.getFullYear(), firstShown.getMonth(), firstShown.getDate() + days);
            const bg = date.getDay() === 0 || date.getDay() === 6 ? ThemeManager.style.appBgColorDarken : undefined;
            renderedSections.push(renderSection(widthPerDay, title, `section${title}${firstShown.getTime()}`, days * widthPerDay, bg));
            days += 1;
        } while (days <= numDays);
        const kwS = [];
        let lastKw = Calendar.getKW(firstShown);
        for (let i = 0; i < monthDays; i += 1) {
            const thisKw = new Date(firstShown.getFullYear(), firstShown.getMonth(), firstShown.getDate() + i);
            const kw = Calendar.getKW(thisKw);
            if (kw !== lastKw) {
                if (i > 5)
                    kwS.push({ day: i, kw });
                lastKw = kw;
            }
        }
        return (<View key={`month${firstShown.getTime()}`} style={{
                width: widthPerDay * monthDays,
                overflow: 'hidden',
                position: 'absolute',
                left,
                top: 0,
                bottom: 0,
            }}>
          <View style={{
                paddingTop: 2,
                borderLeftWidth: ThemeManager.style.borderWidth * 2,
                borderColor: ThemeManager.style.borderColor,
                justifyContent: 'center',
            }}>
            <View style={{ paddingLeft: 4 }}>
              <MaterialText centeredBox fixedWidth={widthPerDay * monthDays}>{`${monthNames[firstShown.getMonth()]} ${firstShown.getFullYear()}`}</MaterialText>
            </View>
          </View>
          {kwS.map((a) => {
                return (<View style={{ position: 'absolute', left: a.day * widthPerDay, top: 6 }}>
                <MaterialText type={MaterialTextTypes.Body2}>
                  {I18n.m.getMessage('calendarWeekShort')}
                  {a.kw}
                </MaterialText>
              </View>);
            })}
          <View style={{
                flexDirection: 'row',
                flex: 1,
                width: monthDays * widthPerDay,
                position: 'absolute',
                top: 22,
                bottom: 0,
                left: 0,
                borderTopWidth: ThemeManager.style.borderWidth,
                borderColor: ThemeManager.style.borderColor,
            }}>
            {renderedSections}
          </View>
        </View>);
    };
    const renderMonthDayView = () => {
        const months = [];
        let currentDate = firstDay;
        let left = 0;
        while (currentDate.getTime() < lastDay.getTime()) {
            months.push(renderSingleMonthDay(currentDate, left));
            left += daysInMonth(currentDate.getFullYear(), currentDate.getMonth()) * getWidthPerDay();
            if (currentDate.getMonth() === 11)
                currentDate = new Date(currentDate.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
            else
                currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1, 0, 0, 0, 0);
        }
        return months;
    };
    const renderSingleYearMonth = (firstShown, left) => {
        const renderedSections = [];
        const monthNames = I18n.m.dateCurrent.monthsNamesShort();
        const widthPerDay = getWidthPerDay();
        let daysInYear = 0;
        const lastMonth = lastDay.getFullYear() === firstShown.getFullYear() ? lastDay.getMonth() + 1 : 12;
        for (let i = firstShown.getMonth(); i < lastMonth; i += 1) {
            let numDays = daysInMonth(firstShown.getFullYear(), i);
            if (i === lastMonth && lastDay.getMonth() === lastMonth)
                numDays = lastDay.getDate();
            const columnWidth = widthPerDay * numDays;
            const monthName = columnWidth >= 36 ? monthNames[i].replace('.', '') : monthNames[i].charAt(0);
            renderedSections.push(renderSection(numDays * widthPerDay, monthName, `yearMonth${monthName}${firstShown.getFullYear()}`, daysInYear * widthPerDay));
            daysInYear += numDays;
        }
        return (<View key={`year${firstShown.getTime()}`} style={{
                width: widthPerDay * daysInYear,
                overflow: 'hidden',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left,
            }}>
          <View style={{
                paddingTop: 2,
                borderLeftWidth: ThemeManager.style.borderWidth * 2,
                borderColor: ThemeManager.style.borderColor,
            }}>
            <MaterialText centeredText centeredBox fixedWidth={widthPerDay * daysInYear}>
              {firstShown.getFullYear()}
            </MaterialText>
          </View>
          <View style={{
                flexDirection: 'row',
                flex: 1,
                width: daysInYear * widthPerDay,
                borderTopWidth: ThemeManager.style.borderWidth,
                borderColor: ThemeManager.style.borderColor,
            }}>
            {renderedSections}
          </View>
        </View>);
    };
    const renderYearMonthView = () => {
        const years = [];
        const widthPerDay = getWidthPerDay();
        let left = 0;
        years.push(renderSingleYearMonth(firstDay, left));
        for (let i = firstDay.getFullYear() + 1; i <= lastDay.getFullYear(); i += 1) {
            const newStart = new Date(i, 0, 1, 0, 0, 0, 0);
            left = daysSinceStart(firstDay, newStart) * widthPerDay;
            years.push(renderSingleYearMonth(newStart, left));
        }
        return years;
    };
    const renderMultipleYearView = () => {
        const widthPerDay = getWidthPerDay();
        const years = [];
        let firstYearDay = firstDay.getDate();
        for (let i = firstDay.getMonth() + 1; i < 12; i += 1) {
            firstYearDay += daysInMonth(firstDay.getFullYear(), i);
        }
        const firstYearWidth = firstYearDay * widthPerDay;
        years.push(<View style={{ width: firstYearWidth, paddingTop: 2 }}>
          {renderSection(firstYearWidth, firstYearWidth >= 40 ? firstDay.getFullYear().toString() : '', `year${firstDay.getFullYear()}`)}
        </View>);
        for (let i = firstDay.getFullYear() + 1; i < lastDay.getFullYear(); i += 1) {
            const leapYear = daysInMonth(i, 1) === 29;
            years.push(<View style={{ width: 365 * widthPerDay + (leapYear ? widthPerDay : 0), paddingTop: 2 }}>
            {renderSection(365 * widthPerDay + (leapYear ? widthPerDay : 0), i.toString(), `year${i}`)}
          </View>);
        }
        let lastYearDay = lastDay.getDate();
        for (let i = 0; i < lastDay.getMonth(); i += 1) {
            lastYearDay += daysInMonth(lastDay.getFullYear(), i);
        }
        const lastYearWidth = lastYearDay * widthPerDay;
        years.push(<View style={{ width: lastYearWidth, height: '100%', paddingTop: 2 }}>
          {renderSection(lastYearWidth, lastYearWidth >= 40 ? lastDay.getFullYear().toString() : '', `year${lastDay.getFullYear()}`)}
        </View>);
        return years;
    };
    const renderData = () => {
        const { data, timeScale } = props;
        const renderedData = [];
        data.forEach((d) => {
            renderedData.push(<GanttChartDataEntry data={d} widthPerDay={getWidthPerDay()} firstDay={firstDay} key={`${d.id};${timeScale}`}/>);
        });
        return (<View style={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 0, overflow: 'visible' }} pointerEvents="box-none">
          {renderedData}
        </View>);
    };
    const renderFullTimeSpan = () => {
        const { timeScale } = props;
        let rendered = [];
        if (timeScale === 'month/day')
            rendered = renderMonthDayView();
        else if (timeScale === 'year/month')
            rendered = renderYearMonthView();
        else if (timeScale === 'year')
            rendered = renderMultipleYearView();
        return rendered;
    };
    const renderTodayMarker = () => {
        return (<View style={{
                height: '100%',
                width: 2,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: todayPosition,
                backgroundColor: ThemeManager.style.brandPrimary,
            }}/>);
    };
    const renderButtons = () => {
        return (<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <View style={{ paddingTop: 15 }}>
            <Chip title={I18n.m.getMessage('today')} onPressChip={() => scrollToToday()} thumbnail={<Icon icon="calendar-today" toolTip="" outerSize={24} iconSize={20}/>}/>
          </View>
          {props.additionalButtons}
        </View>);
    };
    return (<View style={{ flex: 1, overflow: 'hidden' }} onLayout={(e) => {
            setChartWidth(e.nativeEvent.layout.width);
            setChartHeight(e.nativeEvent.layout.height);
        }}>
        {renderButtons()}
        <ScrollView style={{
            flexDirection: 'row',
            width: chartWidth,
            backgroundColor: ThemeManager.style.appBgColor,
            height: chartHeight,
        }} contentContainerStyle={{
            paddingBottom: 0,
            width: daysSinceStart() * getWidthPerDay(),
            minWidth: chartWidth,
            minHeight: props.data.length * 32 + 40,
            height: '100%',
        }} key={`chart${firstDay}${props.data.length}`} ref={todayPosition != null && props.data.length > 0 ? scrollContainer : undefined} scrollEnabled horizontal onScroll={(e) => {
            scrollPosition.current = { ...e.nativeEvent.contentOffset };
        }} scrollEventThrottle={16} nestedScrollEnabled>
          {renderFullTimeSpan()}

          <ScrollView key={`chartvertical${firstDay}${props.data.length}`} scrollEnabled contentContainerStyle={{ minHeight: props.data.length * 32 + 40, height: '100%', width: '100%' }} style={{
            height: chartHeight - 55 - (props.timeScale === 'year' ? 20 : 40),
            top: props.timeScale === 'year' ? 20 : 40,
            position: 'absolute',
            width: '100%',
        }} nestedScrollEnabled>
            <View style={{
            paddingBottom: 0,
            width: daysSinceStart() * getWidthPerDay(),
            minWidth: chartWidth,
            minHeight: props.data.length * 32 + 40,
            height: '100%',
            flexDirection: 'row',
        }}>
              <View style={{
            position: 'absolute',
            width: daysSinceStart() * getWidthPerDay(),
            minWidth: chartWidth,
            minHeight: props.data.length * 32 + 40,
            height: '100%',
            ...moveS,
        }} {...webListener}/>
              {renderData()}
              {renderTodayMarker()}
            </View>
          </ScrollView>
        </ScrollView>
      </View>);
});
