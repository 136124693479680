import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ConfigAll } from '../../config/ConfigAll';
import { CompanyUserInfo } from './CompanyUserInfo';
export class LogoHandler {
    static getLogo(isBackGroundLogo = false) {
        let globalBarLogo;
        const saveString = isBackGroundLogo ? 'backgroundLogo' : 'companyLogo';
        const g = SimpleStorage.get(saveString);
        if (CompanyUserInfo.companySettings &&
            CompanyUserInfo.company &&
            CompanyUserInfo.companySettings.hasModule('moduleCompanyLogo')) {
            const logoPath = isBackGroundLogo ? 'background-logo' : 'logo';
            const companyImage = isBackGroundLogo ? CompanyUserInfo.company.backgroundImage : CompanyUserInfo.company.logo;
            if (!companyImage || companyImage.length === 0) {
                return null;
            }
            const uri = `${ConfigAll.b2cURL}/company/${CompanyUserInfo.company.id}/${logoPath}/${companyImage}`;
            globalBarLogo = { uri };
            if (uri !== g)
                SimpleStorage.set(saveString, uri);
            return globalBarLogo;
        }
        if (g)
            SimpleStorage.remove(saveString);
        return null;
    }
}
