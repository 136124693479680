import { Icon } from 'materialTheme/src/theme/components/Icon';
import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { PlanZoomTileMapper } from './PlanZoomTileMapper';
export class PlanThumb169 extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.errors = 0;
        this.createdToolTip = (toolTip) => {
            this.setState({ toolTip });
        };
        this.state = { toolTip: '' };
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        this.mounted = false;
    }
    renderBadge() {
        const { badge } = this.props;
        if (badge != null && badge.length > 0) {
            return (<View key="planBadge" style={{
                    position: 'absolute',
                    top: -ThemeManager.style.getScreenRelativePixelSize(6),
                    right: -ThemeManager.style.getScreenRelativePixelSize(6),
                    backgroundColor: ThemeManager.style.brandPrimary,
                    borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                    minWidth: ThemeManager.style.getScreenRelativePixelSize(24),
                    height: ThemeManager.style.getScreenRelativePixelSize(24),
                    justifyContent: 'center',
                }}>
          <MaterialText centeredBox centeredText type={MaterialTextTypes.Caption} color="#FFFFFF">
            {badge}
          </MaterialText>
        </View>);
        }
        return null;
    }
    renderTrashIcon() {
        const { deleteFunction } = this.props;
        return (<View key="trashIcon" style={{ position: 'absolute', top: 8, right: 8 }}>
        <Icon icon="delete-outline" onPress={deleteFunction} toolTip={I18n.m.getMessage('deletePlan')}/>
      </View>);
    }
    renderDownloadIcon() {
        const { downloadFunction } = this.props;
        return (<View key="downloadIcon" style={{ position: 'absolute', top: 8, left: 8 }}>
        <Icon icon="download-outline" onPress={downloadFunction} toolTip={I18n.m.getMessage('downloadPlan')}/>
      </View>);
    }
    renderBottomRightIcon() {
        const { bottomRightIcon, selected } = this.props;
        if (bottomRightIcon == null)
            return null;
        return (<View style={{ height: 36, width: 36, position: 'absolute', right: 4, bottom: 4 }}>
        <Icon {...bottomRightIcon} color={selected ? '#FFFFFF' : undefined}/>
      </View>);
    }
    render() {
        const { width, onPress, onPressData, selected, title, fileSource, fileId, projectId, noBorder, deleteFunction, downloadFunction, plan, bottomRightIcon, } = this.props;
        const { toolTip } = this.state;
        const height = Math.round((width / 16) * 9);
        const imageSize = {
            width,
            height,
        };
        const optionalView = [];
        if (title != null) {
            optionalView.push(<View key="planOverlayTitle" style={{
                    width,
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: selected === true
                        ? ThemeManager.style.planThumbOverlayColorActive
                        : ThemeManager.style.planThumbOverlayColor,
                    justifyContent: 'center',
                    overflow: 'visible',
                }}>
          <View key="subContent" style={{
                    padding: ThemeManager.style.getScreenRelativePixelSize(8),
                    height: ThemeManager.style.getScreenRelativePixelSize(36),
                    overflow: 'visible',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
            <View style={{
                    alignSelf: 'center',
                    height: 24,
                    overflow: 'visible',
                    width: '100%',
                    paddingRight: 24,
                }}>
              <MaterialText type={MaterialTextTypes.Subtitle1} numberOfLines={1} fixedWidth="100%" color={selected === true
                    ? ThemeManager.style.planThumbOverlayTextColorActive
                    : ThemeManager.style.planThumbOverlayTextColor} onToolTipCreated={this.createdToolTip} showToolTipOnCutText>
                {title}
              </MaterialText>
            </View>
          </View>
        </View>);
        }
        let view = (<View style={{ ...imageSize, alignItems: 'center', justifyContent: 'center' }}>
        <Icon toolTip="" icon="map-outline" iconSize={Math.min(imageSize.width / 2, imageSize.height / 2)}/>
      </View>);
        if (fileSource === 'map' && fileId === 'MAP' && plan != null) {
            view = (<View style={{ ...imageSize, alignItems: 'center', justifyContent: 'center' }}>
          <PlanZoomTileMapper plan={plan} type="map" url="map" planVersion={undefined} zoomAble={false}/>
        </View>);
        }
        else if (fileSource !== 'map' && fileId !== 'MAP') {
            const localSynced = OfflineDataDownloader.isPlanVersionSynced(projectId, fileId);
            const localSource = localSynced == null
                ? undefined
                : localSynced.urlTemplate.replace('{z}', '0').replace('{x}', '0').replace('{y}', '0');
            view = (<View style={{ ...imageSize, alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ width: imageSize.width * 2, height: imageSize.height * 2 }}>
            <CacheableImage resizeMode="contain" onlyOnConnectedServer={!(localSynced != null)} style={{ width: imageSize.width * 2, height: imageSize.height * 2 }} width={imageSize.width * 2} height={imageSize.height * 2} localSource={localSource} source={{ uri: `${UpmeshClient.instance.url}/plan/tiles/${projectId}/${fileSource}/${fileId}/0/0/0` }}/>
          </View>
        </View>);
        }
        view = (<View style={{ position: 'relative', overflow: 'visible' }}>
        {view}
        {optionalView}
      </View>);
        if (onPress != null) {
            view = (<Ripple key={`test_${toolTip}`} onPress={() => onPress(onPressData)} toolTip={toolTip} style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}>
          {view}
        </Ripple>);
        }
        if (deleteFunction != null) {
            view = (<View style={{ position: 'relative', overflow: 'visible' }}>
          {view}
          {this.renderTrashIcon()}
        </View>);
        }
        if (downloadFunction != null && fileSource !== 'map') {
            view = (<View style={{ position: 'relative', overflow: 'visible' }}>
          {view}
          {this.renderDownloadIcon()}
        </View>);
        }
        if (bottomRightIcon != null) {
            view = (<View style={{ position: 'relative', overflow: 'visible' }}>
          {view}
          {this.renderBottomRightIcon()}
        </View>);
        }
        return (<View style={{
                width,
                height,
                position: 'relative',
                overflow: 'visible',
            }}>
        <View style={{
                width,
                height,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: ThemeManager.style.borderRadius,
                borderWidth: noBorder ? 0 : ThemeManager.style.borderWidth,
                borderColor: selected === true
                    ? ThemeManager.style.planThumbOverlayColorActive
                    : ThemeManager.style.borderColor,
            }}>
          <View style={{
                ...imageSize,
                width,
                height,
                position: 'absolute',
                backgroundColor: '#FFFFFF',
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflow: 'visible',
            }}>
            {view}

            {this.props.children}
          </View>
        </View>
        {this.renderBadge()}
      </View>);
    }
}
