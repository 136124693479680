"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigHandler = exports.StringUtils = void 0;
var StringUtils = function () {
  function StringUtils() {
    (0, _classCallCheck2.default)(this, StringUtils);
  }
  return (0, _createClass2.default)(StringUtils, null, [{
    key: "isTrue",
    value: function isTrue(value) {
      var testValue = typeof value === 'string' ? value.trim().toLowerCase() : value;
      switch (testValue) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
          return true;
        default:
          return false;
      }
    }
  }]);
}();
exports.StringUtils = StringUtils;
var ConfigHandler = function () {
  function ConfigHandler() {
    (0, _classCallCheck2.default)(this, ConfigHandler);
  }
  return (0, _createClass2.default)(ConfigHandler, null, [{
    key: "setConfig",
    value: function setConfig(config) {
      ConfigHandler._instance = config;
    }
  }, {
    key: "config",
    get: function get() {
      if (ConfigHandler._instance == null) {
        ConfigHandler._instance = ConfigHandler.createDefaultConfig();
      }
      return ConfigHandler._instance;
    }
  }, {
    key: "setTypedKey",
    value: function setTypedKey(config, key, value) {
      if (config[key] != null) {
        if (typeof config[key] === 'number' && !Number.isNaN(parseInt(value, 10))) {
          config[key] = parseInt(value, 10);
        } else if (typeof config[key] === 'boolean') {
          config[key] = StringUtils.isTrue(value);
        } else if (typeof config[key] === 'string') {
          config[key] = value;
        }
      }
    }
  }, {
    key: "getEnvirementConfig",
    value: function getEnvirementConfig(defaultConfig) {
      var config = defaultConfig;
      if (process != null) {
        var _process, _process4;
        if (((_process = process) == null ? void 0 : _process.env) != null) {
          for (var k in (_process2 = process) == null ? void 0 : _process2.env) {
            var _process2, _process3;
            ConfigHandler.setTypedKey(config, k, (_process3 = process) == null ? void 0 : _process3.env[k]);
          }
        }
        if (((_process4 = process) == null ? void 0 : _process4.argv) != null) {
          for (var i = 0; i < ((_process5 = process) == null ? void 0 : _process5.argv.length); i += 1) {
            var _process5, _process6;
            if (((_process6 = process) == null ? void 0 : _process6.argv[i].indexOf('=')) > 0) {
              var _process7;
              var value = (_process7 = process) == null ? void 0 : _process7.argv[i];
              if (value.substr(0, 1) === '-') {
                value = value.substr(1);
              }
              if (value.substr(0, 1) === '-') {
                value = value.substr(1);
              }
              var split = value.split('=');
              ConfigHandler.setTypedKey(config, split[0], split[1]);
            }
          }
        }
        if (global != null && global['serverConfig'] != null) {
          for (var key in global['serverConfig']) {
            ConfigHandler.setTypedKey(config, key, global['serverConfig'][key]);
          }
        }
      }
      return config;
    }
  }, {
    key: "createDefaultConfig",
    value: function createDefaultConfig() {
      var defaultConfig = {
        db: 'nedb',
        JWT_TOKEN_SECRET: 'cdKFAUfbwqeui239038ubnvcDSKbfg239ß1238',
        JWT_EXP_DAYS: 0.5,
        BCRYPT_SALT_ROUNDS: 12,
        cacheSize: '500MB',
        REDIS_PORT: 30634,
        REDIS_HOST: '127.0.0.1',
        REDIS_PASS: 'GoizrRSqRfdSQNirh9Oo',
        S3_ACCESS_KEY_ID: 'K3IK22YFFNOGV8Z5FZ7C',
        S3_SECRET_ACCESS_KEY: 'kWamUUHkFL3gZpudtzb2szAzRuoOpvaMyB8UCQ9L',
        S3_ENDPOINT: 'https://devsss.upmesh.de',
        cronCacheClean: ''
      };
      return ConfigHandler.getEnvirementConfig(defaultConfig);
    }
  }]);
}();
exports.ConfigHandler = ConfigHandler;