import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextEditableV2 } from 'materialTheme/src/theme/components/text/MaterialTextEditableV2';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { CustomFieldCheckboxListOptions, CustomFieldDateOptions, CustomFieldDateRangeOptions, CustomFieldListOptions, CustomFieldMultiselectListOptions, CustomFieldNumberOptions, CustomFieldStringOptions, CustomFieldProgressOptions, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Divider } from 'materialTheme/src/theme/components/utils/Divider';
import { I18n } from '../../../i18n/I18n';
import { ApproverChip } from './ApproverChip';
import { AssigneeChip } from './AssigneeChip';
import { CraftChip } from './CraftChip';
import { MultiPersonChip } from './MultiPersonChip';
import { PersonChip } from './PersonChip';
import { PlanAffiliationThumbs } from './PlanAffiliationThumbs';
import { TagChips } from './TagChips';
import { TicketCompletionDateChip } from './TicketCompletionDateChip';
import { TicketDescriptionInput } from './TicketDescriptionInput';
import { TicketStatusChip } from './TicketStatusChip';
import { TicketProgress } from './TicketProgress';
export function CustomFields(props) {
    const [fields, setFields] = useState([]);
    const projectTickets = useRef([]);
    const loadPropjectTickets = async () => {
        const { project, ticket } = props;
        const allTickets = await UpmeshClient.instance.modals.ticket.get({
            filter: `projectId eq '${project.id}' and deleted ne true`,
        });
        const layout = ChangeTicketCustomField.getTicketLayout(ticket, project);
        projectTickets.current = allTickets.filter((t) => ChangeTicketCustomField.getTicketLayout(t, project).id === layout.id && t.id !== ticket.id);
    };
    const getAutoCompleteOptions = (fieldId) => {
        const options = new Set();
        projectTickets.current.forEach((t) => {
            const val = t.fields?.find((a) => a.id === fieldId)?.value;
            if (val)
                options.add(val);
        });
        return [...options.values()];
    };
    const renderCustomField = (l, field, labelStyle, ticket, editable, errorResult) => {
        const newFields = [];
        if (l.customField) {
            if (l.customField.type === 'number') {
                const o = new CustomFieldNumberOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : 0;
                const c = o.getDefaulValues(l, value);
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialTextEditableV2 numberField={{
                        delimiter: o.delimiter === 0 ? ',' : '.',
                    }} toolTip={l.description} label={l.label} key={`customNumber_${l.id}_${ticket.lastModifiedAt}`} materailTextProps={{
                        type: MaterialTextTypes.Body1,
                        recognizeUrl: false,
                        numberOfLines: 1,
                    }} onChanged={(v) => {
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: Number.parseFloat(v) }, ticket.id);
                        c.execute().catch((err) => errorResult(err));
                    }} placeholder={l.description} editable={editable}>
              {c.formattedValue}
            </MaterialTextEditableV2>
          </View>);
            }
            else if (l.customField.type === 'List') {
                const o = new CustomFieldListOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : '';
                const chipList = [];
                o.list?.forEach((item) => {
                    chipList.push({
                        title: item,
                        onPressChipData: item,
                    });
                });
                const i = chipList.findIndex((a) => a.onPressChipData.trim() === value.trim());
                const selectedIndex = i >= 0 ? i : -1;
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <OpenableChip key={`customField_chip__${l.id}_${ticket.lastModifiedAt}`} dialogTitle={l.label} showDeleteIcon accessibilityLabel={l.label} elevation={40} selected={selectedIndex} onPressChip={(d) => {
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: d }, ticket.id);
                        c.execute().catch((err) => errorResult(err));
                    }} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={chipList} chipDisabled={!editable}/>
          </View>);
            }
            else if (l.customField.type === 'MultiselectList') {
                const o = new CustomFieldMultiselectListOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : [];
                const chipList = [];
                o.list?.forEach((item) => {
                    chipList.push({
                        title: item,
                        id: item,
                    });
                });
                const selectedChips = [];
                value.forEach((v) => {
                    selectedChips.push({
                        title: v,
                        id: v,
                    });
                });
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <ChipGroup dialogTitle={l.label} chips={selectedChips} bordered={false} availableChips={chipList} onChanged={(d) => {
                        const value = [];
                        d.forEach((v) => {
                            if (v.id)
                                value.push(v.id);
                        });
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value }, props.ticket.id);
                        c.execute().catch((err) => props.errorResult(err));
                    }} key={`added_${editable}_${selectedChips.toString()}`} buttonDisabled={!editable} displayDisabledButtons={false} editable={editable} tooltipAddChip={I18n.m.getMessage('ticketsDetailsAddWatcher')}/>
          </View>);
            }
            else if (l.customField.type === 'CheckboxList') {
                const o = new CustomFieldCheckboxListOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : [];
                const chipList = [];
                o.list?.forEach((item) => {
                    chipList.push({
                        title: item,
                        id: item,
                    });
                });
                const selectedChips = new Set();
                value.forEach((v) => {
                    selectedChips.add(v);
                });
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            {chipList.map((item) => {
                        return (<CheckboxListItem disabled={!editable} title={item.title} value={!!selectedChips.has(item.title)} onChange={(v) => {
                                if (!item.id)
                                    return;
                                if (v)
                                    selectedChips.add(item.id);
                                else
                                    selectedChips.delete(item.id);
                                const value = [];
                                selectedChips.forEach((val) => {
                                    if (val)
                                        value.push(val);
                                });
                                const c = new ChangeTicketCustomField({ fieldId: l.id, value }, props.ticket.id);
                                c.execute().catch((err) => props.errorResult(err));
                            }}/>);
                    })}
          </View>);
            }
            else if (l.customField.type === 'DateRange') {
                const o = new CustomFieldDateRangeOptions(l.customField.options);
                const value = field && field.value && field.value['from'] && field.value['to']
                    ? { from: new Date(field.value.from), to: new Date(field.value.to) }
                    : undefined;
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <DateRangeInput onChange={(d) => {
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: d }, props.ticket.id);
                        c.execute().catch((err) => props.errorResult(err));
                    }} labelText={l.label} selectTime={o.withTime} placeholder={l.description} selectedDate={value} disabled={!editable}/>
          </View>);
            }
            else if (l.customField.type === 'Date') {
                const o = new CustomFieldDateOptions(l.customField.options);
                const value = field && field.value && field.value['date'] ? new Date(field.value['date']) : undefined;
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
              {l.label}
            </MaterialText>
            <DateInput onChange={(d) => {
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: { date: d } }, props.ticket.id);
                        c.execute().catch((err) => props.errorResult(err));
                    }} labelText={l.label} selectDate selectTime={o.withTime} placeholder={l.description} selectedDate={value} disabled={!editable}/>
          </View>);
            }
            else if (l.customField.type === 'person') {
                newFields.push(<PersonChip labelStyle={labelStyle} ticket={ticket} errorResult={props.errorResult} personField={l} key={`customField_${l.id}_${ticket.lastModifiedAt}`}/>);
            }
            else if (l.customField.type === 'multiperson') {
                newFields.push(<MultiPersonChip labelStyle={labelStyle} ticket={ticket} errorResult={props.errorResult} personField={l} key={`customField_${l.id}_${ticket.lastModifiedAt}`}/>);
            }
            else if (l.customField.type === 'string') {
                const o = new CustomFieldStringOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : '';
                newFields.push(<View style={{ ...labelStyle }} key={`customField_${l.id}_${ticket.lastModifiedAt}`}>
            <MaterialTextEditableV2 toolTip={l.description} label={l.label} key={`custom_${l.id}_${ticket.lastModifiedAt}`} materailTextProps={{
                        type: MaterialTextTypes.Body1,
                        recognizeUrl: true,
                        numberOfLines: o.multiline ? 0 : 1,
                    }} onChanged={(v) => {
                        const c = new ChangeTicketCustomField({ fieldId: l.id, value: v }, props.ticket.id);
                        c.execute().catch((err) => props.errorResult(err));
                    }} blurOnSubmit={!o.multiline} placeholder={l.description} editable={editable} autoCompleteList={getAutoCompleteOptions(l.id)}>
              {value}
            </MaterialTextEditableV2>
          </View>);
            }
            else if (l.customField.type === 'divider') {
                const o = l.customField.options;
                newFields.push(<Divider title={o.title} description={o.description}/>);
            }
            else if (l.customField.type === 'progress') {
                const o = new CustomFieldProgressOptions(l.customField.options);
                const value = field && field.value != null ? field.value : o.default ? o.default : 0;
                newFields.push(<TicketProgress labelStyle={labelStyle} ticket={ticket} errorResult={props.errorResult} progressField={l} ticketValue={value} editable={editable} key={`customField_${l.id}`}/>);
            }
        }
        return newFields;
    };
    const generateFields = () => {
        const { ticket, project, labelStyle } = props;
        RightsManager.hasWriteRight(ticket.projectId, CurrentUser.userId, 'canChangeAllTickets')
            .then((canChangeAll) => {
            const newFields = [];
            const ticketLayout = ChangeTicketCustomField.getTicketLayout(ticket, project);
            for (const l of ticketLayout.fields) {
                const editable = canChangeAll ||
                    ticket.creator === CurrentUser.userId ||
                    (l.assigneeCanEdit && ticket.assignedToUserId === CurrentUser.userId) ||
                    (l.approverCanEdit && ticket.approverUserId === CurrentUser.userId);
                if (l.systemField) {
                    if (l.systemField === 'description') {
                        newFields.push(<TicketDescriptionInput key={`${project.id}_${ticket.id}_description_${ticket.description}`} ticket={ticket} errorResult={props.errorResult}/>);
                    }
                    else if (l.systemField === 'status') {
                        newFields.push(<TicketStatusChip key={`${project.id}_${ticket.id}_status_${ticket.ticketStatus}`} project={project} labelStyle={labelStyle} ticket={ticket} errorResult={props.errorResult}/>);
                    }
                    else if (l.systemField === 'plan') {
                        newFields.push(<PlanAffiliationThumbs key={`${project.id}_${ticket.id}_plan_${ticket.planId}`} labelStyle={labelStyle} ticket={ticket} plan={props.plan} planVersion={props.planVersion}/>);
                    }
                    else if (l.systemField === 'approver') {
                        newFields.push(<ApproverChip ticket={ticket} key={`${project.id}_${ticket.id}_approver_${ticket.approverUserId}`} labelStyle={labelStyle} errorResult={props.errorResult}/>);
                    }
                    else if (l.systemField === 'assignee') {
                        newFields.push(<AssigneeChip key={`${project.id}_${ticket.id}_assignee_${ticket.assignedToUserId}`} labelStyle={labelStyle} ticket={ticket} errorResult={props.errorResult}/>);
                    }
                    else if (l.systemField === 'craft') {
                        newFields.push(<CraftChip key={`${project.id}_${ticket.id}_craft_${ticket.craft}`} labelStyle={labelStyle} ticket={ticket} errorResult={props.errorResult}/>);
                    }
                    else if (l.systemField === 'tags') {
                        newFields.push(<TagChips key={`${project.id}_${ticket.id}_tags_${ticket.tags && ticket.tags.join(',')}`} ticket={ticket} errorResult={props.errorResult} labelStyle={labelStyle} project={project}/>);
                    }
                    else if (l.systemField === 'completionOn') {
                        newFields.push(<TicketCompletionDateChip key={`${project.id}_${ticket.id}_completionon_${ticket.completionOn}`} hideTime={l.systemFieldOptions != null && l.systemFieldOptions['hideTime']} ticket={ticket} labelStyle={labelStyle} errorResult={props.errorResult}/>);
                    }
                }
                else if (l.customField) {
                    const field = ticket.fields?.find((a) => a.id === l.id);
                    newFields.push(...renderCustomField(l, field, labelStyle, ticket, editable ?? false, props.errorResult));
                    if (l.customField.type === 'List') {
                        const customFieldWithChildren = l.customField.options;
                        const value = field ? field.value : '';
                        if (customFieldWithChildren.children && customFieldWithChildren.children.length > 0) {
                            const matchingChildren = customFieldWithChildren.children.filter((child) => child.onSelectedRule.includes(value));
                            for (const child of matchingChildren) {
                                for (const childField of child.fields) {
                                    const childFieldData = ticket.fields?.find((a) => a.id === childField.id);
                                    newFields.push(...renderCustomField(childField, childFieldData, labelStyle, ticket, editable ?? false, props.errorResult));
                                }
                            }
                        }
                    }
                    if (l.customField.type === 'MultiselectList') {
                        const customFieldWithChildren = l.customField.options;
                        const value = field && field.value != null ? field.value : [];
                        if (customFieldWithChildren.children && customFieldWithChildren.children.length > 0) {
                            const matchingChildren = customFieldWithChildren.children.filter((child) => value.some((value) => child.onSelectedRule.includes(value)));
                            for (let i = 0; i < matchingChildren.length; i += 1) {
                                if (matchingChildren) {
                                    for (const l of matchingChildren[i].fields) {
                                        if (l?.customField) {
                                            const childField = ticket.fields?.find((a) => a.id === l.id);
                                            newFields.push(...renderCustomField(l, childField, labelStyle, ticket, editable ?? false, props.errorResult));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            setFields(newFields);
        })
            .catch((err) => console.warn('cant get rights', err));
    };
    useEffect(() => {
        loadPropjectTickets()
            .then(() => generateFields())
            .catch(console.debug);
    }, [props.ticket, props.project, props]);
    return <View style={{ width: '100%' }}>{fields}</View>;
}
