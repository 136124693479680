import { Url } from 'cqrs-shared/src/uri/Url';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { AccountConfig } from 'materialTheme/src/AccountConfig';
import { AddEmailDialog } from 'materialTheme/src/components/account/mails/AddEmailDialog';
import { ChangeAdressDialog } from 'materialTheme/src/components/account/profile/dialogs/ChangeAdressDialog';
import { ChangePasswordDialog } from 'materialTheme/src/components/account/profile/dialogs/ChangePasswordDialog';
import { ChangeUserDataDialog } from 'materialTheme/src/components/account/profile/dialogs/ChangeUserDataDialog';
import { ForgotPwFormPU } from 'materialTheme/src/components/login/popups/ForgotPwFormPU';
import { DownloadCache } from 'materialTheme/src/file/download/DownloadCache';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { LoggerReactNative } from 'materialTheme/src/LoggerReactNative';
import { SimpleReactNativeStorage } from 'materialTheme/src/odataDB/SimpleReactNativeStorage';
import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { SecureLocalStoreB } from 'materialTheme/src/securestore/SecureLocalStoreB';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { FileDropHandler } from 'materialTheme/src/theme/components/file/FileDropHandler';
import { ContentFooterEventHandler, } from 'materialTheme/src/theme/components/footer/ContentFooterEventHandler';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { Wrapper } from 'materialTheme/src/theme/components/wrappper/Wrapper';
import { LanguageAsyncCache } from 'materialTheme/src/theme/components/wrappper/WrapperUserContext';
import { I18n as ThemeI18n } from 'materialTheme/src/theme/i18n/I18n';
import { LocaleDetector } from 'materialTheme/src/theme/i18n/LocaleDetector';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Redirect } from 'materialTheme/src/theme/routing/Redirect';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SplashScreen } from 'materialTheme/src/utils/SplashScreen';
import { AccountView } from 'materialTheme/src/views/account/AccountView';
import { SettingsView } from 'materialTheme/src/views/account/SettingsView';
import { SyncView } from 'materialTheme/src/views/account/SyncView';
import { LoginView } from 'materialTheme/src/views/login/LoginView';
import { PasswordWithTokenView } from 'materialTheme/src/views/login/PasswordWithTokenView';
import { RegisterView } from 'materialTheme/src/views/login/RegisterView';
import { ValidateEmailView } from 'materialTheme/src/views/login/ValidateEmailView';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import querystring from 'querystring';
import React, { PureComponent } from 'react';
import { AppState, Image, Linking, Platform, View } from 'react-native';
import ReceiveSharingIntent from 'react-native-receive-sharing-intent';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { NetworkStatus } from 'upmesh-auth-core/src/client/NetworkStatus';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n as I18Core } from 'upmesh-core/src/i18n/I18n';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18nClient, I18nOptions } from 'upmesh-i18n/src/I18nClient';
import * as uuid from 'uuid';
import { ClientDB } from './ClientDB';
import { CompanyProjectTeamView } from './components/companies/CompanyProjectTeamView';
import { CompanyView } from './components/companies/CompanyView';
import { UserDetailsPU } from './components/companies/UserDetailsPU';
import { UserTokenCard } from './components/companies/UserTokenCard';
import { DefaultErrorHandler } from './components/DefaultErrorHandler';
import { AddFilesPU } from './components/file/AddFilesPU';
import { FilesUploadPU } from './components/file/FilesUploadPU';
import { SharedFiles } from './components/file/SharedFiles';
import { FileInfoDialog } from './components/FileInfoDialog';
import { Notifications } from './components/notifications/Notifications';
import { PushNotifications } from './components/notifications/PushNotifications';
import { AddMapPU } from './components/plans/AddMapPU';
import { PlanEditPU } from './components/plans/PlanEditPU';
import { PlanUploadedPU } from './components/plans/PlanUploadedPU';
import { PlanUploadPU } from './components/plans/PlanUploadPU';
import { CreateProjectDialog } from './components/project/CreateProjectDialog';
import { CurrentProject } from './components/project/CurrentProject';
import { EditProjectDialog } from './components/project/EditProjectDialog';
import { CompanyUserInfo } from './components/root/CompanyUserInfo';
import { openSearch } from './components/root/globalSearch/GlobalSearch';
import { HomeRedirectView } from './components/root/HomeRedirectView';
import { HomeView } from './components/root/HomeView';
import { NotFoundView } from './components/root/NotFoundView';
import { RoutesConfigProtectedProjects } from './components/routes/RoutesConfigProtectedProjects';
import { DeleteAccountCard } from './components/subscription/DeleteAccountCard';
import { SubscriptionAccountCard } from './components/subscription/SubscriptionAccountCard';
import { SubscriptionOrder } from './components/subscription/SubscriptionOrder';
import { SubscriptionPlanView } from './components/subscription/SubscriptionPlanView';
import { ProjectAddTeamPU } from './components/team/ProjectAddTeamPU';
import { ProjectChangeRolePU } from './components/team/ProjectChangeRolePU';
import { ProjectMemberDetailsPU } from './components/team/ProjectMemberDetailsPU';
import { TicketPU } from './components/tickets/TicketPU';
import { UpmeshSettings } from './components/UpmeshSettings';
import { VersionsCard } from './components/VersionsCard';
import { CachedEntities } from './config/CachedEntities';
import { Config } from './config/Config';
import { ConfigAll } from './config/ConfigAll';
import { Init } from './config/Init';
import { I18n } from './i18n/I18n';
import { MessagesDe } from './i18n/MessagesDe';
import { PushNotificationsSetup } from './PushNotificationsSetup';
import { OfflineDataDownloader } from './repo/file/OfflineDataDownloader';
import { SyncProgressFooter } from './SyncProgressFooter';
import { B2cStyles } from './theme/B2cStyles';
import { WhatsNew } from './WhatsNew';
const LAUNCH_IMAGE = require('./assets/launch_screen.png');
const LOGOWhite = require('./assets/signUpLogoWhite.png');
const LOGOBlack = require('./assets/upmesh_logo.png');
const bgImage = require('./assets/signUpImage.jpg');
const appVersionInfo = ConfigAll.getVersion();
export class Bau2cloudApp extends PureComponent {
    constructor(props) {
        super(props);
        this.starting = false;
        this.onRouteChange = (e) => {
            if (CurrentUser.userId != null && CurrentUser.userId.length > 5) {
                SimpleStorage.set(`lastRoute_${CurrentUser.userId}`, e.path);
            }
        };
        this.handleAppMemoryWarning = (e) => {
            console.warn('handleAppMemoryWarning', e);
        };
        this.handleWebStateChange = (nextAppState) => {
            if (this.inActiveTimeout)
                clearTimeout(this.inActiveTimeout);
            this.windowActive = nextAppState === 'active';
            if (nextAppState === 'background') {
                this.inActiveTimeout = setTimeout(() => {
                    window.location.href = `${window.location.origin}/inactive.html`;
                }, 3600000);
            }
        };
        this.windowActive = true;
        this.handleAppStateChange = (nextAppState) => {
            try {
                if (AuthClient.instance != null && AuthClient.instance.serverConnection != null) {
                    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
                        if (AuthClient.instance.loggedIn && AuthClient.instance.serverConnection.isConnected) {
                            AuthClient.instance.startSync().catch((err) => console.debug(err));
                        }
                        else if (!AuthClient.instance.serverConnection.isConnected) {
                            AuthClient.instance.serverConnection.connect().catch((err) => console.debug(err));
                        }
                    }
                    else {
                    }
                }
            }
            catch (e) {
                console.debug('error', e);
            }
            this.setState({ appState: nextAppState });
        };
        this.steal = (_e) => {
            console.log('steal hash 1');
            Init.postMessage(`stealµ${Init.clientId}`);
            const url = Url.getURLfromString(window.location.href);
            url.hash = '';
            window.location.replace(url.href);
        };
        this.staticView = () => {
            return (<View pointerEvents="none" style={{
                    position: 'absolute',
                    height: 24,
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    bottom: 128,
                    left: 0,
                    right: 0,
                    zIndex: 400,
                    elevation: 40,
                }}>
        <MaterialText color="#999999" fixedWidth="100%" textAlign="center">
          {`${ConfigAll.getVersion()} ${Platform.OS}`}
        </MaterialText>
      </View>);
        };
        this.gotoAfterStartCalled = false;
        this.handleOpenURL = (event) => {
            if (event && event.url && !event.url.includes('dataUrl=ShareKey')) {
                const u = Url.getURLfromString(event.url);
                const path = this.getPathWithRemoveBasename(u);
                Routing.instance.goTo(path);
            }
        };
        this.notFoundView = (props) => <NotFoundView {...props}/>;
        this.startUploads = () => {
            if (ClientDB.instance.db != null) {
                Uploads.instance
                    .startUploads(ClientDB.instance.db)
                    .then(() => {
                    console.debug('start Uploads b2c');
                })
                    .catch((err) => {
                    console.error('cant start Uploads in Bau2Cloud.tsx', err);
                });
            }
        };
        this.startLogin = async () => {
            console.log('startLogin DB LOAD', AuthClient.instance?.serverConnected());
            if (AuthClient.instance?.serverConnected()) {
                this.setState({ logIn: true, syncPercent: 1, syncText: I18n.m.getMessage('initDatabase') });
            }
            else {
                AuthClient.instance.startSync().catch((err) => console.debug(err));
            }
        };
        this.onLogin = (data) => {
            this.setState({ logIn: true }, () => {
                ErrorReporter.setUser(data.userId);
                console.log('appstart syncOnLogin starts after', (new Date().getTime() - this.startTest.getTime()) / 1000, 'seconds');
                this.startTest2 = new Date();
                LoadingEvents.instance.startLoading(I18n.m.getMessage('syncOnLogin'));
                const asyncNow = async () => {
                    await DateLocale.waitFor(() => AuthClient.instance != null, 100, 10000);
                    AuthClient.onLogout(this.onLogout);
                    AuthClient.instance.registerBeforeLogout(this.beforeLogout);
                    await DateLocale.waitFor(() => CurrentUser.userId != null && CurrentUser.userId.length > 0, 100, 10000);
                    if (CurrentUser.userId == null || CurrentUser.userId.length === 0) {
                        this.setState({ logIn: false });
                        return;
                    }
                    await DateLocale.waitFor(() => UpmeshClient.instance.ready, 100, 10000);
                    await Init.requestPermissions();
                    try {
                        const { host } = Url.getURLfromString(Config.b2cURL);
                        const currentToken = await SecureLocalStoreB.instance.getItem(`tt_${host}`);
                        if (currentToken == null || currentToken !== data.token) {
                            if (AuthClient.instance.stayLoggedIn) {
                                const yearFromNow = new Date();
                                yearFromNow.setFullYear(yearFromNow.getFullYear() + 1);
                                await SecureLocalStoreB.instance.setItem(`tt_${host}`, data.token, yearFromNow);
                            }
                            else {
                                await SecureLocalStoreB.instance.setItem(`tt_${host}`, data.token);
                            }
                        }
                    }
                    catch (e) {
                        ErrorReporter.sendReport({ subject: 'cant save token', data: e, type: 'warn' });
                    }
                    if (AuthClient.instance != null) {
                        AuthClient.instance.syncDispatcher.syncStop.detach(this.startUploads);
                        AuthClient.instance.syncDispatcher.syncStop.attach(this.startUploads);
                        const fresh = true;
                        console.log('freshDB', {
                            fresh,
                            connected: AuthClient.instance?.serverConnection?.isConnected,
                            connected2: AuthClient.instance?.serverConnected(),
                            db: AuthClient.instance.localDb != null,
                            ready: AuthClient.instance.ready,
                        });
                        if (fresh && AuthClient.instance?.serverConnected()) {
                            await new Promise((resolve) => {
                                const sStatus = (s) => {
                                    const syncText = s.step && s.step.readModelName !== ''
                                        ? `${I18n.m.getMessage(s.step.readModelName.charAt(0).toLowerCase() + s.step.readModelName.slice(1))} - ${s.step.percent}% `
                                        : '';
                                    this.setState({ syncPercent: s.percent, syncText });
                                };
                                AuthClient.instance.syncDispatcher.syncStatus.attach(sStatus);
                                AuthClient.instance.syncDispatcher.syncStop.once(() => {
                                    AuthClient.instance.syncDispatcher.syncStatus.detach(sStatus);
                                    resolve();
                                });
                                if (AuthClient.instance != null) {
                                    AuthClient.instance.startSync().catch((err) => console.debug(err));
                                }
                                else {
                                    resolve();
                                }
                            });
                            console.log('appstart sync finished!');
                        }
                        else {
                            AuthClient.instance.startSync().catch((err) => console.debug(err));
                        }
                        AuthClient.instance.syncDispatcher.syncStatus.attach((status) => {
                            if (status.running) {
                                this.openFooter();
                            }
                            else {
                                this.hideFooter();
                            }
                        });
                        setTimeout(() => {
                            this.startUploads();
                        }, 5000);
                    }
                    await CachedEntities.init();
                    await this.setCompanyUserInfo();
                    UpmeshClient.eventDispatcher.attach({
                        attachKey: 'GlobalCompanyMemberListener',
                        callback: this.setCompanyUserInfo,
                        readModelName: 'CompanyMember',
                    });
                    try {
                        const settings = await UpmeshClient.instance.modals.notificationsSettings.getById(CurrentUser.userId);
                        if (settings.pushNotifications != null && settings.pushNotifications.length > 0) {
                            await PushNotifications.getState(settings.pushNotifications);
                        }
                    }
                    catch (e) {
                        console.debug('cant get Settings, using defaults');
                    }
                    LoadingEvents.instance.stopLoading();
                    console.log('appstart syncOnLogin ends after', (new Date().getTime() - this.startTest.getTime()) / 1000, 'seconds, login duration: ', (new Date().getTime() - this.startTest2.getTime()) / 1000, 'seconds');
                    this.setState({ logIn: false });
                    await OfflineDataDownloader.init();
                    requestAnimationFrame(() => {
                        if (data.goTo != null)
                            this.startUrl = data.goTo;
                        this.goToAfterLogin().catch((err) => console.debug(err));
                    });
                    KeyboardEvents.onKeyDown((e) => {
                        if (e.ctrlKey || e.metaKey) {
                            if (e.key === 'k') {
                                if (typeof e['preventDefault'] === 'function')
                                    e.preventDefault();
                                openSearch();
                            }
                        }
                    }, 'SEARCH');
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.openedFooterOnce = false;
        this.openFooter = () => {
            if (!this.openedFooterOnce) {
                const footerContent = <SyncProgressFooter hideFooter={this.hideFooter}/>;
                const data = {
                    footerContent,
                    open: true,
                    footerHeight: 40,
                };
                ContentFooterEventHandler.statusEvent.post(data);
            }
        };
        this.hideFooter = () => {
            this.openedFooterOnce = true;
            const data = {
                open: false,
            };
            ContentFooterEventHandler.statusEvent.post(data);
        };
        this.setCompanyUserInfo = () => {
            const asyncNow = async () => {
                try {
                    const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                        filter: `userId eq '${CurrentUser.userId}' and deleted ne true`,
                        top: 1,
                    });
                    if (companyMember.length > 0 && companyMember[0].role !== 'inactive') {
                        CompanyUserInfo.company = await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
                    }
                    else {
                        CompanyUserInfo.company = null;
                    }
                }
                catch (err) {
                    console.debug('cant get company informations', err);
                    CompanyUserInfo.company = null;
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.beforeLogout = async () => {
            LoadingEvents.instance.startLoading();
            if (Platform.OS === 'web') {
                window.onunload = () => {
                    if (!AuthClient.instance.stayLoggedIn)
                        SecureLocalStoreB.instance
                            .removeItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`)
                            .catch((err) => console.debug(err));
                    return false;
                };
            }
            try {
                const settings = await UpmeshClient.instance.modals.notificationsSettings.getById(CurrentUser.userId);
                if (settings.pushNotifications != null && settings.pushNotifications.length > 0) {
                    const getStatus = await PushNotifications.getState(settings.pushNotifications);
                    if (getStatus) {
                        await PushNotifications.setState(false);
                    }
                }
                UpmeshClient.eventDispatcher.detach('CompanyMember', 'GlobalCompanyMemberListener');
                CompanyUserInfo.company = null;
            }
            catch (e) {
                console.debug('cant get Settings, using defaults');
            }
            try {
                await SecureLocalStoreB.instance.removeItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`);
            }
            catch (e) {
                console.warn('App.onLogout cant delete token');
                ErrorReporter.sendReport({ subject: 'cant delete token', data: e, type: 'warn' });
            }
        };
        this.onLogout = (data) => {
            LoadingEvents.instance.startLoading();
            KeyboardEvents.remove('SEARCH');
            ErrorReporter.setUser('');
            const asyncNow = async () => {
                await CachedEntities.reset();
                await OfflineDataDownloader.reset();
                AuthClient.instance.syncDispatcher.syncStop.detach(this.startUploads);
                AuthClient.onLogoutDetach(this.onLogout);
                console.debug('App.onLogout 1', data);
                await DateLocale.waitFor(() => CurrentUser.userId == null || CurrentUser.userId.length === 0, 100, 10000);
                console.debug('App.onLogout 2', data);
                try {
                    await SecureLocalStoreB.instance.removeItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`);
                }
                catch (e) {
                    console.warn('App.onLogout cant delete token');
                    ErrorReporter.sendReport({ subject: 'cant delete token', data: e, type: 'warn' });
                }
                Init.postMessage('logout');
                requestAnimationFrame(() => {
                    if (Platform.OS === 'web') {
                        setTimeout(() => {
                            location.href = this.windowActive
                                ? `${ThemeManager.style.basename}login`
                                : `${window.location.origin}/inactive.html`;
                        }, 100);
                    }
                    else if (data.goTo != null) {
                        LoadingEvents.instance.stopLoading();
                        requestAnimationFrame(() => {
                            Routing.instance.goTo(data.goTo);
                        });
                    }
                    else {
                        LoadingEvents.instance.stopLoading();
                        requestAnimationFrame(() => {
                            Routing.instance.goTo('/login');
                        });
                    }
                });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.receiveSharingIntentOnce = false;
        this.gotSharedFiles = (files) => {
            SharedFiles.files = files;
            SharedFiles.excludeFolders = null;
            const data = { type: 'TicketOrFolder' };
            if (CurrentProject.instance.getCurrentProject() != null) {
                data.projectId = CurrentProject.instance.getCurrentProject()?.id;
            }
            const t = CurrentProject.instance.getCurrentTicketId();
            if (t != null) {
                data.type = 'Tickets';
                data.entityId = t;
            }
            AddFilesPU.openDialog(data)(null);
        };
        this.onGotDefaultFiles = (f) => {
            if (f.length > 0) {
                const data = { type: 'TicketOrFolder' };
                if (CurrentProject.instance.getCurrentProject() != null) {
                    data.projectId = CurrentProject.instance.getCurrentProject()?.id;
                }
                const t = CurrentProject.instance.getCurrentTicketId();
                if (t != null) {
                    data.type = 'Tickets';
                    data.entityId = t;
                }
                SharedFiles.files = f;
                SharedFiles.excludeFolders = null;
                AddFilesPU.openDialog({ ...data })(null);
            }
        };
        this.startTest = new Date();
        SimpleStorage.init(new SimpleReactNativeStorage());
        this.state = {
            appState: AppState.currentState,
            started: false,
            logIn: false,
            env: 'production',
        };
        LoggerReactNative.transporter = Config.getEnvironment() === 'production' ? 'loki' : 'console';
        LoggerReactNative.init('client').catch((err) => console.error('cant init log library', err));
        try {
            ThemeManager.style = new B2cStyles();
        }
        catch (e) {
            ErrorReporter.sendReport({ subject: 'Bau2cloudApp App constructor style issue', data: e, type: 'warn' });
        }
        if (Platform.OS !== 'web') {
            try {
                navigator['geolocation'] = require('@react-native-community/geolocation');
            }
            catch (e) {
                console.debug('cant set geolocation on navigator');
            }
        }
        else {
            Config.setBasename(window.location.href);
            if ('serviceWorker' in navigator) {
                console.log('service-worker loading');
                navigator.serviceWorker
                    .register('/sw.js')
                    .then((r) => {
                    console.debug('PushNotifications: registered service-worker', r);
                    PushNotifications.workerRunning = true;
                })
                    .catch((err) => console.debug('cant register service-worker', err));
            }
        }
        AccountConfig.config = { bgImage, logoWhite: LOGOWhite, logoBlack: LOGOBlack };
        Routing.routes = Bau2cloudApp.routes;
        RouterControl.notFoundRoute = this.notFoundView;
    }
    static get routes() {
        return [
            { path: '/', component: (props) => <HomeRedirectView {...props} key="---"/> },
            {
                path: '/login',
                component: (props) => (<LoginView socialLogin appVersion={appVersionInfo} {...props} config={Config.b2cURL} key="login"/>),
            },
            {
                path: '/register',
                beforeRoute: Bau2cloudApp.alreadyLoggedIn,
                component: (props) => <RegisterView appVersion={appVersionInfo} {...props} key="register"/>,
            },
            {
                path: '/account',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => (<AccountView {...props} key="account">
            <UserTokenCard />
            <SubscriptionAccountCard {...props}/>
            <DeleteAccountCard {...props}/>
          </AccountView>),
            },
            {
                path: 'company/projectteam/:projectId',
                exact: false,
                component: (props) => <CompanyProjectTeamView {...props} key="company/projectteam/:projectId"/>,
            },
            {
                path: '/company',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <CompanyView {...props} key="company"/>,
            },
            {
                path: '/company/:tab',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <CompanyView {...props} key="company"/>,
            },
            {
                path: '/sync',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => (<SyncView fileInfo={FileInfoDialog.open} defaultErrorHandler={(e) => DefaultErrorHandler.getDefaultErrorMessages(e)} {...props} key="sync"/>),
            },
            {
                path: '/forgotpw',
                component: (props) => <PasswordWithTokenView {...props} key="forgotpw"/>,
            },
            {
                path: '/validate',
                component: (props) => <ValidateEmailView {...props} key="validate"/>,
            },
            {
                path: '/settings',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => (<SettingsView additionalPersonalSettings={<UpmeshSettings />} appVersion={appVersionInfo} {...props} key="settings" defaultErrorHandler={DefaultErrorHandler.showDefaultErrorAlert}>
            <Notifications {...props}/>
            <VersionsCard appVersion={appVersionInfo}/>
          </SettingsView>),
            },
            {
                path: '/allTickets',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <HomeView {...props} site="allTickets" key="homeView"/>,
            },
            {
                path: '/timeTracking',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <HomeView {...props} site="timeTracking" key="homeView"/>,
            },
            {
                path: '/absences',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <HomeView {...props} site="absences" key="homeView"/>,
            },
            {
                path: '/timeTrackingCalendar',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <HomeView {...props} site="timeTrackingCalendar" key="homeView"/>,
            },
            {
                path: 'projects/:projectId/:page/:planId/:tab/:versionId',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <RoutesConfigProtectedProjects {...props} key="projects"/>,
            },
            {
                path: 'projects/:projectId/:page/:planId/:tab',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <RoutesConfigProtectedProjects {...props} key="projects"/>,
            },
            {
                path: 'projects/:projectId/:page/:planId',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <RoutesConfigProtectedProjects {...props} key="projects"/>,
            },
            {
                path: 'projects/:projectId/:page',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <RoutesConfigProtectedProjects {...props} key="projects"/>,
            },
            {
                path: 'projects/:projectId',
                beforeRoute: Bau2cloudApp.loginNeeded,
                exact: false,
                component: (props) => <RoutesConfigProtectedProjects {...props} key="projects"/>,
            },
            {
                path: '/projects',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <HomeView {...props} site="projects" key="homeView"/>,
            },
            {
                path: '/activities',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <HomeView {...props} site="activities" key="homeView"/>,
            },
            {
                path: '/dashboard',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <HomeView {...props} site="dashboard" key="homeView"/>,
            },
            {
                path: '/extraPayTracking',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <HomeView {...props} site="extraPayTracking" key="homeView"/>,
            },
            {
                path: 'forgotpw',
                asDialog: 'form',
                component: (props) => <ForgotPwFormPU {...props} key="forgotpw"/>,
            },
            {
                path: '/subscriptionPlanView',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <SubscriptionPlanView {...props} key="subscriptionPlanView"/>,
            },
            {
                path: '/subscriptionOrder/:planVersionId',
                beforeRoute: Bau2cloudApp.loginNeeded,
                component: (props) => <SubscriptionOrder {...props} key="subscriptionOrder/:planVersionId"/>,
            },
            {
                path: 'changeUserData',
                asDialog: 'form',
                component: (props) => <ChangeUserDataDialog {...props} key="changeUserData"/>,
            },
            {
                path: 'changePassword',
                asDialog: 'form',
                component: (props) => <ChangePasswordDialog {...props} key="changePassword"/>,
            },
            {
                path: 'changeAdress',
                asDialog: 'form',
                component: (props) => <ChangeAdressDialog {...props} key="changeAdress"/>,
            },
            {
                path: 'addEmail',
                asDialog: 'form',
                component: (props) => <AddEmailDialog {...props} key="addEmail"/>,
            },
            {
                path: 'createProject',
                asDialog: 'form',
                component: (props) => <CreateProjectDialog {...props} key="createProject"/>,
            },
            {
                path: 'editProject',
                asDialog: 'form',
                component: (props) => <EditProjectDialog {...props} key="editProject"/>,
            },
            {
                path: 'addTeam',
                asDialog: 'responsible',
                component: (props) => <ProjectAddTeamPU {...props} key="addTeam"/>,
            },
            {
                path: 'teammember',
                asDialog: 'responsible',
                component: (props) => <ProjectMemberDetailsPU {...props} key="teamember"/>,
            },
            {
                path: 'user',
                asDialog: 'responsible',
                component: (props) => <UserDetailsPU {...props} key="user"/>,
            },
            {
                path: 'changeRole',
                asDialog: 'responsible',
                component: (props) => <ProjectChangeRolePU {...props} key="changeRole"/>,
            },
            {
                path: 'uploadPlan',
                asDialog: 'responsible',
                component: (props) => <PlanUploadPU {...props} key="uploadPlan"/>,
            },
            {
                path: 'addMap',
                asDialog: 'responsible',
                optionalDialogContainerProps: { maxWidth: 1024 },
                component: (props) => <AddMapPU maxWidth={1024} {...props} key="addMap"/>,
            },
            {
                path: 'uploadFiles',
                asDialog: 'responsible',
                component: (props) => <FilesUploadPU {...props} key="uploadFiles"/>,
            },
            {
                path: 'tempPlans',
                asDialog: 'extendedDialog',
                component: (props) => <PlanUploadedPU {...props} key="PlanUploadPu"/>,
            },
            {
                path: 'editPlan',
                asDialog: 'responsible',
                component: (props) => <PlanEditPU {...props} key="PlanEditPu"/>,
            },
            {
                path: 'ticket',
                asDialog: 'extendedDialog',
                component: (props) => <TicketPU {...props} key="ticketPu"/>,
            },
        ];
    }
    componentDidMount() {
        console.log('appstart componentDidMount in', (new Date().getTime() - this.startTest.getTime()) / 1000, 'seconds');
        if (Platform.OS === 'web' && window.location.href.toLowerCase().includes('fromwebsite')) {
            const url = Url.getURLfromString(window.location.href);
            const qs = querystring.parse(url.search.startsWith('?') ? url.search.substring(1) : url.search);
            const today = new Date();
            today.setDate(today.getDate() + 1);
            SecureLocalStoreB.instance
                .setItem('fromwebsite', qs.fromwebsite && typeof qs.fromwebsite === 'string' ? qs.fromwebsite : '1', today)
                .catch((err) => console.warn(err));
        }
        if (!this.starting) {
            this.onStart()
                .then(() => {
                if (Platform.OS === 'web') {
                    window.onfocus = () => {
                        this.handleWebStateChange('active');
                    };
                    window.onblur = () => {
                        this.handleWebStateChange('background');
                    };
                }
                else {
                    this.appStateListener = AppState.addEventListener('change', this.handleAppStateChange);
                    this.appMemoryListener = AppState.addEventListener('memoryWarning', this.handleAppMemoryWarning);
                }
            })
                .catch((err) => {
                console.log('appstart ends error in', (new Date().getTime() - this.startTest.getTime()) / 1000, 'seconds');
                SplashScreen.hide();
                this.setState({ startupError: err });
            });
        }
        Routing.instance.routeChanged.attach(this.onRouteChange);
    }
    componentWillUnmount() {
        if (this.linkListener != null)
            this.linkListener.remove();
        if (this.appStateListener != null)
            this.appStateListener.remove();
        if (this.appMemoryListener != null)
            this.appMemoryListener.remove();
        Routing.instance.routeChanged.detach(this.onRouteChange);
    }
    async getLastRoute(userId) {
        const lastRoute = SimpleStorage != null ? SimpleStorage.get(`lastRoute_${userId}`) : null;
        return lastRoute;
    }
    render() {
        const { started, logIn, startupError, syncPercent, syncText } = this.state;
        if (!started || logIn || startupError != null) {
            return (<View style={{ ...ThemeManager.style.absoluteStyle, justifyContent: 'center' }}>
          <Image source={SimpleStorage.get('companyLogo') ? { uri: SimpleStorage.get('companyLogo') } : LAUNCH_IMAGE} resizeMode="contain" style={{
                    width: 256,
                    height: 256,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    alignSelf: 'center',
                }}/>
          {!startupError ? null : (<View style={{ width: '100%', paddingTop: 8, justifyContent: 'center' }}>
              <MaterialText centeredText centeredBox fixedWidth="100%">
                {startupError.message != null ? startupError.message : startupError.toString()}
              </MaterialText>
              {Platform.OS !== 'web' ? null : (<View style={{ paddingTop: 16, alignSelf: 'center' }}>
                  <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('tryAgain')} onPress={this.steal}/>
                </View>)}
            </View>)}
          {logIn && syncPercent != null && syncPercent >= 1 ? (<View style={{ width: '100%', paddingTop: 16, alignItems: 'center' }}>
              <MaterialText centeredText centeredBox fixedWidth="100%">
                {I18n.m.getMessage('syncOnLogin')}
              </MaterialText>
              <MaterialText centeredText centeredBox fixedWidth="100%">
                {syncText}
              </MaterialText>
              
              
              
              <View style={{ width: '90%', maxWidth: 512, paddingTop: 16 }}>
                <ProgressBar progressInPercent={syncPercent}/>
              </View>
            </View>) : null}
        </View>);
        }
        const { env } = this.state;
        return (<Wrapper connectionContext key="wrapper" style={ThemeManager.style.absoluteStyle} staticView={env === 'production' ? undefined : this.staticView}/>);
    }
    getPathWithRemoveBasename(uri) {
        let basename = uri.hostname === 'dev.upmesh.de' ? '/app/' : ThemeManager.style.basename;
        const url = { ...uri };
        if (basename != null && basename.length > 1) {
            if (basename.substr(basename.length - 1) === '/') {
                basename = basename.substr(0, basename.length - 1);
            }
            if (url.pathname.length >= basename.length && url.pathname.substr(0, basename.length) === basename) {
                if (url.pathname.substr(0, 1) !== '/') {
                    url.pathname = `/${url.pathname}`;
                }
                return url.pathname.substr(basename.length) + url.search;
            }
        }
        return url.pathname + url.search;
    }
    async goToAfterStart() {
        const { started, logIn } = this.state;
        let path = '/login';
        if (this.startUrl != null) {
            path = this.getPathWithRemoveBasename(this.startUrl);
        }
        if (!logIn && started && RouterControl.isMounted) {
            requestAnimationFrame(() => {
                if (RouterControl.instance.currentUrl.pathname !== '/' || path !== RouterControl.instance.currentUrl.pathname) {
                    Routing.instance.goTo(path, true);
                }
                this.gotoAfterStartCalled = true;
            });
        }
        else if (logIn) {
            this.gotoAfterStartCalled = true;
        }
        else {
            setTimeout(() => {
                this.goToAfterStart().catch((err) => console.error(err));
            }, 100);
        }
    }
    async goToAfterLogin() {
        const { started, logIn } = this.state;
        let path = '';
        if (this.startUrl != null) {
            path = this.getPathWithRemoveBasename(this.startUrl);
            if (path.indexOf('login') > -1 ||
                path.indexOf('register') > -1 ||
                (path.indexOf('forgotpw') > -1 && CurrentUser.userId != null && CurrentUser.userId.length > 0)) {
                const toSubscription = Platform.OS === 'web' &&
                    (await RightsManager.hasVersion(CurrentUser.userId)) === 'basic' &&
                    CompanyUserInfo.company == null;
                path = toSubscription
                    ? '/subscriptionPlanView'
                    : ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM
                        ? '/projects'
                        : '/dashboard';
            }
        }
        if (this.gotoAfterStartCalled &&
            !logIn &&
            CurrentUser.userId != null &&
            CurrentUser.userId.length > 0 &&
            started &&
            RouterControl.isMounted) {
            requestAnimationFrame(() => {
                Routing.instance.routeChanged.once(() => {
                    this.showWhatsNew().catch((err) => console.debug(err));
                    setTimeout(() => {
                        this.addSharingFeature();
                    }, 1000);
                });
                if (path === '/')
                    path = '/dashboard';
                if (RouterControl.instance.currentUrl.pathname !== '/' || path !== RouterControl.instance.currentUrl.pathname) {
                    Routing.instance.goTo(path, true);
                }
            });
        }
        else {
            setTimeout(() => {
                this.goToAfterLogin().catch((err) => console.error(err));
            }, 100);
        }
    }
    async initLinking() {
        this.linkListener = Linking.addEventListener('url', this.handleOpenURL);
        let url = new Url('', '', '', '', '', '/', 80, '', '');
        if (Platform.OS.toString() === 'web') {
            try {
                url = Url.getURLfromString(window.location.toString());
                if (url == null || url.pathname === '/' || url.pathname === '/login' || url.pathname === '/register') {
                    const last = await this.getLastRoute(CurrentUser.userId);
                    if (last != null) {
                        url = Url.getURLfromString(last);
                    }
                }
            }
            catch (err) {
                console.warn('cant get last url in web', err);
            }
        }
        else if (Linking != null && typeof Linking.getInitialURL === 'function') {
            try {
                const initU = await Linking.getInitialURL();
                if (initU != null) {
                    url = Url.getURLfromString(initU);
                }
                if (url == null || url.toString() === '///') {
                    const last = await this.getLastRoute(CurrentUser.userId);
                    if (last != null) {
                        url = Url.getURLfromString(last);
                    }
                }
            }
            catch (err) {
                if (err) {
                    ErrorReporter.sendReport({ subject: 'An error occurred initLinking', data: err, type: 'warn' });
                }
            }
        }
        return url;
    }
    async localeUses24HourTime(locale) {
        try {
            const l = new Intl.DateTimeFormat(locale, {
                hour: 'numeric',
            })
                .formatToParts(new Date(2020, 0, 1, 13))
                .find((part) => part.type === 'hour');
            if (l != null)
                return l.value.length === 2;
        }
        catch (e) {
            console.debug('cant get 24 hour format', e);
        }
        return true;
    }
    async onStart() {
        this.starting = true;
        await ConfigAll.init();
        try {
            await SecureLocalStoreB.instance.removeItem(`t_${Url.getURLfromString(Config.b2cURL).host}`);
        }
        catch (e) {
            console.warn('App.onStart cant delete token');
        }
        if (Platform.OS !== 'web') {
            if (ConfigAll.getEnvironment() === 'development' && ConfigAll.getHost().length > 0) {
                ErrorReporter.setENVIRONMENTExtra(ConfigAll.getHost());
            }
            ErrorReporter.ENVIRONMENT = ConfigAll.getEnvironment();
        }
        try {
            const apiOnline = await fetch(`${ConfigAll.b2cURL}/health`);
            const state = await apiOnline.text();
            NetworkStatus.online = state === 'started';
        }
        catch (e) {
            NetworkStatus.online = false;
        }
        const is24Hour = await this.localeUses24HourTime();
        I18n.m = new I18nClient(new MessagesDe(), new I18nOptions({
            cache: new LanguageAsyncCache('trans'),
            backendUrl: `${ConfigAll.b2cURL}/backend/translations`,
            component: 'client',
            hourFormat24: is24Hour,
        }));
        const lastLocale = SimpleStorage.get('locale');
        if (lastLocale == null) {
            const l = LocaleDetector.detect();
            SimpleStorage.set('locale', l);
            await I18n.m.init(l);
        }
        else {
            await I18n.m.init(lastLocale);
        }
        ThemeI18n.m = I18n.m;
        I18Core.m = I18n.m;
        AuthClient.onStartLogin.attach(() => {
            this.startLogin().catch((err) => console.error(err));
        });
        AuthClient.onLogin(this.onLogin);
        await DeviceManager.loadDeviceId();
        await DownloadCache.instance.start();
        if (Platform.OS === 'web') {
            await Init.initChannelHandling();
        }
        try {
            await ClientDB.instance.init();
        }
        catch (e) {
            console.debug('Cant init Client DB', e);
            throw e;
        }
        this.startUrl = await this.initLinking();
        await WaitFor.instance.waitFor(() => UpmeshClient.instance.serverConnection != null);
        console.debug('onStart app called');
        const startUrl = await PushNotificationsSetup.init();
        if (Platform.OS !== 'web') {
            if (startUrl != null) {
                this.startUrl = startUrl;
            }
        }
        this.setState({ started: true, env: ConfigAll.getEnvironment() }, () => {
            requestAnimationFrame(() => {
                console.log('appstart ends in', (new Date().getTime() - this.startTest.getTime()) / 1000, 'seconds');
                SplashScreen.hide();
                if (this.startUrl != null) {
                    this.goToAfterStart().catch((err) => console.debug(err));
                }
            });
        });
    }
    addSharingFeature() {
        if (Platform.OS !== 'web') {
            const { started, logIn } = this.state;
            if (!logIn && started && RouterControl.isMounted && Alert.instance != null) {
                if (!this.receiveSharingIntentOnce) {
                    this.receiveSharingIntentOnce = true;
                    ReceiveSharingIntent.getReceivedFiles(this.gotSharedFiles, (error) => {
                        console.warn('cant get ReceiveSharingIntent.getReceivedFiles', error);
                    }, 'com.upmesh');
                }
            }
            else {
                setTimeout(() => this.addSharingFeature(), 200);
            }
        }
        else if (this.dropKeyDefault == null) {
            this.dropKeyDefault = FileDropHandler.addTarget({
                onGotFile: this.onGotDefaultFiles,
                defaultHandler: true,
                text: 'Drop some files here',
                filter: ['allFiles'],
                multiHandlerDialogText: 'add some files',
                multiFiles: true,
            });
        }
    }
    async showWhatsNew() {
        const lastVersion = SimpleStorage.get(`lastVersion${CurrentUser.userId}`);
        if (lastVersion == null || lastVersion !== ConfigAll.getVersion()) {
            try {
                await new Promise((resolve) => {
                    setTimeout(resolve, 2000);
                });
                const version = ConfigAll.getVersion().split('@')[0];
                let headers = {};
                if (CurrentUser.entity != null)
                    headers = { 'accept-language': CurrentUser.entity.locale };
                const result = await fetch(`${ConfigAll.b2cURL}/releasenotes/${version}`, { headers });
                const json = await result.json();
                const releaseNotes = { ...json };
                if (releaseNotes != null &&
                    ((releaseNotes.features != null && releaseNotes.features.length > 0) ||
                        (releaseNotes.bugs != null && releaseNotes.bugs.length > 0))) {
                    await WaitFor.instance.waitFor(() => {
                        try {
                            Dialog.instance?.open({
                                content: <WhatsNew versionName={version} releaseNotes={releaseNotes}/>,
                                contentPadding: false,
                                scrollable: true,
                                buttons: [
                                    <ContainedButton key="close" onPress={Dialog.instance?.close} title={I18n.m.getMessage('close')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent"/>,
                                ],
                            });
                            SimpleStorage.set(`lastVersion${CurrentUser.userId}`, ConfigAll.getVersion());
                            return true;
                        }
                        catch (e) {
                            return false;
                        }
                    });
                }
            }
            catch (e) {
                console.debug('cant get releasenotes', e);
            }
        }
    }
}
Bau2cloudApp.alreadyLoggedIn = () => {
    if (AuthClient.instance.serverConnection != null && CurrentUser.userId != null && CurrentUser.userId.length > 0) {
        return (_props) => (<Redirect key={uuid.v1()} path="/projects" replace>
          <View />
        </Redirect>);
    }
    return undefined;
};
Bau2cloudApp.loginNeeded = () => {
    if (CurrentUser.userId != null && CurrentUser.userId.length > 0) {
        return undefined;
    }
    console.debug('LOGIN NEEDED!!!!', CurrentUser);
    return (_props) => (<Redirect key={uuid.v1()} path="/login" replace>
        <View />
      </Redirect>);
};
