import ConnectionContext from 'materialTheme/src/connectionContext';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ContentHeaderEventHandler, } from 'materialTheme/src/theme/components/header/ContentHeaderEventHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ImageEditor } from 'materialTheme/src/theme/components/lightbox/ImageEditor';
import { LightBoxHandler } from 'materialTheme/src/theme/components/lightbox/LightBoxHandler';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { ProOnlyDialogContent } from 'materialTheme/src/theme/components/ProOnlyDialogContent';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { CommandReadModels } from 'upmesh-auth-core/src/server/webserver/commands/CommandReadModels';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeFolder } from 'upmesh-core/src/client/commands/folder/ChangeFolder';
import { DeleteFolder } from 'upmesh-core/src/client/commands/folder/DeleteFolder';
import { ChangeStoredFileComment } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFileComment';
import { ChangeStoredFilename, } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFilename';
import { ChangeStoredFileType } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFileType';
import { CopyStoredFile } from 'upmesh-core/src/client/commands/storedfile/CopyStoredFile';
import { RemoveStoredFile } from 'upmesh-core/src/client/commands/storedfile/RemoveStoredFile';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { JournalProtocolEntity } from 'upmesh-core/src/client/query/entities/JournalProtocolEntity';
import { StoredFileFilter } from 'upmesh-core/src/client/query/filter/StoredFileFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { PageView } from '../root/PageView';
import { AddFilesPU } from './AddFilesPU';
import { AddFileTypeAndCommentDialog } from './AddFileTypeAndCommentDialog';
import { ChangeFolderDialog } from './ChangeFolderDialog';
import { EditedFileSaver } from './EditedFileSaver';
import { FilesFilterDialog } from './FilesFilterDialog';
import { FilesViewFolders } from './FilesViewFolders';
import { FilesViewTable } from './FilesViewTable';
import { FilesViewThumbs } from './FilesViewThumbs';
import { SharedFiles } from './SharedFiles';
import { StoredFileDownloader } from './StoredFileDownloader';
import { StoredFileDownloaderOptions } from './StoredFileDownloaderOptions';
const pfdEditEnterpriseOnlyImage = require('../../assets/img/emptyStatePDFEdit.png');
export class FilesView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.handleFileChange = (_e) => {
            const { folder, searchWords } = this.state;
            const documents = FilesView.createDocs(folder, searchWords);
            this.setState({ documents });
        };
        this.onFolderChange = (en) => {
            const { projectId } = this.props;
            en.entities.forEach((e) => {
                if (e.entity != null && e.entity.projectId === projectId) {
                    this.loadFolders().catch((err) => console.debug(err));
                }
            });
        };
        this.selectAll = () => {
            const { selectedIDs, documents } = this.state;
            const selected = new Set();
            if (documents != null && selectedIDs.size < documents.length) {
                documents.forEach((document) => {
                    selected.add(document.id);
                });
            }
            this.setState({ selectedIDs: selected }, this.openMultiselectHeader);
        };
        this.deleteFolder = (folderId) => {
            MenuRaw.instance?.close();
            const c = new DeleteFolder({}, folderId);
            c.canI()
                .then(() => Routing.instance.alert.post({
                text: I18n.m.getMessage('folderDeleteQuestion'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.deleteFolderNow(folderId)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.deleteFolderNow = (folderId) => (_e) => {
            Alert.instance?.close();
            LoadingEvents.instance.startLoading();
            const deleteFolder = new DeleteFolder({}, folderId);
            deleteFolder
                .execute()
                .then(() => LoadingEvents.instance.stopLoading())
                .catch((err) => {
                LoadingEvents.instance.stopLoading();
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        };
        this.onChangeMultiSelectStatus = (status) => {
            Menu.instance?.close();
            this.setState({ activeMultiselect: status }, () => {
                if (status) {
                    this.openMultiselectHeader();
                }
            });
        };
        this.closeMultiselectHeader = () => {
            this.setState({
                selectedIDs: new Set(),
                activeMultiselect: false,
            });
            const data = {
                open: false,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
            this.onChangeMultiSelectStatus(false);
        };
        this.moveFiles = (e) => {
            const { folder, selectedIDs, documents } = this.state;
            const currentFolder = folder == null ? 'public' : folder.toString();
            const item = documents ? documents.find((doc) => doc.id === selectedIDs[0]) : undefined;
            const excludeFolder = [
                item == null || item.folder == null || item.folder === '' ? 'private' : item.folder,
            ];
            FolderEntity.hasFolderDeleteRights(currentFolder, CurrentUser.userId)
                .then(() => {
                if (e.persist != null && typeof e.persist === 'function') {
                    e.persist();
                }
                SharedFiles.fileIds = Array.from(selectedIDs);
                SharedFiles.excludeFolders = excludeFolder;
                AddFilesPU.openDialog({
                    projectId: this.props.projectId,
                    dontChangeProject: true,
                })(null);
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.openMultiselectHeader = () => {
            const { selectedIDs, folder } = this.state;
            const globalbar = ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
            const headerHeight = globalbar
                ? ThemeManager.style.headerHeight + ThemeManager.style.getScreenRelativePixelSize(48)
                : ThemeManager.style.headerHeight;
            const headerContent = (<View style={{
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingLeft: ThemeManager.style.contentPaddingValue,
                    paddingRight: ThemeManager.style.contentPaddingValue,
                    width: '100%',
                    alignItems: 'center',
                }}>
        <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          <Icon toolTip={I18n.m.getMessage('close')} icon="close" color={ThemeManager.style.brandPrimary} onPress={this.closeMultiselectHeader}/>
          <MaterialText color={ThemeManager.style.brandPrimary} centeredText centeredBox>
            {selectedIDs.size}
          </MaterialText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {folder !== 'tickets' ? (<Icon icon="file-move-outline" toolTip={I18n.m.getMessage('moveFile')} color={ThemeManager.style.brandPrimary} onPress={this.moveFiles} disabled={selectedIDs.size === 0}/>) : (<View />)}
          <Icon icon="download-outline" toolTip={I18n.m.getMessage('download')} color={ThemeManager.style.brandPrimary} onPress={this.downloadMultiSelect} disabled={selectedIDs.size === 0}/>
          {folder !== 'tickets' ? (<Icon icon="delete-outline" toolTip={I18n.m.getMessage('delete')} color={ThemeManager.style.brandPrimary} onPress={this.openDeleteConfirmationDialog} disabled={selectedIDs.size === 0}/>) : (<View />)}
        </View>
      </View>);
            const data = {
                headerContent,
                open: true,
                headerHeight,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
        };
        this.downloadMultiSelect = () => {
            const files = this.state.selectedIDs;
            this.getFilesDownloadToken(Array.from(files))
                .then((token) => {
                const link = `${UpmeshClient.instance.url}/storedfile/archive/${token}`;
                return StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                    link,
                    orgFilename: 'files.zip',
                }));
            })
                .catch((e) => {
                console.error('cant download folder', e);
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
            });
        };
        this.getFilesDownloadToken = async (fileIDs) => {
            const url = `${UpmeshClient.instance.url}/storedfile/archive/`;
            const headers = {};
            headers['Accept'] = 'text/plain';
            headers['Content-Type'] = 'application/json';
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            const result = await fetch(url, { headers, method: 'POST', body: JSON.stringify({ selectedFileIDs: fileIDs }) });
            if (result.status === 200) {
                return await result.text();
            }
            return '';
        };
        this.openDeleteConfirmationDialog = () => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('filesDeleteQuestion'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.deleteNow()} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.deleteNow = () => (_e) => {
            Alert.instance?.close();
            LoadingEvents.instance.startLoading();
            const { selectedIDs, documents } = this.state;
            const asyncNow = async () => {
                if (documents != null) {
                    const idsToDelete = new Set();
                    for (const id of selectedIDs) {
                        const fileIndex = documents.findIndex((a) => a.id === id);
                        if (fileIndex > -1) {
                            const file = documents[fileIndex];
                            idsToDelete.add(file.id);
                            if (file.versions != null && file.versions.length > 0) {
                                file.versions.forEach((i) => idsToDelete.add(i.id));
                            }
                        }
                    }
                    for (const id of idsToDelete) {
                        const rm = new RemoveStoredFile({}, id);
                        try {
                            await rm.execute();
                        }
                        catch (e) {
                            DefaultErrorHandler.showDefaultErrorAlert(e);
                        }
                    }
                }
                this.closeMultiselectHeader();
                LoadingEvents.instance.stopLoading();
            };
            asyncNow().catch((err) => console.error('cant download folder', err));
        };
        this.breakViewWidth = 756;
        this.startMoveOnX = null;
        this.startTreeWidth = null;
        this.onResponderGrant = (e) => {
            if (e != null && e.nativeEvent != null && e.nativeEvent.touches != null) {
                this.startMoveOnX = e.nativeEvent.touches[0].pageX;
                this.startTreeWidth = this.state.folderTreeWidth;
            }
        };
        this.onResponderEnd = (_e) => {
            this.startMoveOnX = null;
            this.startTreeWidth = null;
            const { folderTreeWidth } = this.state;
            SimpleStorage.set('filesFolderWidth', folderTreeWidth.toString());
        };
        this.onTouchMove = (e) => {
            const { startMoveOnX, startTreeWidth } = this;
            if (e != null &&
                e.nativeEvent != null &&
                e.nativeEvent.touches != null &&
                startMoveOnX != null &&
                startTreeWidth != null) {
                const diff = e.nativeEvent.touches[0].pageX - startMoveOnX;
                let folderTreeWidth = startTreeWidth + diff;
                folderTreeWidth = Math.min(folderTreeWidth, ResizeEvent.current.contentWidth / 2);
                folderTreeWidth = Math.max(folderTreeWidth, 200);
                if (folderTreeWidth !== this.state.folderTreeWidth) {
                    this.setState({ folderTreeWidth });
                }
            }
        };
        this.onSearch = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.setState({ searchWords: text }, () => {
                this.searchTimer = window.setTimeout(this.searchNow(text), 250);
            });
        };
        this.searchNow = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNow(text), 100);
                return;
            }
            this.searching = true;
            this.setState({ searchWords: text }, () => {
                this.searching = false;
            });
            Routing.instance.changeQueryParameter({ q: text });
        };
        this.openFilter = () => {
            if (FilesView.currentDocs != null) {
                FilesFilterDialog.open(FilesView.currentDocs, this.setFilter, FilesView.filter);
            }
        };
        this.setFilter = (filter) => {
            const { searchWords, folder } = this.state;
            FilesView.filter = filter;
            this.setState({ documents: FilesView.createDocs(folder, searchWords) });
        };
        this.editFile = (file) => async (_e) => {
            Menu.instance?.close();
            if (file.mimeType.startsWith('image')) {
                const { folder, hasFolderEditRights } = this.state;
                try {
                    if (folder != null && folder !== 'public' && folder !== 'private') {
                        await FolderEntity.hasFolderWriteRights(folder, CurrentUser.userId);
                    }
                    const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${file.id}?lm=${file.lastModifiedAt}`;
                    ImageEditor.openImageEditor(targetUrl, file.orgFilename, EditedFileSaver.saveEditedImage(file, hasFolderEditRights), true).catch((err) => console.debug('cant edit file', err));
                }
                catch (err) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
                }
            }
            else if (file.mimeType.includes('pdf')) {
                this.downloadFile(true)(file)(null);
            }
        };
        this.downloadFolder = (item) => async (_e) => {
            Menu.instance?.close();
            if (item['hiddenData'] != null) {
                const folder = new FolderEntity(item['hiddenData']);
                const token = await this.createAndDownloadZip(folder.id);
                const link = `${UpmeshClient.instance.url}/storedfile/archive/${token}`;
                StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                    link,
                    orgFilename: 'blabla.zip',
                })).catch((e) => {
                    console.error('cant download folder', link, e);
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                    throw e;
                });
            }
        };
        this.editFolder = (item) => (_e) => {
            Menu.instance?.close();
            if (item['hiddenData'] != null) {
                const folder = new FolderEntity(item['hiddenData']);
                const c = new ChangeFolder({
                    title: folder.title,
                    access: folder.access,
                    tags: folder.tags,
                    description: folder.description,
                }, folder.id);
                c.canI()
                    .then(() => ChangeFolderDialog.openDialog({ folder }))
                    .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
            }
        };
        this.createAndDownloadZip = async (folderId) => {
            MenuRaw.instance?.close();
            const url = `${UpmeshClient.instance.url}/storedfile/archive/${folderId}`;
            const headers = {};
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            const result = await fetch(url, { headers, method: 'POST', body: '' });
            if (result.status === 200) {
                return await result.text();
            }
            return '';
        };
        this.downloadFile = (view, localFile) => (item) => (_e) => {
            Menu.instance?.close();
            if (item.mimeType === 'folder') {
                this.goToFolder(item.id)(_e);
            }
            else if (item.forEntity === 'Journal') {
                try {
                    const data = JSON.parse(item.metaData);
                    const j = new JournalProtocolEntity(data.protocol);
                    CurrentProject.instance
                        .createJournalProtocolLink(j)
                        .then((link) => {
                        if (link != null) {
                            let orgFilename = j.fileId;
                            if (j.fileName != null) {
                                orgFilename = `${j.fileName}.pdf`;
                            }
                            StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                                link,
                                orgFilename,
                            }, view)).catch((e) => {
                                console.error('cant download', link, e);
                                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                                throw e;
                            });
                        }
                    })
                        .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                }
            }
            else {
                const options = new StoredFileDownloaderOptions({ id: item.id, orgFilename: item.orgFilename }, view, localFile);
                const project = CurrentProject.instance?.getCurrentProject();
                if (project != null &&
                    project.projectSubscription === 'enterprise' &&
                    (item.mimeType.includes('pdf') || item.orgFilename.includes('pdf'))) {
                    options.localFile = OfflineDataDownloader.isMediaSynced(item.projectId, item.id, item.getFileId());
                    options.storedfile = item;
                    const { hasFolderEditRights } = this.state;
                    options.editable = hasFolderEditRights;
                }
                StoredFileDownloader.downloadFile(options).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.goToFolder = (folderId) => (_e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const { projectId } = this.props;
            Routing.instance.goTo(`/projects/${projectId}/files/${folderId}`);
        };
        this.changeView = (index, _button) => {
            const { selectedView } = this.state;
            const newView = index === 1 ? 'table' : 'thumbs';
            if (selectedView !== newView) {
                this.toggleSelectedView();
            }
        };
        this.toggleSelectedView = () => {
            this.setState((prevState) => {
                const newView = prevState.selectedView === 'table' ? 'thumbs' : 'table';
                SimpleStorage.set('filesView', newView);
                return { selectedView: newView };
            });
        };
        this.hideHeader = async () => {
            const data = {
                open: false,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
            this.setState({ selectable: false });
        };
        this.onMultiSelect = (selectedIDs) => {
            this.setState({ selectedIDs, activeMultiselect: true }, () => {
                if (selectedIDs.size === 0)
                    this.closeMultiselectHeader();
                else
                    this.openMultiselectHeader();
            });
        };
        this.deleteFile = (item) => async (_e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const c = new RemoveStoredFile({}, item.id);
            c.canI()
                .then(() => {
                if (item.versions?.length > 1) {
                    const versionIds = [item.id];
                    item.versions.forEach((v) => versionIds.push(v.id));
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage('filesDeleteQuestionAllVersions'),
                        buttons: [
                            <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                            <ContainedButton key="yes" title={I18n.m.getMessage('deleteVersion')} onPress={this.deleteFilesNow([item.id])} backgroundColor={ThemeManager.style.brandDanger}/>,
                            <ContainedButton key="yesAll" title={I18n.m.getMessage('deleteAll')} onPress={this.deleteFilesNow(versionIds)} backgroundColor={ThemeManager.style.brandDanger}/>,
                        ],
                    });
                }
                else {
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage('filesDeleteQuestion'),
                        buttons: [
                            <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                            <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.deleteFilesNow([item.id])} backgroundColor={ThemeManager.style.brandDanger}/>,
                        ],
                    });
                }
            })
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.deleteFilesNow = (ids) => (_e) => {
            Alert.instance?.close();
            LoadingEvents.instance.startLoading();
            const promises = [];
            ids.forEach((id) => {
                promises.push(new Promise((r) => {
                    const rm = new RemoveStoredFile({}, id);
                    rm.execute()
                        .then(() => {
                        r();
                    })
                        .catch((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                        r();
                    });
                }));
            });
            Promise.all(promises)
                .then(() => {
                LoadingEvents.instance.stopLoading();
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
                LoadingEvents.instance.stopLoading();
            });
        };
        this.openTicket = (item) => (e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            if (e.persist != null && typeof e.persist === 'function') {
                e.persist();
            }
            Routing.instance.openDialog('ticket', { id: item.forEntityId })(e);
        };
        this.moveFile = (fileIds, excludeFolders) => (_item) => (e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const { folder } = this.state;
            const currentFolder = folder == null ? 'public' : folder.toString();
            if (folder === 'reports') {
                SharedFiles.fileIds = fileIds;
                SharedFiles.excludeFolders = excludeFolders;
                AddFilesPU.openDialog({
                    projectId: this.props.projectId,
                    dontChangeProject: true,
                    copyFile: true,
                    source: 'Journal',
                })(null);
            }
            else {
                FolderEntity.hasFolderDeleteRights(currentFolder, CurrentUser.userId)
                    .then(() => {
                    if (e.persist != null && typeof e.persist === 'function') {
                        e.persist();
                    }
                    SharedFiles.fileIds = fileIds;
                    SharedFiles.excludeFolders = excludeFolders;
                    AddFilesPU.openDialog({
                        projectId: this.props.projectId,
                        dontChangeProject: true,
                        copyFile: false,
                    })(null);
                })
                    .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.changeFileName = (item) => async (_e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const cmd = new ChangeStoredFilename({ orgFilename: item.orgFilename }, item.id);
            cmd
                .canI()
                .then(() => {
                const dataOptions = new Map();
                let { orgFilename } = item;
                let ext;
                if (item.orgFilename.lastIndexOf('.') >= 0) {
                    orgFilename = item.orgFilename.substr(0, item.orgFilename.lastIndexOf('.'));
                    ext = item.orgFilename.substr(item.orgFilename.lastIndexOf('.'));
                }
                dataOptions.set('orgFilename', {
                    formType: 'string',
                    cols: 1,
                    props: { labelText: I18n.m.getMessage('filesRenameNewFileName') },
                });
                const currentData = {
                    orgFilename,
                    ext,
                };
                const content = (<Form key="ChangeStoredFileNameForm" formHeaderProps={{ formTitle: I18n.m.getMessage('filesRenameHeader') }} stickyActions={false} command={new ChangeStoredFilename(currentData, item.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={(_result) => {
                        Dialog.instance?.close();
                    }} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[
                        <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
                    ]}/>);
                Dialog.instance?.open({
                    closeOnTouchOutside: true,
                    content,
                    height: 0,
                    contentPadding: false,
                });
            })
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.changeFileType = (file) => (_e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const { fileTypes } = this.state;
            const changeCommentCmd = new ChangeStoredFileComment({ comment: '' }, file.id, CurrentUser.token);
            const changeTypeCmd = new ChangeStoredFileType({ type: '' }, file.id, CurrentUser.token);
            Promise.race([changeCommentCmd.canI(), changeTypeCmd.canI()])
                .then(() => Dialog.instance?.open({
                closeOnTouchOutside: true,
                content: <AddFileTypeAndCommentDialog fileTypes={fileTypes} fileId={file.id}/>,
                height: 0,
                contentPadding: false,
            }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.copyFile = (item) => async (_e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const copyFileCommand = new CopyStoredFile({ forTicket: false }, item.id, CurrentUser.token);
            try {
                Toast.instance?.open({
                    title: I18n.m.getMessage('fileIsCopying', { file: item.orgFilename }),
                    onPressButton: Toast.instance?.close,
                    buttonTitle: I18n.m.getMessage('close'),
                });
                await copyFileCommand.execute().then(() => Toast.instance?.close());
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                console.error('cant copy file:', e);
            }
        };
        this.openVersionsMenu = (file) => (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const { connectedToServer } = this.context;
                const journalIcon = file.forEntity === 'Journal' && file.forEntityId != null && file.forEntityId.length > 0;
                const localFile = OfflineDataDownloader.isMediaSynced(file.projectId, file.id, file.getFileId());
                const editFileTypes = file.orgFilename.includes('pdf') ||
                    file.mimeType.includes('pdf') ||
                    file.mimeType.includes('png') ||
                    file.mimeType.includes('jpeg') ||
                    file.mimeType.includes('jpg');
                const editDisabled = !editFileTypes ||
                    (!localFile && !connectedToServer) ||
                    (file.forEntityId != null && file.forEntityId.length > 0);
                const project = CurrentProject.instance?.getCurrentProject();
                let canEditForPdf = false;
                if (!(file.mimeType.includes('pdf') || file.orgFilename.includes('pdf')))
                    canEditForPdf = true;
                else if (project != null && project.projectSubscription === 'enterprise')
                    canEditForPdf = true;
                const menuElements = [
                    {
                        thumbnail: {
                            thumbnail: (<Icon toolTip={I18n.m.getMessage('download')} icon="download-outline" disabled={!(connectedToServer || localFile != null)}/>),
                            width: 24,
                        },
                        disabled: !(connectedToServer || localFile != null),
                        onPress: this.downloadFile(false, localFile)(file),
                        text: I18n.m.getMessage('download'),
                    },
                    {
                        thumbnail: {
                            thumbnail: <Icon toolTip={I18n.m.getMessage('filesChangeCommentTypeTooltip')} icon="comment-outline"/>,
                            width: 24,
                        },
                        disabled: journalIcon,
                        onPress: (e) => this.changeFileType(file)(e),
                        text: I18n.m.getMessage('filesChangeCommentTypeTooltip'),
                    },
                    {
                        thumbnail: {
                            thumbnail: (<Icon toolTip={I18n.m.getMessage('delete')} icon="delete-outline" disabled={!connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0)}/>),
                            width: 24,
                        },
                        disabled: !connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0),
                        onPress: (e) => {
                            this.deleteFile(file)(e).catch((err) => console.error(err));
                        },
                        text: I18n.m.getMessage('delete'),
                    },
                ];
                if (file.forEntityId == null || file.forEntityId.length === 0) {
                    menuElements.unshift({
                        thumbnail: {
                            thumbnail: <Icon toolTip={I18n.m.getMessage('edit')} icon="pencil-outline" disabled={editDisabled}/>,
                            width: 24,
                        },
                        disabled: editDisabled,
                        onPress: (e) => {
                            if (canEditForPdf) {
                                this.editFile(file)(e).catch((err) => console.error(err));
                            }
                            else {
                                this.showEnterpriseOnlyInfo(e)(file).catch((err) => console.error(err));
                            }
                        },
                        text: I18n.m.getMessage('edit'),
                    }, {
                        thumbnail: {
                            thumbnail: (<Icon toolTip={I18n.m.getMessage('storedFileCopyText')} icon="content-copy" disabled={file.forEntityId != null && file.forEntityId.length > 0}/>),
                            width: 24,
                        },
                        disabled: file.forEntityId != null && file.forEntityId.length > 0,
                        onPress: (e) => {
                            this.copyFile(file)(e).catch((err) => console.error(err));
                        },
                        text: I18n.m.getMessage('storedFileCopyText'),
                    });
                }
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                Menu.instance?.open({ items: menuElements, client });
            });
        };
        this.openImage = (file) => (_e) => {
            LightBoxHandler.open('thumb', file.id);
        };
        this.showVersionsList = (file) => (_e) => {
            this.showVersionsListNow(file).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.showVersionsListNow = async (file) => {
            Menu.instance?.close();
            const imageSize = { height: 40, width: 40 };
            const listItems = [];
            let versions = await UpmeshClient.instance.modals.storedFile.get({
                filter: `projectId eq '${file.projectId}' and orgFilename eq '${file.orgFilename}' and deleted eq true and folder eq ${file.folder != null ? `'${file.folder}'` : 'null'} and forEntityId eq ${file.forEntityId != null ? `'${file.forEntityId}'` : 'null'}`,
            });
            versions = versions.concat(file.versions);
            if (versions) {
                versions.sort((a, b) => {
                    if (a.createdAt >= b.createdAt)
                        return -1;
                    return 1;
                });
                for (const v of versions) {
                    let f = v;
                    const user = await CommandReadModels.user.getById(v.createdBy);
                    if (user != null) {
                        v['uploadedByUsername'] = user.getFullName();
                        v['uploadedByUser'] = user;
                        if (v.deleted && v.lastModifiedBy != null) {
                            const dUser = await CommandReadModels.user.getById(v.lastModifiedBy);
                            if (dUser != null)
                                v['deletedByUsername'] = dUser.getFullName();
                        }
                        f = v;
                    }
                    if (f != null) {
                        const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${file.id}?lm=${file.lastModifiedAt}`;
                        const localFile = OfflineDataDownloader.isMediaSynced(file.projectId, file.id, file.getFileId());
                        let panoramaImage = false;
                        if (file.mimeType.startsWith('image') &&
                            file.metaData != null &&
                            !Number.isNaN(file.metaData['width']) &&
                            !Number.isNaN(file.metaData['height']) &&
                            file.metaData['width'] / file.metaData['height'] === 2) {
                            panoramaImage = true;
                        }
                        const thumb = UploadTypes.getImageForFileName({ targetUrl, name: file.orgFilename, preview: localFile }, undefined, undefined, {
                            id: file.id,
                            metaData: file.imageMetadata,
                        }, panoramaImage, imageSize.width, imageSize.height);
                        const onPress = f.fileType === 'image' ? this.openImage(f) : this.downloadFile(true, localFile)(f);
                        listItems.push(<ListItem thumbnail={{ thumbnail: thumb, width: 40 }} key={`li${f.fileId}`} title={f.orgFilename} secondTextLine={`${I18n.m.dateCurrent.localeDateWithTimeString(f.createdAt)} - ${f.uploadedByUsername}`} iconRight={{
                                icon: f.deleted ? 'delete-outline' : 'dots-vertical',
                                onPress: (e) => this.openVersionsMenu(f)(e),
                                disabled: f.deleted,
                            }} onPress={f.deleted ? undefined : onPress} textColor={f.deleted ? ThemeManager.style.brandDanger : undefined} thirdTextLine={f.deleted
                                ? `${I18n.m.getMessage('filesDeleted')}: ${I18n.m.dateCurrent.localeDateWithTimeString(f.lastModifiedAt)}${f.deletedByUsername && f.deletedByUsername.length > 0 ? ` - ${f.deletedByUsername}` : ''}`
                                : undefined}/>);
                    }
                }
            }
            Dialog.instance?.open({
                contentPadding: false,
                content: (<View>
          <DialogTitle>{I18n.m.getMessage('filesVersionsManage')}</DialogTitle>
          <DialogContent>{listItems}</DialogContent>
        </View>),
            });
        };
        this.showEnterpriseOnlyInfo = (_file) => async (_e) => {
            Menu.instance?.close();
            Dialog.instance?.close();
            const { projectId } = this.props;
            const project = await UpmeshClient.instance.modals.project.getById(projectId);
            if (project.creator !== CurrentUser.userId) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('askOwnerForUpgradeProject'),
                });
                return;
            }
            const acc = await RightsManager.hasVersion(CurrentUser.userId);
            if (acc === 'enterprise') {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('upgradeProjectQuestion'),
                    buttons: [
                        <ContainedButton key="abort" title={I18n.m.getMessage('abort')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                        <ContainedButton key="dashboard" title={I18n.m.getMessage('menuProject')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={() => {
                                Alert.instance?.close(() => {
                                    requestAnimationFrame(() => {
                                        Routing.instance.goTo(`projects/${projectId}/project`);
                                    });
                                });
                            }}/>,
                    ],
                });
                return;
            }
            Dialog.instance?.open({
                contentPadding: false,
                content: (<ProOnlyDialogContent headline={I18n.m.getMessage('filesEditPdfEnterpriseFunctionHeadline')} description={I18n.m.getMessage('filesEditPdfEnterpriseFunctionDescription')} buttonText={I18n.m.getMessage('projectDownloadProFunctionButton')} imagePosition="below" buttonFunction={Dialog.instance?.close} imageSource={pfdEditEnterpriseOnlyImage}/>),
            });
        };
        try {
            FilesView.filter = props.ff != null ? JSON.parse(props.ff) : new StoredFileFilter();
        }
        catch (e) {
            FilesView.filter = new StoredFileFilter();
            console.debug('cant parse filefilter');
        }
        this.state = {
            searchWords: props.q != null ? props.q : '',
            documents: undefined,
            selectable: false,
            folder: null,
            fileTypes: [],
            folders: [],
            folderTreeWidth: 312,
            init: false,
            activeMultiselect: false,
            selectedIDs: new Set(),
            selectedView: 'table',
            hasFolderEditRights: false,
        };
    }
    componentDidMount() {
        CurrentProject.storedFilesChanged.attach(this.handleFileChange);
        this.folderAttachkey = UpmeshClient.eventDispatcher.attach({
            readModelName: 'Folder',
            callback: this.onFolderChange,
        });
        const project = CurrentProject.instance.getCurrentProject();
        if (project != null && project.fileTypes != null) {
            this.setState({ fileTypes: project.fileTypes });
        }
        this.loadFolders().catch((err) => console.debug(err));
        this.loadView().catch((err) => console.debug(err));
        this.mounted = true;
    }
    async loadView() {
        const viewStorage = SimpleStorage.get('filesView');
        this.setState({ selectedView: viewStorage === 'thumbs' ? 'thumbs' : 'table' });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.folder !== this.props.folder) {
            this.loadFolders().catch((err) => console.debug(err));
        }
    }
    async loadFolders() {
        const { projectId } = this.props;
        const { folder } = this.state;
        const filesFolderWidth = SimpleStorage.get('filesFolderWidth');
        let folderTreeWidth = filesFolderWidth == null ? 312 : Number.parseInt(filesFolderWidth, 10);
        folderTreeWidth = Math.min(folderTreeWidth, ResizeEvent.current.contentWidth / 2);
        folderTreeWidth = Math.max(folderTreeWidth, 200);
        const folders = await UpmeshClient.instance.modals.folder.get({
            filter: `projectId eq '${projectId}' and deleted ne true`,
            orderby: 'title ASC',
        });
        let hasFolderEditRights = false;
        if (folder === 'private' || folder === 'public') {
            hasFolderEditRights = true;
        }
        else {
            const f = folders.find((f) => f.id === folder);
            if (f != null) {
                try {
                    hasFolderEditRights = await FolderEntity.hasFolderEditRights(f.id, CurrentUser.userId);
                }
                catch (e) {
                    hasFolderEditRights = false;
                }
            }
        }
        this.setState({ folders, folderTreeWidth, init: true, hasFolderEditRights });
    }
    componentWillUnmount() {
        this.closeMultiselectHeader();
        if (this.folderAttachkey != null) {
            UpmeshClient.eventDispatcher.detach('Folder', this.folderAttachkey);
        }
        ContentHeaderEventHandler.statusEvent.post({ open: false });
        CurrentProject.storedFilesChanged.detach(this.handleFileChange);
        this.mounted = false;
        this.hideHeader().catch((err) => console.debug(err));
    }
    renderFolder(width) {
        const { projectId } = this.props;
        const { folder, folders, folderTreeWidth } = this.state;
        return (<FilesViewFolders deleteFolder={this.deleteFolder} projectId={projectId} folders={folders} folder={folder == null && width === folderTreeWidth ? 'public' : folder} width={width}/>);
    }
    renderView() {
        const { folder, init, folderTreeWidth } = this.state;
        if (!init) {
            return <Spinner />;
        }
        const { size } = this.props;
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const height = size.contentHeight - ThemeManager.style.headerHeight - sViewHeight;
        if (size.contentWidth < this.breakViewWidth) {
            if (folder == null) {
                return <View style={{ width: '100%', height }}>{this.renderFolder('100%')}</View>;
            }
            return this.renderFiles();
        }
        return (<View style={{ flexDirection: 'row', width: '100%', height, position: 'relative' }}>
        {this.renderFolder(folderTreeWidth)}
        <View style={{ width: ThemeManager.style.getScreenRelativePixelSize(size.contentWidth - folderTreeWidth) }}>
          {this.renderFiles()}
        </View>
        <View style={{
                position: 'absolute',
                bottom: 4,
                left: folderTreeWidth - 52,
                width: 36,
                height: 36,
            }} onResponderMove={this.onTouchMove} onMoveShouldSetResponderCapture={() => true} onResponderGrant={this.onResponderGrant} onResponderEnd={this.onResponderEnd}>
          <Icon borderStyle="solid" backgroundColor={ThemeManager.style.appBgColor} icon="arrow-left-right" toolTip=""/>
        </View>
      </View>);
    }
    render() {
        const { projectId, size } = this.props;
        const { folder, searchWords, selectedView } = this.state;
        let leftButtons;
        let searchBarProps;
        const multiselectEnabled = folder !== 'search' && folder !== 'reports';
        if (folder != null && ResizeEvent.current.contentWidth < this.breakViewWidth) {
            leftButtons = [
                <Icon key="back" toolTip="" icon="arrow-left" onPress={Routing.instance.goToButton(`/projects/${projectId}/files`, true)}/>,
            ];
        }
        let title = '';
        if (ResizeEvent.current.contentWidth > this.breakViewWidth || folder != null) {
            searchBarProps = {
                searchBarValue: searchWords,
                searchBarPlaceholder: I18n.m.getMessage('searchFiles'),
                searchOnChange: this.onSearch,
                tooltip: I18n.m.getMessage('searchFiles'),
            };
        }
        else {
            title = `${I18n.m.getMessage('menuProjectFiles')}`;
        }
        const isFilterActive = StoredFileFilter.isSet(FilesView.filter);
        let filterIcon = null;
        if (ResizeEvent.current.contentWidth > this.breakViewWidth || folder != null) {
            filterIcon = (<Icon key={`fileFilter_${isFilterActive}`} icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={this.openFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
            if (isFilterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
                filterIcon = (<View key={`fileFilter_big_outer_${isFilterActive}`}>
            <ContainedButton key={`fileFilter_big_${isFilterActive}`} title={I18n.m.getMessage('filterRemove')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={this.openFilter}/>
          </View>);
            }
        }
        const multiselectIcon = multiselectEnabled && !(size.contentWidth < this.breakViewWidth && folder == null) ? (<Icon key="bulkChangeIcon" toolTip={I18n.m.getMessage('bulkChangesIconTooltip')} icon="checkbox-multiple-marked-outline" onPress={() => this.onChangeMultiSelectStatus(true)}/>) : null;
        return (<PageView headerProps={{
                leftButtons,
                title,
                searchBarProps,
                rightButtons: [
                    filterIcon,
                    multiselectIcon,
                    <View key="toggleButton">
              {ResizeEvent.current.contentWidth <= ThemeManager.style.breakpointM ? (<Icon key={`showThumbOrTableButton${selectedView}`} icon={selectedView === 'table' ? 'view-grid' : 'format-list-bulleted-square'} toolTip={I18n.m.getMessage(selectedView === 'table' ? 'planDetailsViewChangeToTiles' : 'planViewChangeToListView')} onPress={this.toggleSelectedView}/>) : (<View style={{ width: 144, marginLeft: 12 }} key="viewButton">
                  <SegmentedButton buttons={[{ icon: { icon: 'view-grid' } }, { icon: { icon: 'format-list-bulleted-square' } }]} onPress={this.changeView} singleSelectSelected={selectedView === 'table' ? 1 : 0} density={2}/>
                </View>)}
            </View>,
                ],
            }} scrollable={false}>
        {this.renderView()}
      </PageView>);
    }
    static fileFilter(folder) {
        if (folder === 'public') {
            return (element, _index, _array) => (element.forEntityId == null || element.forEntityId.length === 0) && element.folder === 'public';
        }
        if (folder === 'private') {
            return (element, _index, _array) => element.userId === CurrentUser.entity.id &&
                (element.forEntityId == null || element.forEntityId.length === 0) &&
                (element.folder == null || element.folder.length === 0);
        }
        if (folder === 'tickets') {
            return (element, _index, _array) => element.forEntity === 'Ticket';
        }
        if (folder === 'reports') {
            return (element, _index, _array) => element.forEntity === 'Journal';
        }
        return (element, _index, _array) => (element.forEntityId == null || element.forEntityId.length === 0) && element.folder === folder;
    }
    static createDocs(folder, searchwords) {
        const storedFiles = CurrentProject.instance.getCurrentFiles();
        if (storedFiles != null) {
            let documents = storedFiles.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
            if (searchwords != null && searchwords.length > 0) {
                documents = StoredFileFilter.filterFilesByText(searchwords, documents);
            }
            else {
                const f = folder == null || folder === 'all' ? 'public' : folder;
                documents = documents.filter(FilesView.fileFilter(f));
            }
            FilesView.currentDocs = documents;
            if (FilesView.filter != null && StoredFileFilter.isSet(FilesView.filter)) {
                documents = StoredFileFilter.filterFiles(documents, this.filter);
            }
            const docs = [];
            documents.forEach((d) => {
                const index = docs.findIndex((doc) => doc.forEntityId == null && doc.orgFilename === d.orgFilename);
                if (index === -1) {
                    const element = d;
                    element.versions = [d];
                    docs.push(element);
                }
                else {
                    const { versions } = docs[index];
                    if (versions != null)
                        versions.push(d);
                    docs[index].versions = versions;
                }
            });
            return docs;
        }
        return undefined;
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        try {
            if (nextProps.ff !== JSON.stringify(FilesView.filter)) {
                FilesView.filter = nextProps.ff != null ? JSON.parse(nextProps.ff) : new StoredFileFilter();
            }
        }
        catch (e) {
            FilesView.filter = new StoredFileFilter();
            console.debug('cant parse filefilter');
        }
        if (nextProps.folder !== prevState.folder || nextProps.q !== prevState.searchWords) {
            const searchWords = nextProps.q != null ? nextProps.q : '';
            const documents = FilesView.createDocs(nextProps.folder, searchWords);
            if (nextProps.folder !== prevState.folder) {
                const data = {
                    open: false,
                };
                ContentHeaderEventHandler.statusEvent.post(data);
            }
            return {
                folder: searchWords.length > 0 ? 'search' : nextProps.folder,
                documents,
                searchWords,
                selectedIDs: nextProps.folder !== prevState.folder ? new Set() : prevState.selectedIDs,
                activeMultiselect: nextProps.folder !== prevState.folder ? false : prevState.activeMultiselect,
            };
        }
        return null;
    }
    renderFiles() {
        const { size, projectId } = this.props;
        const { searchWords, documents, fileTypes, folder, folders, activeMultiselect, selectedIDs, selectedView, hasFolderEditRights, folderTreeWidth, } = this.state;
        const multiselectEnabled = folder !== 'search' && folder !== 'reports';
        if (selectedView === 'table') {
            return (<FilesViewTable deleteFolder={this.deleteFolder} breakViewWidth={this.breakViewWidth} folders={folders} currentFolder={folder == null ? 'public' : folder.toString()} documents={documents} size={size} projectId={projectId} fileTypes={fileTypes} multiselectEnabled={multiselectEnabled} activeMultiselect={activeMultiselect} onMultiSelect={this.onMultiSelect} selectedIDs={selectedIDs} onMultiSelectAll={this.selectAll} hasFolderEditRights={hasFolderEditRights} editFile={this.editFile} showEnterpriseOnlyInfo={this.showEnterpriseOnlyInfo} editFolder={this.editFolder} downloadFile={this.downloadFile} downloadFolder={this.downloadFolder} goToFolder={this.goToFolder} searchWords={searchWords} showVersionsList={this.showVersionsList} openImage={this.openImage} deleteFile={this.deleteFile}/>);
        }
        return (<FilesViewThumbs deleteFolder={this.deleteFolder} breakViewWidth={this.breakViewWidth} folders={folders} currentFolder={folder == null ? 'public' : folder.toString()} documents={documents} size={size} projectId={projectId} fileTypes={fileTypes} multiselectEnabled={multiselectEnabled} activeMultiselect={activeMultiselect} onMultiSelect={this.onMultiSelect} selectedIDs={selectedIDs} hasFolderEditRights={hasFolderEditRights} editFile={this.editFile} showEnterpriseOnlyInfo={this.showEnterpriseOnlyInfo} editFolder={this.editFolder} downloadFile={this.downloadFile} downloadFolder={this.downloadFolder} goToFolder={this.goToFolder} folderTreeWidth={folderTreeWidth} showVersionsList={this.showVersionsList} deleteFile={this.deleteFile} copyFile={this.copyFile} moveFile={this.moveFile} changeFileName={this.changeFileName} changeFileType={this.changeFileType} openTicket={this.openTicket}/>);
    }
}
FilesView.contextType = ConnectionContext;
FilesView.defaultProps = {};
FilesView.filter = new StoredFileFilter();
FilesView.currentDocs = [];
