import { Card } from 'materialTheme/src/theme/components/Card';
import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { JournalEntity } from 'upmesh-core/src/client/query/entities/JournalEntity';
import { I18n } from '../../i18n/I18n';
import { numberToDate } from '../tickets/TicketCalendar/calendarUtil';
const noJournalImage = require('../../assets/img/no_journal.png');
export class JournalTableView extends PureComponent {
    constructor(props) {
        super(props);
        this.openFab = () => {
            if (Fab.instance != null) {
                const { size } = this.props;
                const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
                if (this.props.canI) {
                    Fab.instance.open({
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        onPressFab: this.openAddDialog,
                        extraPaddingBottom: sViewHeight,
                    });
                }
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.openAddDialog = () => {
            const { coloredDates } = this.props;
            const today = new Date();
            const quickSelections = [
                { name: I18n.m.getMessage('today'), days: today.getTime() },
                {
                    name: I18n.m.getMessage('yesterday'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0).getTime(),
                },
                {
                    name: I18n.m.getMessage('dayBeforeYesterday'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2, 0, 0, 0).getTime(),
                },
                {
                    name: I18n.m.getMessage('lastWeek'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0).getTime(),
                },
                {
                    name: I18n.m.getMessage('lastMonth'),
                    days: new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0).getTime(),
                },
            ];
            Datepicker.open({
                mode: 'date',
                onChange: this.onDateSelected,
                selectedDate: new Date(),
                coloredDates,
                maxDate: new Date(),
                quickSelections,
            });
        };
        this.onDateSelected = (date) => {
            if (date != null && date instanceof Date) {
                const today = new Date();
                const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 1);
                if (date.getTime() >= tomorrow.getTime()) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('journalAddDateInFutureError') });
                    this.openAddDialog();
                }
                else {
                    this.props.openEditDialog(new Date(date));
                }
            }
        };
        this.renderCheckboxes = (item, column, _index, _sLayout) => {
            let data = null;
            if (column.keyInData === 'weather' || column.keyInData === 'attendance' || column.keyInData === 'employee') {
                data = item[column.keyInData];
            }
            return (<View key={`cell_${column.keyInData}_${item.id}`} style={[column.style, { height: '100%', alignItems: 'center', justifyContent: 'center' }]}>
        <Checkbox value={data != null && data.length > 0}/>
      </View>);
        };
        this.renderVersions = (item, column, _index, _sLayout) => {
            return (<View key={`cell_${column.keyInData}_${item.id}`} style={[
                    column.style,
                    { height: '100%', alignItems: 'flex-end', justifyContent: 'center', paddingHorizontal: 16 },
                ]}>
        <View style={{ alignSelf: 'flex-end' }}>
          <Chip backgroundColor={item.versions != null && item.versions > 0
                    ? ThemeManager.style.brandSecondary
                    : ThemeManager.style.brandWarning} title={item.versions != null ? item.versions.toString(10) : '0'}/>
        </View>
      </View>);
        };
        this.renderDate = (item, column, _index, _sLayout) => {
            return (<View key={`cell_${column.keyInData}_${item.id}`} style={[column.style, { height: '100%', alignItems: 'flex-end', justifyContent: 'center' }]}>
        <View>
          <MaterialText centeredText>
            {I18n.m.dateCurrent.localeDateString(JournalEntity.getNormalizedDateShortToDate(item.forDay.toString()))}
          </MaterialText>
        </View>
      </View>);
        };
        this.state = {};
    }
    componentDidMount() {
        this.openFab();
    }
    componentWillUnmount() {
        if (Fab.instance != null) {
            Fab.instance.close();
        }
    }
    render() {
        const { size, onPressJournal, previewOrSendIsLoading, openEditDialog, canI, getAsPdf, sendCurrentDraft, journalSettings, visibleJournals, } = this.props;
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const tableMaxHeight = size.contentHeight -
            ThemeManager.style.headerHeight -
            ThemeManager.style.getScreenRelativePixelSize(48) -
            sViewHeight;
        const journalsWithAdditional = visibleJournals.map((journal) => {
            const withAdditional = { ...journal };
            journal.additionalFields?.forEach((field) => {
                withAdditional[`additionalFields_${field.id}`] = field.content;
                return withAdditional;
            });
            return withAdditional;
        });
        const columns = [
            {
                title: I18n.m.getMessage('date'),
                keyInData: 'forDay',
                style: { width: 120 },
                dataType: 'Date',
                cellRenderer: this.renderDate,
            },
            {
                title: I18n.m.getMessage('journalWeather'),
                keyInData: 'weather',
                style: { width: 160 },
                dataType: 'object',
                cellRenderer: this.renderCheckboxes,
            },
            {
                title: I18n.m.getMessage('journalEmployeesEmployees'),
                keyInData: 'employee',
                style: { width: 160 },
                dataType: 'object',
                cellRenderer: this.renderCheckboxes,
            },
            {
                title: I18n.m.getMessage('subcontractor'),
                keyInData: 'attendance',
                style: { width: 160 },
                dataType: 'object',
                cellRenderer: this.renderCheckboxes,
            },
            {
                title: I18n.m.getMessage('journalWorkDone'),
                keyInData: 'workDone',
                style: { width: 200 },
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('journalSpecialOccurrences'),
                keyInData: 'specialOccurrences',
                style: { width: 200 },
                dataType: 'string',
            },
        ];
        if (journalSettings != null &&
            journalSettings.additionalFields != null &&
            journalSettings.additionalFields.length > 0) {
            for (let i = 0; i < journalSettings.additionalFields.length; i += 1) {
                const field = journalSettings.additionalFields[i];
                if (field.active !== false) {
                    columns.push({
                        title: field.name,
                        keyInData: `additionalFields_${field.id}`,
                        style: { flex: 2, minWidth: 200 },
                        dataType: 'string',
                    });
                }
            }
        }
        columns.push({
            title: I18n.m.getMessage('journalTableVersions'),
            keyInData: 'versions',
            style: { width: 110 },
            dataType: 'number',
            cellRenderer: this.renderVersions,
        });
        return (<Card style={{
                marginHorizontal: 8,
                marginTop: 16,
                height: tableMaxHeight + 16,
            }}>
        <Table actionItemsLength={3} tableName={I18n.m.getMessage('journal')} onRowPress={onPressJournal} columns={columns} actions={(item) => [
                {
                    onAction: () => () => sendCurrentDraft(numberToDate(item.forDay)),
                    icon: 'share-outline',
                    toolTip: I18n.m.getMessage('journalSend'),
                },
                {
                    onAction: () => () => getAsPdf(JournalEntity.getNormalizedDateShortToDate(item.forDay.toString())),
                    icon: 'download-outline',
                    toolTip: !canI ? I18n.m.getMessage('forbidden') : I18n.m.getMessage('journalGetAsPDF'),
                    disabled: previewOrSendIsLoading || !canI,
                },
                {
                    onAction: () => () => openEditDialog(numberToDate(item.forDay)),
                    icon: 'pencil-outline',
                    toolTip: !canI ? I18n.m.getMessage('forbidden') : I18n.m.getMessage('edit'),
                    disabled: previewOrSendIsLoading || !canI,
                },
            ]} data={journalsWithAdditional} maxHeight={tableMaxHeight} sortBy="forDay" sortDirection="desc" emptyTableImage={noJournalImage} emptyTableHint={I18n.m.getMessage('journalTableNoDataHint')} emptyTableText={I18n.m.getMessage('journalTableNoDataText')}/>
      </Card>);
    }
}
